import styled from 'styled-components';


export const CardTitle = styled.h2`
    @media screen and (max-width: 1395px) {
        font-size: 1rem;
    };

    @media screen and (max-width: 1199px) {
        font-size: 1.2rem;
    };

`;
