import Header from 'components/Headers/Header';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Table
} from 'reactstrap';
import { listContractSignTemplateService } from 'services/Register/ContractSign';
import { createContractSignTemplateService } from 'services/Register/ContractSign';
import Swal from 'sweetalert2';
import getBase64 from 'utils/input-base64';
import { Label } from 'views/PersonalCredit/Component/styles';

const ContractSignTemplateView = () => {
  const [register, setRegister] = useState({
    name: '',
    useWhatsapp: 0,
    useEmail: 0,
    useSms: 0,
    isUpdateDataUser: 0
  });
  const [registers, setRegisters] = useState([]);

  const handleSubmit = () => {
    Swal.fire({
      title: 'Salvando',
      text: 'Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    createContractSignTemplateService(register)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Registro inserido com sucesso!'
        });
        setRegister({
          name: '',
          useWhatsapp: 0,
          useEmail: 0,
          useSms: 0,
          isUpdateDataUser: 0
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleList = () => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    listContractSignTemplateService()
      .then(({ data }) => {
        setRegisters(data?.resultSet ?? []);
        Swal.close();
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <>
      <Header titlePage="IZISIGN - CADASTRAR TEMPLATE" />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader>
            <h3>IZISIGN - CADASTRAR TEMPLATE</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label>Nome do template:</Label>
                  <Input
                    type="text"
                    value={register.name}
                    onChange={(ev) => {
                      setRegister({
                        ...register,
                        name: ev.target.value
                      });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Card className="border-0 shadow mb-4">
              <CardHeader className="border-0 bg-white">
                <h4>Upload</h4>
              </CardHeader>
              <CardBody>
                <Col md="12" className='px-0'>
                  <FormGroup>
                    <Label for="name">
                      Documento <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="file"
                      className="btn-upload"
                      id="imgMain"
                      name="imgMain"
                      onChange={(ev) => {
                        const file = ev.target.files[0] || null;
                        getBase64(file).then((result) => {
                          setRegister({
                            ...register,
                            documents: [result.base64]
                          });
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </CardBody>
            </Card>

            <Card className="shadow">
              <CardHeader className="border-0 bg-white">
                <h4>Configuração - Validação</h4>
              </CardHeader>
              <CardBody>
                <Row className='px-2'>
                  <Col md="4" className='px-1'>
                    <FormGroup>
                      <Label>Enviar SMS</Label>
                      <select
                        className="form-control"
                        value={register.useSms}
                        onChange={(ev) => {
                          setRegister({
                            ...register,
                            useSms: ev.target.value
                          });
                        }}
                      >
                        <option value={1}>SIM</option>
                        <option value={0}>NÃO</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md="4" className='px-1'>
                    <FormGroup>
                      <Label>Token WhatsApp</Label>
                      <select
                        className="form-control"
                        value={register.useWhatsapp}
                        onChange={(ev) => {
                          setRegister({
                            ...register,
                            useWhatsapp: ev.target.value
                          });
                        }}
                      >
                        <option value={1}>SIM</option>
                        <option value={0}>NÃO</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md="4" className='px-1'>
                    <FormGroup>
                      <Label>Confirmar E-mail</Label>
                      <select
                        className="form-control"
                        value={register.useEmail}
                        onChange={(ev) => {
                          setRegister({
                            ...register,
                            useEmail: ev.target.value
                          });
                        }}
                      >
                        <option value={1}>SIM</option>
                        <option value={0}>NÃO</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md="4" className='px-1'>
                    <FormGroup>
                      <Label>Atualizar informações cadastrais</Label>
                      <select
                        className="form-control"
                        value={register.isUpdateDataUser}
                        onChange={(ev) => {
                          setRegister({
                            ...register,
                            isUpdateDataUser: ev.target.value
                          });
                        }}
                      >
                        <option value={1}>SIM</option>
                        <option value={0}>NÃO</option>
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </CardBody>
          <CardFooter>
            <Button color="success" onClick={handleSubmit}>
              <i className="fas fa-save" /> SALVAR
            </Button>
          </CardFooter>
        </Card>
        {registers.length > 0 && (
          <Card className="shadow mt-4">
            <CardHeader>
              <h4>Listagem - Templates Cadastrados</h4>
            </CardHeader>
            <div className="table-responsive">
              <Table>
                <thead>
                  <tr>
                    <th>DATA CADASTRO</th>
                    <th>NOME</th>
                    <th>ENVIA SMS</th>
                    <th>TOKEN WHATSAPP</th>
                    <th>CONFIRMA E-MAIL</th>
                    <th>ATUALIZAR USUÁRIO</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {registers.map((value, key) => (
                    <tr key={key}>
                      <td>{value.createdAt}</td>
                      <td>{value.name}</td>
                      <td>
                        {value.useSms > 0 ? (
                          <Badge color="success">SIM</Badge>
                        ) : (
                          <Badge color="danger">NÃO</Badge>
                        )}
                      </td>
                      <td>
                        {value.useWhatsapp > 0 ? (
                          <Badge color="success">SIM</Badge>
                        ) : (
                          <Badge color="danger">NÃO</Badge>
                        )}
                      </td>
                      <td>
                        {value.useEmail > 0 ? (
                          <Badge color="success">SIM</Badge>
                        ) : (
                          <Badge color="danger">NÃO</Badge>
                        )}
                      </td>
                      <td>
                        {value.isUpdateDataUser > 0 ? (
                          <Badge color="success">SIM</Badge>
                        ) : (
                          <Badge color="danger">NÃO</Badge>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Card>
        )}
      </Container>
    </>
  );
};

export default ContractSignTemplateView;
