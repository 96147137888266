import { useState, useRef, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import ptLocale from '@fullcalendar/core/locales/pt-br';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import Header from 'components/Headers/Header';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container
} from 'reactstrap';
import SampleModalView from 'components/Modals/SampleModalView';
import Swal from 'sweetalert2';
import { indexScheduleUserService } from 'services/Register/ScheduleUserService';
import FormScheduleComponent from './Component/FormScheduleComponent';
import { StyledCalendarContainer } from './Component/style';

const ScheduleView = () => {
  const calendarRef = useRef(null);
  const [toggleModal, setToggleModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [registerSelected, setRegisterSelected] = useState({
    title: null,
    date: null,
    color: '#3788d8',
    display: 'block',
    extendedProps: {
      user: {
        id: null,
        name: null
      },
      description: null
    }
  });
  const [register, setRegister] = useState({
    title: null,
    date: null,
    color: '#3788d8',
    display: 'block',
    extendedProps: {
      user: {
        id: null,
        name: null
      },
      description: null
    }
  });

  const handleList = () => {
    Swal.fire({
      title: 'Carregando',
      text: 'por favor aguarde!',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    indexScheduleUserService()
      .then(({ data }) => {
        Swal.close();
        let calendarApi = calendarRef.current.getApi();
        calendarApi.addEventSource(data.resultSet);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleEventClick = (clickInfo) => {
    setRegisterSelected({
      title: clickInfo.event.title,
      date: clickInfo.event.startStr,
      color: '#3788d8',
      display: 'block',
      extendedProps: {
        user: {
          id: clickInfo.event.extendedProps.user.id,
          name: clickInfo.event.extendedProps.user.name
        },
        description: clickInfo.event.extendedProps.description
      }
    });
    setShowRegisterModal(true);
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <>
      <Header showCards={false} />
      <Container className="mt--8" fluid>
        <Card className="shadow">
          <CardHeader>
            <h2 className="m-0">
              <i className="fas fa-calendar-alt" /> Agenda
            </h2>
          </CardHeader>
          <CardBody>
            <Alert color="secondary" className='p-md-3 mb-md-3 mb-0 p-2 d-flex justify-content-sm-start justify-content-center'>
              <Button
                color="success"
                onClick={() => {
                  setToggleModal(!toggleModal);
                }}
              >
                <i className="fas fa-plus" /> Realizar agendamento
              </Button>
            </Alert>
          </CardBody>
        </Card>
        <Card className="shadow mt-4">
          <CardBody className='px-2'>
            <StyledCalendarContainer>
              <FullCalendar
                ref={calendarRef}
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                  listPlugin
                ]}
                headerToolbar={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek,listWeek'
                }}
                locales={ptLocale}
                locale={'pt-br'}
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                initialView="dayGridMonth"
                weekends={true}
                dateClick={(ref) => {
                  setRegister({
                    ...register,
                    date: ref.dateStr
                  });
                  setToggleModal(true);
                }}
                initialEvents={[]}
                eventClick={handleEventClick}
                aspectRatio="2"
              />
            </StyledCalendarContainer>
          </CardBody>
        </Card>

        {/* MODAL REGISTER */}
        <FormScheduleComponent
          toggleModal={toggleModal}
          setToggleModal={setToggleModal}
          calendarRef={calendarRef}
        />

        {/* MODAL SHOW */}
        <SampleModalView
          labelModal="Visualizar Registro"
          isOpen={showRegisterModal}
          setIsOpen={setShowRegisterModal}
          btnSubmitLabel={'Salvar'}
          btnSubmitAction={() => { }}
          showBtnSubmit={false}
        >
          <div className="bg-secondary p-3 rounded">
            <div>
              <p className="m-0">Titulo</p>
              <h3 className="m-0">{registerSelected.title ?? ''}</h3>
            </div>
            <div className="mt-3">
              <p className="m-0">Descrição</p>
              <h3 className="m-0">
                {registerSelected.extendedProps.description ?? ''}
              </h3>
            </div>
            <hr />
            <div className="mt-3">
              <p className="m-0">Usuário Responsável</p>
              <h3 className="m-0 text-primary">
                {registerSelected.extendedProps.user.name ?? ''}
              </h3>
            </div>
          </div>
        </SampleModalView>
      </Container>
    </>
  );
};

export default ScheduleView;
