import styled, { css } from 'styled-components';


export const CardText = styled.p`
  font-size: 13px;

  @media screen and (max-width: 1034px){
    font-size: 12px;
  };

`;

export const CardH2 = styled.h2`
  font-size: 1.2rem;

  @media screen and (max-width: 1034px){
    font-size: 1rem;
  };

`;

export const TextValue = styled.h2`
  font-size: 1.2rem;

  @media screen and (max-width: 1153px){
    font-size: 1rem;
  };

`;

export const Icon = styled.i`
  font-size: 1.8rem;

  @media screen and (max-width: 1153px){
    font-size: 1.5rem;
  };

`;

export const ContainerCol = styled.div`
 @media screen and (max-width: 767px){
    padding: 10px 20px 0;
  };

`;