import Header from 'components/Headers/Header.js';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table
} from 'reactstrap';
import { newPayment } from 'services/Finance/Payment/PaymentService';
import { listPayments } from 'services/Finance/Payment/PaymentService';
import { showLicense } from 'services/Promoter/PromoterService';
import Swal from 'sweetalert2';
import { moneyMask } from 'utils/mask';
import { Text, Title, CardHeaderStyle, ButtonStyle } from './styles';

const PaymentView = () => {
  const [dataPayments, setDataPayments] = useState([]);
  const [license, setLicense] = useState({});
  const ENUM_STATUS = {
    paid: { text: 'PAGO', color: 'success' },
    pending: { text: 'AGUARDANDO PAGAMENTO', color: 'secondary' },
    cancel: { text: 'CANCELADO', color: 'danger' },
    PAGO: { text: 'PAGO', color: 'success' },
    PENDENTE: { text: 'AGUARDANDO PAGAMENTO', color: 'secondary' },
    CANCELADO: { text: 'CANCELADO', color: 'danger' }
  };

  const handleShowLicense = async () => {
    Swal.fire({
      title: 'Carregando...',
      text: 'Por favor, aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    await showLicense()
      .then(({ data }) => {
        Swal.close();
        setLicense(data?.resultSet ?? {});
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleListPayments = async () => {
    await listPayments()
      .then((res) => {
        setDataPayments(res?.data?.resultSet ?? []);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleGenerateNewInvoice = async () => {
    Swal.fire({
      title: 'Confirmação',
      text: 'Visualizar boleto',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Ok, continuar!',
      cancelButtonText: 'Não, sair!'
    })
      .then((res) => {
        if (!res.isConfirmed) return;

        Swal.fire({
          title: 'Gerando novo pagamento',
          text: 'Por favor aguarde',
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
        newPayment().then((result) => {
          Swal.close();

          Swal.fire({
            text: 'Sucesso',
            html: `
          <h2>Fatura emitida com sucesso</h2>
          <a class="btn btn-success btn-lg" href="${result.data.invoice}" target="_blank">VISUALIZAR BOLETO</a>
          `,
            icon: 'success',
            confirmButtonText: 'OK, SAIR'
          });

          handleListPayments();
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  useEffect(() => {
    handleShowLicense();
    handleListPayments();
  }, []);

  if (!license.id || license.id <= 0) {
    return (
      <>
        <Header titlePage="PAGAMENTO - Atividades de Pagamento" />
        <Alert color="secondary">Carregando ...</Alert>
      </>
    );
  }

  return (
    <>
      <Header titlePage="PAGAMENTO - Atividades de Pagamento" />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader>
            <h2>
              <i className="fas fa-shopping-cart" /> Atividades de Pagamento
            </h2>
          </CardHeader>
          <CardBody>
            <Row className="d-flex align-items-stretch" style={{ minHeight: '100%' }}>
              <Col md="4" className="d-flex">
                <Card className="shadow mb-2 bg-success w-100">
                  <CardHeader style={{padding: '12px'}}>
                    <Title className="m-0">
                      <i className="far fa-money-bill-alt" /> Fatura
                    </Title>
                  </CardHeader>
                  <CardBody>
                    <Text className="text-white m-0">
                      R$ {moneyMask(license.value.toFixed(2))}
                    </Text>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" className="d-flex">
                <Card className="shadow mb-2 w-100">
                  <CardHeaderStyle className='border-bottom'>
                    <Title className="m-0">
                      <i className="far fa-calendar-alt" /> Vencimento
                    </Title>
                  </CardHeaderStyle>
                  <CardBody>
                    <Text className="text-primary m-0">
                      {license.dateExpiration}
                    </Text>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" className="d-flex">
                <Card className="shadow mb-2 w-100">
                  <CardHeaderStyle className='border-bottom'>
                    <Title className="m-0">
                      <i className="fas fa-cash-register" /> Formas de Pagamento
                    </Title>
                  </CardHeaderStyle>
                  <CardBody>
                    <ButtonStyle className="btn btn-warning" onClick={handleGenerateNewInvoice}>
                      <i className="fas fa-barcode" /> Boleto Bancário
                    </ButtonStyle>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {dataPayments.length > 0 && (
              <Card className="shadow mt-2">
                <div className="p-3">Últimas atividades de pagamento</div>
                <div className="table-responsive">
                  <Table className="table align-items-center mb-0">
                    <thead className="bg-primary text-white">
                      <tr>
                        <th>TRANSAÇÃO</th>
                        <th>VALOR</th>
                        <th>STATUS</th>
                        <th>DATA VENCIMENTO</th>
                        <th>DATA PAGAMENTO</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {dataPayments.map((register, key) => (
                        <tr key={key}>
                          <td>{register.transactionId}</td>
                          <td>R$ {moneyMask(register.valuePayment)}</td>
                          <td>
                            <Badge
                              className="font-12"
                              color={ENUM_STATUS[register.status].color}
                            >
                              {ENUM_STATUS[register.status].text}
                            </Badge>
                          </td>
                          <td>{register.dateExpiration}</td>
                          <td>{register.datePayment}</td>
                          <td />
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Card>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default PaymentView;
