import InputBankView from 'components/Form/Input/InputBankView';
import SelectServiceView from 'components/Form/Input/SelectServiceView';
import Header from 'components/Headers/Header';
import { useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap';
import { listTableBankPromoterService } from 'services/Comission/TableComissionService';
import TableComissionBankRegisterComponent from './Component/TableComissionBankRegisterComponent';
import { useEffect } from 'react';

const TableComissionBankView = () => {
  const [isLoading, setIsLoading] = useState(false);
  const defaultError = {
    status: false,
    message: null,
  };
  const [filter, setFilter] = useState({
    febrabanId: null,
    serviceId: null,
    tableId: null,
    tableName: null,
  });
  const [list, setList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState(defaultError);

  const handleList = () => {
    setIsLoading(true);
    setError(defaultError);
    listTableBankPromoterService(filter)
      .then(({ data }) => {
        setList(data.resultSet);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        setError({
          status: true,
          message: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <>
      <Header titlePage="Simulador Consignado" />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader>
            <h3>
              <i className="fas fa-table" /> Tabela Banco
            </h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="3">
                <InputBankView
                  placeholder="Selecione..."
                  defaultValue={{
                    value: filter.febrabanId,
                  }}
                  filter={filter}
                  setFilter={(e) => {
                    setFilter({
                      ...filter,
                      febrabanId: e.febrabanId,
                    });
                  }}
                  isClearable
                />
              </Col>
              <Col md="3">
                <SelectServiceView
                  placeholder="Selecione..."
                  defaultValue={{
                    value: filter.serviceId,
                  }}
                  filter={filter}
                  setFilter={setFilter}
                  isClearable
                />
              </Col>
              <Col md="3">
                <Label>Cod. Tabela</Label>
                <Input
                  value={filter.tableId}
                  setFilter={(e) => {
                    setFilter({
                      ...filter,
                      tableId: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col md="3">
                <Label>Nome Tabela</Label>
                <Input
                  value={filter.tableName}
                  setFilter={(e) => {
                    setFilter({
                      ...filter,
                      tableName: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="primary" onClick={handleList} disabled={isLoading}>
              <i className="fas fa-filter" /> Buscar
            </Button>
            <Button color="success" onClick={() => setOpenModal(true)}>
              <i className="fas fa-plus" /> Novo registro
            </Button>
          </CardFooter>
        </Card>
        {isLoading && (
          <Alert className="mt-3 shadow" color="secondary">
            <i className="fas fa-circle-notch fa-spin" /> Carregando consulta...
          </Alert>
        )}

        {error.status && (
          <Alert className="mt-3 shadow" color="warning">
            <i className="fas fa-exclamation-triangle" /> {error.message}
          </Alert>
        )}

        {list.length > 0 && (
          <Card className="mt-3">
            <CardHeader>
              <i className="fas fa-bars" /> Registros
            </CardHeader>
            <Table>
              <thead className="bg-secondary">
                <tr>
                  <th>BANCO</th>
                  <th>CONVÊNIO</th>
                  <th>TABELA</th>
                </tr>
              </thead>
              <tbody>
                {list.map((register, key) => (
                  <tr key={key}>
                    <td>
                      {register.febrabanId} - {register.febrabanName}
                    </td>
                    <td>{register.service}</td>
                    <td>
                      {register.tableId} - {register.tableName}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        )}
      </Container>

      <TableComissionBankRegisterComponent
        isOpen={openModal}
        setIsOpen={(e) => setOpenModal(e)}
        handleList={handleList}
      />
    </>
  );
};

export default TableComissionBankView;
