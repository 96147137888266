import CardSimulation from 'components/Cards/CardSimulation';
import FilterDefault from 'components/Form/FilterDefault';
import Header from 'components/Headers/Header';
import ModalTagComponent from 'components/ModalTag/ModalTagComponent';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  Alert,
  Badge,
  Card,
  CardHeader,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { IndexSimulationReport } from 'services/Digitation/SimulationService';
import { scheduleExcel } from 'services/Report/Schedule';
import Swal from 'sweetalert2';
import { maskCPF } from 'utils/mask';
import { phoneMask } from 'utils/mask';
import PaginationSelector from 'views/Dashboard/component/PaginationSelector';
import Note from 'views/Dashboard/component/note';
import { Title } from 'views/Dashboard/styles';
import SmsSendMessageComponent from 'views/Register/Sms/Component/SmsSendMessgeComponent';
import FormScheduleComponent from 'views/Schedule/Component/FormScheduleComponent';
import ListHistoryCrefisaComponent from './Component/ListHistoryCrefisaComponent';

const PersonalCreditView = () => {
  const history = useHistory();
  const [filter, setFilter] = useState({});
  const [resultSet, setResultSet] = useState([]);
  const [pages, setPages] = useState({});
  const [dataClient, setDataClient] = useState([]);
  const [modalNote, setModalNote] = useState(false);
  const [modalHistoryCrefisa, setModalHistoryCrefisa] = useState(false);
  const [proposalId, setProposalId] = useState(false);

  const [toggleScheduleComponent, setToggleScheduleComponent] = useState(false);
  const [scheduleDocumentClient, setScheduleDocumentClient] = useState({
    name: '',
    document: '',
  });

  const [sendSms, setSendSms] = useState({
    number: null,
    message: null,
    openModal: false,
  });

  const openModalNote = async (data) => {
    setDataClient({
      ...data,
      id: data.simulationId,
    });
    setModalNote(!modalNote);
  };

  const [tagDataModal, setTagDataModal] = useState({
    isOpen: false,
    clientId: null,
  });

  const handleList = (page = 0) => {
    const newFilter = {
      ...filter,
      page: typeof page == 'number' ? page : 0,
    };

    Swal.fire({
      title: 'Carregando',
      text: 'Por favor aguarde...',
      // message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    IndexSimulationReport(6, newFilter)
      .then(({ data }) => {
        Swal.close();
        const { resultSet } = data;
        setResultSet(resultSet.data);
        setPages(resultSet.pagination);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  return (
    <>
      <Header showCards={false} titlePage="CRÉDITO PESSOAL - Simulações" />
      <Container className="mt--9" fluid>
        <CardSimulation serviceId="6" />
        <FilterDefault
          title="Crédito Pessoal - Simulações"
          data={filter}
          setData={setFilter}
          handleSubmit={handleList}
          handleSubmitCsv={() => {
            scheduleExcel(9, filter, history);
          }}
          config={{ showBtnExcel: true, showFilterStatusText: false }}
          febrabanList={[
            { febrabanId: '321', name: 'CREFAZ' },
            { febrabanId: '274', name: 'NOVO SAQUE' },
          ]}
        />
        <Card className="mt-4 mb-5 mb-xl-0">
          <CardHeader>
            <Title>Simulações</Title>
          </CardHeader>
          {resultSet.length > 0 ? (
            <>
              <PaginationSelector pages={pages.pages} list={handleList} />
              <Table responsive>
                <thead>
                  <tr className="table bg-secondary">
                    <th>DATA CADASTRO</th>
                    <th>CLIENTE</th>
                    <th>VALORES</th>
                    <th>STATUS</th>
                    <th />
                  </tr>
                </thead>
                <tbody className="">
                  {resultSet.map((register, index) => (
                    <tr key={`table-${index}`}>
                      <td className="align-middle">
                        <div>{register.createdAt}</div>
                        <Badge color="secondary" className="font-12 mt-2">
                          {register.localeSimulation}
                        </Badge>
                        {register.userAction && (
                          <div>
                            <Badge color="info" className="font-12 mt-2">
                              {register.userAction}
                            </Badge>
                          </div>
                        )}
                      </td>
                      <td className="align-middle">
                        <h3>{register.name}</h3>
                        <div>
                          CPF: <strong>{maskCPF(register.cpf)}</strong>
                        </div>
                        <div>
                          Simulações:{' '}
                          <Badge color="success">{register.qtd}</Badge>
                        </div>
                        {register.indicationCode && (
                          <>
                            <div>
                              Código Indicado:{' '}
                              <strong className="text-warning">
                                {register.indicationCode}
                              </strong>
                            </div>

                            <div>
                              Nome Indicado:{' '}
                              <strong className="text-warning">
                                {register.indicationName}
                              </strong>
                            </div>
                          </>
                        )}
                        <p className="text-primary font-weight-bold">
                          {phoneMask(register.whatsappNumber)} {` `}
                          <a
                            className="btn btn-success btn-sm"
                            href={`https://wa.me/55${register.whatsappNumber}`}
                            target="_blank"
                          >
                            <i className="fab fa-whatsapp" />
                          </a>
                        </p>
                      </td>
                      <td className="align-middle">
                        <div>
                          Vlr. Bruto:{' '}
                          <strong>R$ {register.valueFinally ?? '0.00'}</strong>
                        </div>
                        <div>
                          Vlr. Liquido:{' '}
                          <strong>R$ {register.valueLiberty ?? '0.00'}</strong>
                        </div>
                      </td>
                      <td className="align-middle">
                        <div>
                          Banco:{' '}
                          <strong>
                            {register.febrabanId} - {register.bank}
                          </strong>
                        </div>
                        {register.numberProposal ? (
                          <>
                            <div>
                              Nº Contrato:{' '}
                              <Badge color="warning" className="font-12">
                                {register.numberProposal}
                              </Badge>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div>
                          Status Digitação: <strong>{register.status}</strong>
                        </div>
                        {register.situation && (
                          <div>
                            Situação Banco:{' '}
                            <strong>{register.situation}</strong>
                          </div>
                        )}
                        {register.activity && (
                          <div>
                            Atividade Banco:{' '}
                            <strong>{register.activity}</strong>
                          </div>
                        )}

                        {register.messageReturnService && (
                          <>
                            <hr className="mt-2 mb-2" />
                            <h5 className="m-0 text-danger">
                              <strong>{register.messageReturnService}</strong>
                            </h5>
                          </>
                        )}
                      </td>
                      <td className="align-middle">
                        <UncontrolledDropdown>
                          <DropdownToggle caret color="secondary">
                            Opções
                          </DropdownToggle>
                          <DropdownMenu dark>
                            {parseInt(register.statusId) === 2 && (
                              <>
                                <DropdownItem
                                  href={`/admin/proposal-details/${
                                    register.proposalId ?? 0
                                  }`}
                                  target="_blank"
                                >
                                  <i className="fas fa-edit" /> Detalhes do
                                  Contrato
                                </DropdownItem>
                              </>
                            )}

                            {parseInt(register.statusId) !== 2 && (
                              <>
                                <DropdownItem
                                  href={`/admin/simulation-details/${
                                    register.simulationId ?? 0
                                  }`}
                                  target="_blank"
                                >
                                  <i className="fas fa-edit" /> Detalhes da
                                  Simulação
                                </DropdownItem>
                              </>
                            )}
                            {register.febrabanId == '321' && (
                              <DropdownItem
                                onClick={() => {
                                  history.push(
                                    `/admin/personal-credit/re-simulation-crefaz/${register.simulationId}`
                                  );
                                }}
                              >
                                <i className="far fa-sticky-note" /> Simular
                                novamente creafaz
                              </DropdownItem>
                            )}

                            {register.febrabanId == '069' && (
                              <DropdownItem
                                onClick={() => {
                                  setProposalId(register.proposalId);
                                  setModalHistoryCrefisa(true);
                                }}
                              >
                                <i className="fas fa-history" /> Histórico
                                Esteira
                              </DropdownItem>
                            )}

                            <DropdownItem
                              onClick={() => {
                                setToggleScheduleComponent(
                                  !toggleScheduleComponent
                                );
                                setScheduleDocumentClient({
                                  name: register.name,
                                  document: register.cpf,
                                });
                              }}
                            >
                              <i className="far fa-clock" /> Realizar
                              Agendamento
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                setTagDataModal({
                                  isOpen: true,
                                  clientId: register.clientId,
                                });
                              }}
                            >
                              <i className="fas fa-tags" /> Etiquetas
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                openModalNote(register);
                              }}
                            >
                              <i className="far fa-comment-alt" /> Anotações
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                setSendSms({
                                  ...sendSms,
                                  openModal: true,
                                  number: register.whatsappNumber,
                                  message: `Olá ${register.name}`,
                                });
                              }}
                            >
                              <i className="fas fa-paper-plane" /> Enviar SMS
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <PaginationSelector pages={pages.pages} list={handleList} />
            </>
          ) : (
            <>
              <Alert color="secondary">
                <h2>Não foram identificados registros para esta consulta</h2>
              </Alert>
            </>
          )}
        </Card>
      </Container>

      <FormScheduleComponent
        toggleModal={toggleScheduleComponent}
        setToggleModal={setToggleScheduleComponent}
        name={scheduleDocumentClient.name}
        document={scheduleDocumentClient.document}
      />

      <ModalTagComponent
        tagDataModal={tagDataModal}
        setTagDataModal={setTagDataModal}
        serviceId={2}
      />

      <SmsSendMessageComponent
        numberDefault={sendSms.number}
        messageDefault={sendSms.message}
        isOpen={sendSms.openModal}
        setIsOpen={(e) => {
          setSendSms({ ...sendSms, openModal: e });
        }}
      />

      <ListHistoryCrefisaComponent
        proposalId={proposalId}
        isOpen={modalHistoryCrefisa}
        hideModal={() => {
          setProposalId(null);
          setModalHistoryCrefisa(false);
        }}
      />

      <Note isModal={modalNote} state={setModalNote} data={dataClient} />
    </>
  );
};

export default PersonalCreditView;
