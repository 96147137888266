import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from 'components/Headers/Header';
import FormDigitationClientCrefazComponent from './Page/Component/FormDigitationClientCrefazComponent';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import FormUploadCrefazComponent from './Page/Component/FormUploadCrefazComponent';
import FormSelectOfferCrefazComponent from './Page/Component/FormSelectOfferCrefazComponent';
import FormDigitationCompleteRegisterCrefazComponent from './Page/Component/FormDigitationCompleteRegisterCrefazComponent';
import Swal from 'sweetalert2';
import { showSimulationDetails } from 'services/Digitation/ProposalService';
import { useEffect } from 'react';
import { CardTitle } from './Component/styles';

const DigitationCrefazView = () => {
  let { simulationId } = useParams();
  const [stage, setStage] = useState(1);
  const [form, setForm] = useState({
    name: null,
    cpf: null,
    birthDate: null,
    phone: null,
    cep: null,
    state: null,
    city: null
  });
  const [result, setResult] = useState({});
  const [offers, setOffers] = useState({});

  const handleShow = async (simulationId) => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    showSimulationDetails(simulationId)
      .then((result) => {
        Swal.close();
        const resultSimulation = result?.data?.resultSet;
        setForm({
          name: resultSimulation?.name,
          cpf: resultSimulation?.cpf,
          birthDate: resultSimulation?.birthDate,
          phone: resultSimulation?.whatsappNumber,
          cep: resultSimulation?.multBank?.resultSimulation?.cep
        });
        Swal.close();
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  useEffect(() => {
    if (simulationId > 0) {
      handleShow(simulationId);
    }
  }, []);

  return (
    <>
      <Header titlePlage="Crédito Pessoal - Digitação Crefaz" />
      <Container className="mt--9" fluid>
        <Card>
          <CardBody>
            <Row className="d-flex align-items-stretch px-2">
              <Col md="3" className="d-flex p-1">
                <Card className={`shadow text-center text-dark ${Number(stage) === 1 && 'bg-success'} d-flex flex-column w-100`}>
                  <CardBody className="px-2">
                    <CardTitle>
                      <i className="fas fa-sticky-note fa-2x" />
                      <h2>Simulação</h2>
                    </CardTitle>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3" className="d-flex p-1">
                <Card className={`shadow text-center text-dark ${Number(stage) === 5 && 'bg-success'} d-flex flex-column w-100`}>
                  <CardBody className="px-2">
                    <CardTitle>
                      <i className="fas fa-user-tag fa-2x" />
                      <h2>Selecionar oferta</h2>
                    </CardTitle>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3" className="d-flex p-1">
                <Card className={`shadow text-center text-dark ${Number(stage) === 7 && 'bg-success'} d-flex flex-column w-100`}>
                  <CardBody className="px-2">
                    <CardTitle>
                      <i className="fas fa-file-upload fa-2x" />
                      <h2>Anexar documentos</h2>
                    </CardTitle>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3" className="d-flex p-1">
                <Card className={`shadow text-center text-dark ${Number(stage) === 6 && 'bg-success'} d-flex flex-column w-100`}>
                  <CardBody className="px-2">
                    <CardTitle>
                      <i className="fas fa-user-edit fa-2x" />
                      <h2>Finalizar proposta</h2>
                    </CardTitle>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <FormDigitationClientCrefazComponent
          form={form}
          setForm={setForm}
          result={result}
          setResult={setResult}
          offers={offers}
          setOffers={setOffers}
          stage={stage}
          setStage={setStage}
        />

        <FormSelectOfferCrefazComponent
          form={form}
          setForm={setForm}
          offers={offers}
          result={result}
          stage={stage}
          setStage={setStage}
        />

        <FormUploadCrefazComponent
          form={form}
          setForm={setForm}
          stage={stage}
          setStage={setStage}
        />

        <FormDigitationCompleteRegisterCrefazComponent
          form={form}
          setForm={setForm}
          stage={stage}
          setStage={setStage}
        />
      </Container>
    </>
  );
};

export default DigitationCrefazView;
