import Header from 'components/Headers/Header';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
  Table
} from 'reactstrap';
import SmsCardResumeComponent from './Component/SmsCardResumeComponent';
import { getProductService } from 'services/Finance/Payment/PaymentService';
import { moneyMask } from 'utils/mask';
import Swal from 'sweetalert2';
import { genereteInvoiceProductService } from 'services/Finance/Payment/PaymentService';
import { listPayments } from 'services/Finance/Payment/PaymentService';
import { CardTitle } from './styles';

const SmsRechargeView = () => {
  const [products, setProducts] = useState([]);
  const [payments, setPayments] = useState([]);

  const handleListProducts = () => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    getProductService(2)
      .then(({ data }) => {
        Swal.close();
        setProducts(data?.resultSet ?? []);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleSelectPlan = (plan) => {
    console.log(plan);
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      html: `
      <p>Você está prestes a adquirir:</p>
      <h1 class="mb-0"><i class="fas fa-sms"></i>  ${plan.name}</h1>
      <p class="m-0">${plan.description}</p>
      <div class="mt-3 bg-secondary rounded p-2">
      <p class='m-0'>Quantidade</p>
      <h3 class='m-0 mb-2'>${plan.qtd}</h3>
      <p class='m-0'>Valor do pacote</p>
      <h1 class='m-0'>R$ ${moneyMask(plan.valueAmount)}</h1>
      </div>
      <p class="mt-3"><span class="font-weight-bold text-danger">Atenção:</span> Após realizar a confirmação do pagamento, em até 1(um) dia útil, seu pacote de SMS será disponibilizado.</p>
      `,
      showCancelButton: true,
      cancelButtonText: 'Não, sair',
      confirmButtonText: 'Sim, quero adquirir'
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;
      Swal.fire({
        title: 'Emitindo fatura',
        text: 'Por favor, aguarde.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      genereteInvoiceProductService(plan.id)
        .then(({ data }) => {
          const invoice = data.resultSet;

          Swal.fire({
            icon: 'success',
            title: 'Sucesso',
            html: `
            <h3>Fatura emitida com sucesso!</h3>
            <p class="mb-0">Valor da fatura</p>
            <h1 class="mt-0">R$ ${moneyMask(
              invoice.valuePayment.toFixed(2)
            )}</h1>
            <p class="mt-4 mb-0">Código de barras:</p>
            <textarea class="form-control mb-3">${
              invoice.digitable_line
            }</textarea>
            <a class="btn btn-success w-100" href="${
              invoice.url
            }" target="blank">VISUALIZAR FATURA</a>
            `,
            confirmButtonText: 'Ok, sair'
          });
        })
        .catch((err) => {
          const code = !err.response ? '00000' : err.response.data.code;
          const message = !err.response
            ? 'Ocorreu um erro inesperado'
            : err.response.data.message;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `[${code}] ${message}`
          });
        });
    });
  };

  const handleListPayment = () => {
    listPayments({ products: [2, 3, 4, 5] }).then(({ data }) => {
      console.log(data.resultSet);
      setPayments(data.resultSet);
    });
  };

  useEffect(() => {
    handleListProducts();
    handleListPayment();
  }, []);

  return (
    <>
      <Header titlePage="SMS - Relatório de envios" />
      <Container className="mt--9" fluid>
        <SmsCardResumeComponent />
        <Card className="shadow">
          <CardHeader>
            <h2>
              <i className="fas fa-battery-three-quarters" /> SMS - Recarregar
            </h2>
          </CardHeader>
          <CardBody>
            <Row className='px-2'>
              {products.map((product, key) => (
                <Col md="6" xl="3" key={key} className='px-1'>
                  <Card className="shadow mb-2">
                    <CardHeader className="border-0">
                      <CardTitle className="m-0">
                        <i className="fas fa-sms" /> {product.name}
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <p className="m-0">{product.description}</p>
                      <div className="bg-secondary rounded p-2 mt-3">
                        <p className="m-0">Quantidade:</p>
                        <h2 className="m-0 mb-2">{product.qtd}</h2>
                        <p className="m-0">Valor do pacote:</p>
                        <h1 className="m-0">
                          R$ {moneyMask(product.valueAmount)}
                        </h1>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <Button
                        color="warning"
                        className="w-100"
                        onClick={() => handleSelectPlan(product)}
                      >
                        <i className="fas fa-cart-plus" /> QUERO ESTE
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              ))}
            </Row>
          </CardBody>
        </Card>
        {payments.length > 0 && (
          <Card className="shadow mt-3">
            <CardHeader>Faturas</CardHeader>
            <Table responsive>
              <thead>
                <tr>
                  <th>PRODUTO</th>
                  <th>VALOR</th>
                  <th>FATURA</th>
                  <th>STATUS</th>
                  <th>TRANSAÇÃO</th>
                  <th>DATA VENCIMENTO</th>
                  <th>DATA PAGAMENTO</th>
                </tr>
              </thead>
              <tbody>
                {payments.map((payment, key) => (
                  <tr key={key}>
                    <td>{payment.product}</td>
                    <td>
                      <h2>R$ {moneyMask(payment.valuePayment)}</h2>
                    </td>
                    <td>
                      {payment.status === 'PENDENTE' && (
                        <a
                          href={payment.invoiceUrl}
                          target="blank"
                          className="btn btn-success btn-md"
                        >
                          Visualizar fatura
                        </a>
                      )}
                    </td>
                    <td>
                      <Badge
                        className="font-12"
                        color={
                          payment.status === 'PAGO' ? 'success' : 'secondary'
                        }
                      >
                        {payment.status}
                      </Badge>
                    </td>
                    <td>{payment.transactionId}</td>
                    <td>{payment.dateExpiration}</td>
                    <td>{payment.datePayment}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        )}
      </Container>
    </>
  );
};

export default SmsRechargeView;
