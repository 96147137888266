import api from 'services/api';

export const listSimulationQueue = () => {
    return api.get('v3/simulation/fgts/queue');
};

export const sendSimulationQueue = (data) => {
    return api.post('v3/simulation/fgts/queue', data);
};

export const reProcessSimulation = (uuid) => {
    return api.put(`v3/simulation/fgts/queue/${uuid}`);
};

export const deleteSimulationQueue = (uuid) => {
    return api.delete(`v3/simulation/fgts/queue/${uuid}`);
};
