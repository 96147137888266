import { useEffect, useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { listService } from 'services/Register/ServicesService';

const SelectServiceView = ({
  filter,
  setFilter,
  defaultValue = { value: null, label: null },
  isClearInput = false,
  isMultSelect = false,
  nameLabel = 'Convênio',
}) => {
  const [list, setList] = useState([]);
  const [valueSelected, setValueSelected] = useState({});
  const [loading, isLoading] = useState(false);

  const handleList = async () => {
    isLoading(true);
    setList([]);
    listService()
      .then(({ data }) => {
        setValueSelected(
          data.resultSet.filter((e) => e.id == defaultValue?.value)[0] ?? null
        );

        setList(data.resultSet);
        isLoading(false);
      })
      .catch((err) => {
        isLoading(false);
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <FormGroup>
      <Label>{nameLabel}</Label>
      {list.length > 0 && (
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={
            valueSelected?.id
              ? {
                label: valueSelected?.name,
                value: valueSelected?.id,
              }
              : null
          }
          options={list.map((register) => {
            return {
              label: register.name,
              value: register.id,
            };
          })}
          isLoading={list.length <= 0}
          placeholder={`Selecione...`}
          isClearable={isClearInput}
          isMulti={isMultSelect}
          onChange={(res) => {
            if (isMultSelect) {
              setFilter(res);
              return;
            }

            setFilter({
              ...filter,
              serviceId: res?.value ?? null,
            });
          }}
        />
      )}

      {loading && (
        <p>
          <i className="fas fa-circle-notch fa-spin"></i> Carregando...
        </p>
      )}

      {!loading && list.length <= 0 && (
        <p>
          <i className="fas fa-exclamation-triangle"></i> Não foram
          identificados registros
        </p>
      )}
    </FormGroup>
  );
};

export default SelectServiceView;
