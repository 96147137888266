import styled from 'styled-components';


export const CardTitle = styled.div`
  i{
      @media screen and (max-width: 1317px) {
        font-size: 1.5rem;
      };
  };

  h2{
      @media screen and (max-width: 1317px) {
        font-size: 1.1rem;
      };
  };

   h2{
      @media screen and (max-width: 1224px) {
        font-size: 1rem;
      };
  };

   h2{
      @media screen and (max-width: 1097px) {
        font-size: 0.9rem;
      };
  };

`;

export const Label = styled.label`
  font-size: 0.9rem;
`;
