import { useEffect, useState } from 'react';

import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableData,
} from 'components/Table';

import {
  ContentWrapper,
  MobileTitle,
  CaretRight,
} from 'views/Dashboard/styles';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Alert,
  Badge,
  Row,
  Col,
} from 'reactstrap';

import {
  sendSimulationQueue,
  listSimulationQueue,
} from 'services/SimulationQueue';

import Swal from 'sweetalert2';
import { maskCPF } from 'utils/mask';
import SampleModalView from 'components/Modals/SampleModalView';
import { listBankFgtsService } from 'services/Register/BankService';
import { BankSelectData } from 'views/AccessBank/service/bankSelectData';
import { moneyMask } from 'utils/mask';
import OptionSimulationFgtsQueueComponent from './OptionSimulationFgtsQueueComponent';
import InfoSimulationDashboard from 'views/Dashboard/component/simulationInfo';

const SimulationFGTSQueueComponent = ({
  isOpen = false,
  hideModal = () => {},
}) => {
  const [data, setData] = useState({});
  const [simulationQueueData, setSimulationQueueData] = useState([]);
  const [tableDetails, setTableDetails] = useState(null);
  const [banks, setBanks] = useState([]);
  const [modalDigitation, setModalDigitation] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  const [simulationResult, setSimulationResult] = useState({
    id: null,
    febrabanId: null,
    cpf: null,
    valueSolicited: null,
    whatsappNumber: null,
  });

  const handleListBanks = async () => {
    await listBankFgtsService()
      .then((result) => {
        if (!result?.data?.resultSet) {
          return;
        }

        setBanks(
          result?.data?.resultSet.map((res) => {
            return {
              value: res.id,
              label: `${res.febraban} | ${res.name}`,
              febrabanId: res.febraban,
            };
          })
        );
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleTableDetails = (index) => {
    if (tableDetails === index) return setTableDetails(null);

    setTableDetails(index);
  };

  const closeModal = () => {
    hideModal();
    setData({});
  };

  const handleSubmit = async () => {
    if (!data.cpf || !data.bankId) {
      Swal.fire({
        icon: 'warning',
        title: 'Campos obrigatórios',
        text: 'Por favor, preencha todos os campos obrigatórios.',
      });
      return;
    }

    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await sendSimulationQueue(data)
      .then(async () => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Registro adicionado a Fila!',
        });
        setData({
          cpf: '',
          bankId: '',
        });
        handleList();
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleList = async () => {
    await listSimulationQueue()
      .then(({ data }) => {
        Swal.close();
        setSimulationQueueData(data?.resultSet ?? []);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    if (isOpen) {
      handleList();
      handleListBanks();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && simulationQueueData.length > 0) {
      const id = setInterval(() => {
        setLoadingProgress((prevProgress) => {
          if (prevProgress < 100) {
            return prevProgress + 3.33;
          } else {
            clearInterval(id);
            return 100;
          }
        });
      }, 1000);

      setIntervalId(id);

      return () => {
        clearInterval(id);
      };
    }
  }, [isOpen, simulationQueueData]);

  useEffect(() => {
    if (loadingProgress === 100 && simulationQueueData.length > 0 && isOpen) {
      handleList();
      setLoadingProgress(0);
    }
  }, [loadingProgress, simulationQueueData, isOpen]);

  return (
    <>
      <SampleModalView
        labelModal="Fila de Simulações FGTS"
        icon="fa fa-solid fa-people-arrows mr-2"
        isOpen={isOpen}
        setIsOpen={closeModal}
        showBtnSubmit={false}
        size="xl"
      >
        <Card className="shadow">
          <CardBody>
            <Alert color="secondary" className="border-0">
              <strong>
                <i className="fas fa-exclamation-triangle" /> A rotina
                processará apenas 10 CPFs por vez.
              </strong>
            </Alert>
            {simulationQueueData.length < 10 && (
              <Row>
                <Col className="col-lg-6 col-12">
                  <FormGroup>
                    <Label>
                      Bancos <span className="text-danger">*</span>
                    </Label>
                    {banks.length > 0 ? (
                      <select
                        className="form-control"
                        value={data.bankId}
                        onChange={(e) => {
                          setData({
                            ...data,
                            bankId: e.target.value,
                          });
                        }}
                      >
                        <option value="">Selecione...</option>
                        {banks.map((bank) => (
                          <option key={bank.value} value={bank.value}>
                            {bank.label}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <p>Carregando...</p>
                    )}
                  </FormGroup>
                </Col>

                <Col className="col-lg-6 col-12">
                  <FormGroup>
                    <Label>
                      CPF do Cliente <span className="text-danger">*</span>
                    </Label>
                    <InputGroup>
                      <Input
                        type="text"
                        value={data.cpf}
                        placeholder="000.000.000-00"
                        onChange={(ev) => {
                          setData({ ...data, cpf: maskCPF(ev.target.value) });
                        }}
                      />
                      <InputGroupAddon addonType="prepend">
                        <Button
                          className="btn-success shadow-none border rounded-right"
                          onClick={handleSubmit}
                        >
                          <i className="fa fa-plus mr-2"></i>
                          ADICIONAR
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
        <Card className="mt-3 shadow">
          <CardHeader className="d-flex flex-sm-row flex-column align-items-sm-center justify-content-between">
            <div>
              <i className="fas fa-bars" /> Fila
            </div>
            {simulationQueueData.length > 0 && (
              <div className="d-flex flex-sm-row flex-column align-items-sm-center mt-sm-0 mt-3">
                <div
                  className="progress m-0"
                  style={{ height: '5px', width: '150px' }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${loadingProgress}%` }}
                    aria-valuenow={loadingProgress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>
                <p
                  className="ml-sm-2 m-0 text-primary"
                  style={{ fontSize: '13px' }}
                >
                  Proxima atualização
                </p>
              </div>
            )}
          </CardHeader>

          <CardBody className="p-0">
            {simulationQueueData.length > 0 ? (
              <Table responsive>
                <TableHeader>
                  <TableRow>
                    <TableHead className="">BANCO</TableHead>
                    <TableHead>STATUS</TableHead>
                    <TableHead className="d-none d-lg-table-cell">
                      PROCESSAMENTO
                    </TableHead>
                    <TableHead>CPF</TableHead>
                    <TableHead>VALOR LIBERADO</TableHead>
                    <TableHead className="d-none d-xl-table-cell">
                      TENTATIVAS
                    </TableHead>
                    <TableHead className="d-none d-xl-table-cell">
                      OPÇÕES
                    </TableHead>
                    <TableHead className="d-xl-none" />
                  </TableRow>
                </TableHeader>
                {simulationQueueData.map((register, key) => (
                  <TableBody>
                    <>
                      {register.error && (
                        <TableRow className="border-bottom-0">
                          <TableData colSpan={12} className="pb-0">
                            <h4 className="m-0">
                              <Alert color="secondary">{register.error}</Alert>
                            </h4>
                          </TableData>
                        </TableRow>
                      )}
                      <TableRow key={register.uuid} className="border-top-0">
                        <TableData
                          style={{ minWidth: '80px' }}
                          className="text-left"
                        >
                          {BankSelectData(register.febrabanId)?.img && (
                            <img
                              src={BankSelectData(register.febrabanId).img}
                              alt={register.nameBank}
                              height="50px"
                            />
                          )}
                        </TableData>
                        <TableData>
                          {register.status === 'SUCCESS' ? (
                            <Badge
                              color="success"
                              style={{ fontSize: '0.7rem' }}
                            >
                              CONCLUIDO
                            </Badge>
                          ) : register.status === 'ERROR' ? (
                            <Badge
                              color="danger"
                              style={{ fontSize: '0.7rem' }}
                            >
                              ERRO
                            </Badge>
                          ) : register.status === 'WAIT' ? (
                            <Badge
                              color="warning"
                              style={{ fontSize: '0.7rem' }}
                            >
                              AGUARDANDO{' '}
                            </Badge>
                          ) : (
                            <Badge color="info" style={{ fontSize: '0.7rem' }}>
                              PROCESSANDO
                            </Badge>
                          )}
                        </TableData>
                        <TableData
                          className="d-none d-lg-table-cell"
                          style={{ minWidth: '100px' }}
                        >
                          {register.createdAt}
                        </TableData>

                        <TableData style={{ minWidth: '200px' }}>
                          <strong style={{ fontSize: '1rem' }}>
                            {maskCPF(register.cpf)}
                          </strong>
                        </TableData>
                        <TableData style={{ minWidth: '200px' }}>
                          <strong style={{ fontSize: '1rem' }}>
                            R$ {moneyMask(register.valueLiberty)}
                          </strong>
                        </TableData>
                        <TableData className="d-none d-xl-table-cell">
                          <Badge style={{ fontSize: '1rem' }}>
                            {register.qtd}
                          </Badge>
                        </TableData>
                        <TableData className="d-none d-xl-table-cell">
                          <OptionSimulationFgtsQueueComponent
                            register={register}
                            handleList={handleList}
                            setSimulationResult={setSimulationResult}
                            setModalDigitation={setModalDigitation}
                          />
                        </TableData>
                        <TableData className="d-xl-none">
                          <CaretRight
                            active={tableDetails === key}
                            onClick={() => handleTableDetails(key)}
                          />
                        </TableData>
                      </TableRow>
                      {tableDetails === key && (
                        <TableRow className="d-xl-none">
                          <TableData colSpan={10}>
                            <ContentWrapper className="d-xl-none">
                              <MobileTitle>CADASTRO</MobileTitle>
                              {register.createdAt}
                            </ContentWrapper>
                            <ContentWrapper className="d-xl-none">
                              <MobileTitle>TENTATIVAS</MobileTitle>
                              <Badge style={{ fontSize: '1rem' }}>
                                {register.qtd}
                              </Badge>
                            </ContentWrapper>
                            <ContentWrapper className="d-xl-none">
                              <MobileTitle>Opções</MobileTitle>
                              <OptionSimulationFgtsQueueComponent
                                register={register}
                                handleList={handleList}
                                setSimulationResult={setSimulationResult}
                                setModalDigitation={setModalDigitation}
                              />
                            </ContentWrapper>

                            <ContentWrapper className="d-xl-none">
                              <MobileTitle>ERRO</MobileTitle>
                              <Badge
                                color="danger"
                                style={{ fontSize: '0.7rem' }}
                              >
                                {register.error}
                              </Badge>
                            </ContentWrapper>
                          </TableData>
                        </TableRow>
                      )}
                    </>
                  </TableBody>
                ))}
              </Table>
            ) : (
              <div className="p-3">Não possuem registros na fila</div>
            )}
          </CardBody>
        </Card>
      </SampleModalView>

      <InfoSimulationDashboard
        isModal={modalDigitation}
        state={setModalDigitation}
        data={simulationResult}
      />
    </>
  );
};

export default SimulationFGTSQueueComponent;
