import styled from 'styled-components';

export const StyledCalendarContainer = styled.div`
    .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) {
        @media screen and (max-width: 540px) {
            width: 70px;
        };
    };

    .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .fc-button-group {
        button {
            @media screen and (max-width: 540px) {
                border-radius: 5px;
                margin-bottom: 1px;
            };
        };

        @media screen and (max-width: 540px) {
            display: flex;
            flex-direction: column;
        };
    };

    .fc-button-group button {
        @media screen and (max-width: 767px){
            padding: 8px;
            font-size: 12px;
        };
    };

    .fc-today-button{
        @media screen and (max-width: 991px){
            font-size: 14px;
        };

         @media screen and (max-width: 540px){
            margin-top: 5px;
         };
    };

    .fc-toolbar-title {
        font-weight: bold;
        @media screen and (max-width: 991px){
            font-size: 1.2rem;
        };

         @media screen and (max-width: 540px){
            text-align: center;
        };
}`;