const PaginationSelector = (propos) => {
  const { pages, list, page } = propos;
  let indices = [];

  for (let i = 0; i < pages; i++) {
    indices.push(i);
  }

  const handleChange = async (evt) => {
    await list(Number(evt.target.value));
  };

  return (
    <>
      <div className="text-left col-lg-3 col-md-4 col-12 pb-2">
        <div className="input-group input-group-sm">
          <div className="input-group-prepend">
            <div className="input-group-text">Pagina</div>
          </div>
          <select
            className="form-control form-control-sm"
            onChange={handleChange}
          >
            {pages <= 0 ? (
              <option value="0">1</option>
            ) : (
              <>
                {indices.map((idx) => (
                  <option
                    key={idx}
                    value={idx}
                    selected={Number(idx) === Number(page) && 'true'}
                  >
                    {idx + 1}
                  </option>
                ))}
              </>
            )}
          </select>
          <div className="input-group-prepend">
            <div className="input-group-text">
              <span>
                de <strong>{pages}</strong>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaginationSelector;
