import styled from 'styled-components';

import { device } from 'layouts/breakpoints';

export const Header = styled.div`
  min-height: 350px;
  background-size: cover;
  background-position: center top;
`;

export const Hello = styled.h2`
  color: #ffffff;
  font-size: 44px;
  font-weight: 600;
`;

export const Card = styled.div`
  background: #ffffff;
  padding: 16px 0;
  border-radius: 8px;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
  border: 1px solid rgba(0, 0, 0, 0.05);
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;

  padding: 0 16px 16px;
`;

export const HeaderTitle = styled.h3`
  color: #32325d;
  font-weight: 600;
  font-size: 13px;

  margin-bottom: 0;

  @media ${device.tablet} {
    font-size: 17px;
  }
`;

export const LinkToSimulation = styled.a`
  padding: 4px 8px;
  font-size: 12px;

  @media ${device.tablet} {
    padding: 10px 20px;
    font-size: 14px;
  }
`;

export const CardBody = styled.div`
  background: #f7fafc;
  padding: 16px;
`;

export const BodyTitle = styled.p`
  text-transform: uppercase;
  color: #8898aa;
  font-size: 12px;
  font-weight: 600;
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 16px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
`;

export const Button = styled.button`
  padding: 4px 8px;
  font-size: 12px;

  @media ${device.tablet} {
    padding: 10px 20px;
    font-size: 14px;
  }
`;

export const Title = styled.h3`
  @media screen and (max-width: 1229px) {
    font-size: 1rem;
  };

  @media screen and (max-width: 1071px) {
    font-size: 0.95rem;
  };

   @media screen and (max-width: 1018px) {
    font-size: 0.9rem;
  };

`;

export const CardHeaderStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;

  padding: 12px;
`;

export const Text = styled.h1`
  @media screen and (max-width: 1229px) {
    font-size: 1.3rem;
  };

  @media screen and (max-width: 1229px) {
    font-size: 1.1rem;
  };
`;


export const TextStyled = styled.h3`
  @media screen and (max-width: 1020px) {
    font-size: 1rem;
  };

`;

export const ButtonStyle = styled.button`
  @media screen and (max-width: 1010px) {
    font-size: 0.8rem;
  };

`;

export const ContainerButton = styled.div`
  .btn-margin {

    @media screen and (max-width: 696px) {
        margin-top: 10px;
        margin-left: 0;
      };
  } 
`;