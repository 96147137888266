import Header from 'components/Headers/Header';
import { useState } from 'react';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  Row,
} from 'reactstrap';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableData,
  TableHeader,
} from 'components/Table';
import PaginationSelector from '../component/PaginationSelector';
import { Title } from '../styles';
import { phoneMask } from 'utils/mask';
import { moneyMask } from 'utils/mask';
import { IndexMiniSimulationReport } from 'services/Digitation/SimulationService';
import { maskCPF } from 'utils/mask';
import Swal from 'sweetalert2';
import { CaretRight } from 'phosphor-react';
import { IndexMiniSimulationReportCsv } from 'services/Digitation/SimulationService';
import { useHistory } from 'react-router-dom';
import { Label } from 'views/PersonalCredit/Component/styles';
import { MobileTitle } from 'views/Dashboard/styles';
import { ContainerButton } from 'views/Dashboard/styles';

const LeadScheduleView = () => {
  const modules = JSON.parse(localStorage.getItem('IZIDEV_MODULE_SYSTEM'));
  const userDataConfig = JSON.parse(localStorage.getItem('IZIDEV_USER_DATA'));
  const history = useHistory();
  const [filter, setFilter] = useState({
    dateStart: null,
    dateEnd: null,
    cpf: null,
    febrabanId: null,
    nameBank: null,
    messageError: null,
    hasBalance: null,
  });
  const [result, setResult] = useState([]);
  const [resume, setResume] = useState({
    total: 0,
    qtdError: 0,
    qtdBalance: 0,
    totalBalance: 0,
  });
  const [pages, setPages] = useState({
    pages: 0,
  });
  const [tableDetails, setTableDetails] = useState(null);

  const handleTableDetails = (index) => {
    if (tableDetails === index) return setTableDetails(null);

    setTableDetails(index);
  };

  const handleHasPermission = (path) => {
    return modules.filter((register) => {
      return register.path === path;
    });
  };

  const handleFilter = (page = 0) => {
    const newFilter = {
      ...filter,
      page: typeof page == 'number' ? page : 0,
    };

    Swal.fire({
      title: 'Carregando',
      text: 'Por favor aguarde...',
      // message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    IndexMiniSimulationReport(newFilter)
      .then((res) => {
        const { data, pagination } = res.data.resultSet;
        setResult(data);
        setResume(res?.data?.resultSet?.resume ?? {});
        setPages(pagination);
        Swal.close();
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleExportExcel = () => {
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    IndexMiniSimulationReportCsv(filter)
      .then(async (res) => {
        Swal.fire({
          icon: 'info',
          title: 'Sucesso',
          text: res.data.message,
        }).then((result) => {
          if (!result.isConfirmed) return;
          history.push('/admin/report-schedule');
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  return (
    <>
      <Header titlePage="FGTS - Retrabalhar Leads" />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader>
            <Title>
              <i className="fas fa-infinity" /> Retrabalhar Leads
            </Title>
          </CardHeader>
          <CardBody>
            {/* <Alert color="secondary" className="border">
              <h3>Funcionalidade em versão beta</h3>
              <p>
                A izidev desenvolveu uma inteligência que analisa, diariamente,
                seus clientes que tenham realizado simulações há mais de 30
                dias. Isso possibilita identificar potenciais clientes, para
                oferecer mais crédito, e retrabalhar seus leads.
              </p>
              <p className="m-0">
                Para sugestões, melhorias ou dúvidas, entre em contato com nossa
                central de atendimento, via <strong>chat on-line</strong> ou{' '}
                <strong>WhatsApp</strong>.
              </p>
            </Alert> */}
            <Row>
              <Col md="3">
                <FormGroup>
                  <Label>Período</Label>
                  <InputGroup>
                    <Input
                      id="dateStart"
                      name="dateStart"
                      type="date"
                      className="border"
                      onChange={(ev) => {
                        setFilter({
                          ...filter,
                          dateStart: ev.target.value,
                        });
                      }}
                    />
                    <Input
                      id="dateEnd"
                      name="dateEnd"
                      type="date"
                      className="border pl-3"
                      onChange={(ev) => {
                        setFilter({
                          ...filter,
                          dateEnd: ev.target.value,
                        });
                      }}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Financeira</Label>
                  <select
                    id="bank"
                    name="bank"
                    className="form-control"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        febrabanId: ev.target.value,
                      });
                    }}
                  >
                    <option value="">Selecione...</option>
                    <option value="626">C6 Consignado</option>
                    <option value="149">FACTA Financeira</option>
                    <option value="074">SAFRA</option>
                    <option value="623">PAN</option>
                    <option value="389">BANCO MERCANTIL</option>
                    <option value="329">iCred</option>
                    <option value="465">Quero+Crédito</option>
                    <option value="318">BMG</option>
                    <option value="329-2">SABEMI</option>
                    <option value="324">LOTUS MAIS</option>
                    <option value="457">457 - UY3</option>
                  </select>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>CPF do Cliente</Label>
                  <Input
                    id="cpf"
                    name="cpf"
                    type="text"
                    value={filter.cpf}
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        cpf: maskCPF(ev.target.value),
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Mensagem de erro</Label>
                  <Input
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        messageError: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Possui saldo</Label>
                  <select
                    id="hasBalance"
                    name="hasBalance"
                    className="form-control"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        hasBalance: ev.target.value,
                      });
                    }}
                  >
                    <option value="">Selecione...</option>
                    <option value="1">SIM</option>
                    <option value="0">NÃO</option>
                  </select>
                </FormGroup>
              </Col>
              {handleHasPermission('/fgts/import/lead').length > 0 && (
                <Col md="3">
                  <FormGroup>
                    <Label>Registros Importados</Label>
                    <select
                      id="hasImportedLead"
                      name="hasImportedLead"
                      className="form-control"
                      onChange={(ev) => {
                        setFilter({
                          ...filter,
                          hasImportedLead: ev.target.value,
                        });
                      }}
                    >
                      <option value="-1" selected>
                        TODOS
                      </option>
                      <option value="0">NÃO</option>
                      <option value="1">SIM</option>
                    </select>
                  </FormGroup>
                </Col>
              )}
            </Row>
          </CardBody>
          <CardFooter>
            <ContainerButton>
              <Button
                className="btn-margin"
                color="success"
                onClick={handleFilter}
              >
                <i className="fas fa-search" /> Filtrar
              </Button>
              {userDataConfig?.canExportExcel && (
                <Button
                  className="btn-margin"
                  color="success"
                  onClick={handleExportExcel}
                >
                  <i className="fas fa-file-csv" /> Exportar Excel
                </Button>
              )}
            </ContainerButton>
          </CardFooter>
        </Card>

        {result.length > 0 && (
          <Row className="mt-3">
            <Col md="3">
              <Card>
                <CardBody>
                  <p className="m-0">QTD. TOTAL</p>
                  <h2 className="m-0 text-primary font-22">{resume.total}</h2>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <CardBody>
                  <p className="m-0">QTD. ERRO</p>
                  <h2 className="m-0 text-warning font-22">
                    {resume.qtdError}
                  </h2>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <CardBody>
                  <p className="m-0">QTD. SALDO</p>
                  <h2 className="m-0 text-success font-22">
                    {resume.qtdBalance}
                  </h2>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <CardBody>
                  <p className="m-0">SALDO TOTAL</p>
                  <h2 className="m-0 text-success font-22">
                    R$ {resume.totalBalance}
                  </h2>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        <Card className="shadow mt-3">
          <CardHeader>
            <Title>Listagem</Title>
          </CardHeader>
          <CardBody>
            {result.length > 0 ? (
              <>
                <PaginationSelector pages={pages.pages} list={handleFilter} />
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead className="d-none d-sm-table-cell">
                        Data Cadastro
                      </TableHead>
                      <TableHead>Cliente</TableHead>
                      <TableHead className="d-none d-xl-table-cell">
                        Simulação
                      </TableHead>
                      <TableHead className="d-none d-xl-table-cell">
                        Erro
                      </TableHead>
                      <TableHead className="d-none d-lg-table-cell" />
                      <TableHead className="d-xl-none" />
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {result.map((register, key) => (
                      <>
                        <TableRow key={key}>
                          <TableData
                            className="d-none d-sm-table-cell"
                            style={{ width: '300px' }}
                          >
                            <p>{register.createdAt}</p>
                          </TableData>
                          <TableData>
                            <p className="m-0">
                              Nome: <strong>{register.clientName}</strong>
                            </p>
                            <p className="m-0">
                              CPF: <strong>{maskCPF(register.cpf)}</strong>
                            </p>
                            <p className="m-0">
                              WhatsApp:{' '}
                              <strong>{phoneMask(register.whatsapp)}</strong>
                              {` `}
                              <a
                                href={`https://wa.me/55${register.whatsapp.replace(
                                  /[^0-9]/g,
                                  ''
                                )}`}
                                target="_blank"
                                className="btn btn-success btn-sm"
                              >
                                <i className="fab fa-whatsapp" />
                              </a>
                            </p>
                          </TableData>
                          <TableData className="d-none d-xl-table-cell">
                            <p className="m-0">
                              Banco:{' '}
                              <strong>
                                {register.febrabanId} - {register.nameBank}
                              </strong>
                            </p>
                            <p className="m-0">
                              Valor Liberado:{' '}
                              <strong>
                                R$ {moneyMask(register.valueLiberty)}
                              </strong>
                            </p>
                            {register.indicationName && (
                              <>
                                <p className="m-0">
                                  Indicação:{' '}
                                  <strong className="text-warning">
                                    {register.indicationName}
                                  </strong>
                                </p>
                                <p className="m-0">
                                  Código Indicação:{' '}
                                  <strong className="text-warning">
                                    {register.indicationCode}
                                  </strong>
                                </p>
                              </>
                            )}
                            {register.dateLastSimulation && (
                              <p>
                                Data da Simulação:{' '}
                                <Badge color="success" className="font-12">
                                  {register.dateLastSimulation}
                                </Badge>
                              </p>
                            )}
                          </TableData>
                          <TableData className="d-none d-xl-table-cell">
                            <strong className="text-danger">
                              {register.messageReturnService}
                            </strong>
                          </TableData>
                          {/* <TableData className="d-none d-lg-table-cell">
                            <UncontrolledDropdown>
                              <DropdownToggle caret color="secondary">
                                Opções
                              </DropdownToggle>
                              <DropdownMenu dark>
                                <DropdownItem
                                  href={`/admin/proposal-details/${
                                    register.proposalId ?? 0
                                  }`}
                                  target="_blank"
                                >
                                  <i className="fas fa-comments" /> Observações
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </TableData> */}

                          <TableData className="d-xl-none">
                            <CaretRight
                              active={tableDetails === key}
                              onClick={() => handleTableDetails(key)}
                            />
                          </TableData>
                        </TableRow>
                        {tableDetails === key && (
                          <tr className="d-xl-none d-flex">
                            <td colSpan={2} className="p-3">
                              <div className="d-sm-none d-table-cell">
                                <MobileTitle>DATA CADASTRO</MobileTitle>
                                <p>{register.createdAt}</p>
                              </div>
                              <div>
                                <p className="m-0">
                                  Banco:{' '}
                                  <strong>
                                    {register.febrabanId} - {register.nameBank}
                                  </strong>
                                </p>
                                <p className="m-0">
                                  Valor Liberado:{' '}
                                  <strong>
                                    R$ {moneyMask(register.valueLiberty)}
                                  </strong>
                                </p>
                                {register.indicationName && (
                                  <>
                                    <p className="m-0">
                                      Indicação:{' '}
                                      <strong className="text-warning">
                                        {register.indicationName}
                                      </strong>
                                    </p>
                                    <p className="m-0">
                                      Código Indicação:{' '}
                                      <strong className="text-warning">
                                        {register.indicationCode}
                                      </strong>
                                    </p>
                                  </>
                                )}
                                {register.dateLastSimulation && (
                                  <p>
                                    Data da Simulação:{' '}
                                    <Badge color="success" className="font-12">
                                      {register.dateLastSimulation}
                                    </Badge>
                                  </p>
                                )}
                              </div>
                              <div>
                                <MobileTitle>ERRO</MobileTitle>
                                <strong className="text-danger">
                                  {register.messageReturnService}
                                </strong>
                              </div>
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                  </TableBody>
                </Table>
                <PaginationSelector pages={pages.pages} list={handleFilter} />
              </>
            ) : (
              <>
                <Alert color="secondary" className="text-center">
                  Não possuem registros para esta consulta
                </Alert>
              </>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default LeadScheduleView;
