import { useEffect, useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { listAllTablesService } from 'services/AccessBank/AccessBankService';

const SelectTableBankView = ({
  filter,
  setFilter,
  defaultValue = { value: null, label: null },
  isClearInput = false,
  isMultSelect = false,
  nameLabel = 'Tabela banco',
  febrabanId = null,
  productId = null,
}) => {
  const [list, setList] = useState([]);
  const [valueSelected, setValueSelected] = useState({});
  const [loading, isLoading] = useState(false);

  const handleList = async () => {
    setList([]);
    isLoading(true);
    listAllTablesService(febrabanId, {
      productBankId: productId,
    })
      .then(({ data }) => {
        setValueSelected(
          data.resultSet.filter((e) => e.id == defaultValue?.value)[0] ?? null
        );
        setList(data.resultSet);
        isLoading(false);
      })
      .catch((err) => {
        isLoading(false);
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    if (!productId || !febrabanId) return;
    handleList();
  }, [productId, febrabanId]);

  if (!productId || !febrabanId) {
    return (
      <FormGroup>
        <Label>{nameLabel}</Label>
        <Input type="text" value="Selecione produto" readOnly />
      </FormGroup>
    );
  }

  return (
    <FormGroup>
      <Label>{nameLabel}</Label>
      {list.length > 0 && (
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={
            valueSelected?.id
              ? {
                label: `${valueSelected?.tableId} | ${valueSelected?.tableName}`,
                value: valueSelected?.id,
              }
              : null
          }
          options={list.map((register) => {
            return {
              label: `${register.tableId} | ${register.tableName}`,
              value: register.id,
            };
          })}
          isLoading={list.length <= 0}
          placeholder={`Selecione...`}
          isClearable={isClearInput}
          isMulti={isMultSelect}
          onChange={(res) => {
            if (isMultSelect) {
              setFilter(res);
              return;
            }

            setFilter({
              ...filter,
              tableBankId: res?.value ?? null,
            });
          }}
        />
      )}

      {loading && (
        <p>
          <i className="fas fa-circle-notch fa-spin"></i> Carregando...
        </p>
      )}

      {!loading && list.length <= 0 && (
        <p>
          <i className="fas fa-exclamation-triangle"></i> Não foram
          identificados registros
        </p>
      )}
    </FormGroup>
  );
};

export default SelectTableBankView;
