import Header from 'components/Headers/Header';
import { TableRow } from 'components/Table';
import { TableBody } from 'components/Table';
import { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  Table,
} from 'reactstrap';
import { ReportClientBirthDateService } from 'services/Register/ClientService';
import { scheduleExcel } from 'services/Report/Schedule';
import Swal from 'sweetalert2';

const ClientBirthDateReportView = () => {
  const history = useHistory();
  const [showBtnMoreRegisters, setShowBtnMoreRegisters] = useState(false);
  const [filter, setFilter] = useState({
    month: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [registers, setRegisters] = useState([]);

  const handleSubmit = (isMore = false) => {
    Swal.fire({
      title: 'Buscando',
      text: 'Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    setIsLoading(true);
    if (!isMore) {
      setRegisters([]);
    }

    let filterSearch = {
      ...filter,
    };

    if (!isMore) {
      setFilter({
        ...filter,
        pageActual: 0,
      });

      filterSearch = {
        ...filter,
        pageActual: 0,
      };
    }

    ReportClientBirthDateService(filterSearch)
      .then(({ data }) => {
        Swal.close();
        setIsLoading(false);
        if (!data?.resultSet?.data) {
          setShowBtnMoreRegisters(false);
          return;
        }

        setFilter({
          ...filter,
          pageActual: data.resultSet.pagination,
        });

        if (isMore) {
          setRegisters([...registers, ...data?.resultSet?.data || []]);
          setShowBtnMoreRegisters(data?.resultSet?.data?.length > 0);
        } else {
          setRegisters(data?.resultSet?.data ?? []);
          setShowBtnMoreRegisters(data?.resultSet?.data?.length >= 15);
        }
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleExportCsv = () => {
    scheduleExcel(19, filter, history);
  };

  return (
    <>
      <Header titlePage="CLIENTES POR ANIVERSÁRIO" />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader>
            <h2 className="m-0">Clientes por aniversário</h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label>Mês aniversário</Label>
                  <select
                    className="form-control"
                    value={filter.month}
                    onChange={(e) => {
                      setFilter({
                        ...filter,
                        month: e.target.value,
                      });
                    }}
                  >
                    <option value="">Selecione ...</option>
                    <option value="01">JANEIRO</option>
                    <option value="02">FEVEREIRO</option>
                    <option value="03">MARÇO</option>
                    <option value="04">ABRIL</option>
                    <option value="05">MAIO</option>
                    <option value="06">JUNHO</option>
                    <option value="07">JULHO</option>
                    <option value="08">AGOSTO</option>
                    <option value="09">SETEMBRO</option>
                    <option value="10">OUTUBRO</option>
                    <option value="11">NOVEMBRO</option>
                    <option value="12">DEZEMBRO</option>
                  </select>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button
              color="primary"
              className="mr-2"
              onClick={() => {
                setRegisters([]);
                handleSubmit();
              }}
            >
              <i className="fas fa-search" /> Listar
            </Button>
            <Button
              color="success"
              className="mr-2"
              onClick={() => {
                handleExportCsv();
              }}
            >
              <i className="fas fa-file-csv" /> Exportar excel
            </Button>
          </CardFooter>
        </Card>

        {isLoading && (
          <Alert color="secondary" className="text-center mt-4 border shadow">
            Carregando...
          </Alert>
        )}

        {registers.length > 0 && (
          <Card className="shadow mt-4">
            <CardHeader>Registros</CardHeader>
            <Table responsive>
              <thead className="bg-secondary">
                <tr>
                  <th>NOME</th>
                  <th>CPF</th>
                  <th>DATA NASCIMENTO</th>
                  <th>WHATSAPP</th>
                </tr>
              </thead>
              <tbody>
                {registers.map((register, key) => (
                  <tr key={key}>
                    <td>
                      <p className="m-0">{register.name}</p>
                    </td>
                    <td>
                      <p className="m-0">{register.cpf}</p>
                    </td>
                    <td>
                      <p className="m-0">{register.birthDate}</p>
                    </td>
                    <td>
                      <p className="m-0">{register.whatsappNumber}</p>
                    </td>
                  </tr>
                ))}
              </tbody>
              {showBtnMoreRegisters && (
                <TableBody>
                  <TableRow>
                    <td colSpan={5}>
                      <Button
                        className="w-100"
                        onClick={() => {
                          setFilter({ ...filter });
                          handleSubmit(true);
                        }}
                      >
                        Carregar mais...
                      </Button>
                    </td>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </Card>
        )}
      </Container>
    </>
  );
};

export default ClientBirthDateReportView;
