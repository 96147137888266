import dayjs from 'dayjs';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap';
import { ShowClientProposalService } from 'services/Register/ClientService';
import Swal from 'sweetalert2';
import { EnumOrganEmissionRG } from 'utils/EnumOrganEmissionRG';
import { cepMask } from 'utils/mask';
import { EnumRateBolsaFamilia } from '../Crefisa/EnumRateBolsaFamilia';
import FormRegisterAddressComponent from 'components/Form/Register/FormRegisterAddressComponent';
import { moneyMask } from 'utils/mask';
import { useState } from 'react';
import { maskCPF } from 'utils/mask';
import { phoneMask } from 'utils/mask';
import { maskDate } from 'utils/mask';
import { showClientHasPermissionService } from 'services/Digitation/PersonalCreditCrefisaService';

const DataDigitationCrefisaComponent = ({
  contract = {},
  setContract = () => {},
}) => {
  const [simulation, setSimulation] = useState({
    show: false,
    valueInstallment: 0,
    valueClient: 0,
  });

  const handleSimulate = () => {
    try {
      const installment = Number(contract.installment);
      const valueInstallment = contract?.valueInstallment
        .replace('.', '')
        .replace(',', '.');

      const filterRate = EnumRateBolsaFamilia.filter(
        (e) => Number(e.installment) === Number(installment)
      )[0];

      if (!filterRate) {
        Swal.fire({
          icon: 'warning',
          title: 'Ops',
          text: 'Não foi possível calcular',
        });
        return;
      }

      setSimulation({
        ...simulation,
        show: true,
        installment: installment,
        valueClient: (filterRate.rate * valueInstallment).toFixed(2),
        valueInstallment: moneyMask(valueInstallment),
      });

      setContract({
        ...contract,
        installment: installment,
        valueLiberty: (filterRate.rate * valueInstallment).toFixed(2),
        valueInstallment: moneyMask(valueInstallment),
      });
    } catch (e) {
      setSimulation({
        ...simulation,
        show: false,
        installment: 12,
        valueClient: 0,
        valueInstallment: 0,
      });
    }
  };

  const handleGetClientProposal = async () => {
    Swal.fire({
      title: 'Buscando',
      text: 'Por favor, aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    await ShowClientProposalService(
      contract.cpf.replace(/[^0-9]/g, ''),
      1
    ).then(({ data }) => {
      if (!data?.resultSet || !data?.resultSet?.name) {
        Swal.fire({
          icon: 'warning',
          title: 'Não identificado',
          text: 'Registro não identificado na base',
        });
        return;
      }

      Swal.close();

      setContract({
        ...contract,
        name: data.resultSet?.name ?? '',
        email: data.resultSet?.email ?? '',
        sex: data.resultSet?.sex ?? '',
        motherName: data.resultSet?.nameMother ?? '',
        cep: cepMask(data.resultSet?.CEP ?? ''),
        address: data.resultSet?.address ?? '',
        numberAddress: data.resultSet?.numberAddress ?? '',
        district: data.resultSet?.district ?? '',
        city: data.resultSet?.city ?? '',
        state: data.resultSet?.state ?? '',
        complement: data.resultSet?.email ?? '',
        documentRg: data.resultSet?.documentRG ?? '',
        dateEmissionRg: dayjs(data.resultSet?.dateEmissionRG ?? '').format(
          'DD/MM/YYYY'
        ),
        organEmissionRg: data.resultSet?.emissionRG ?? '',
        stateRg: data.resultSet?.ufRG ?? '',
      });
    });
  };

  const handleInputChange = (e) => {
    let { name, value } = e.target;

    if (name === 'cpf') value = maskCPF(value);
    if (name === 'phone') value = phoneMask(value);
    if (name === 'valueIncome' || name === 'valueInstallment')
      value = moneyMask(value);
    if (name === 'birthDate' || name === 'dateEmissionRG')
      value = maskDate(value);

    setContract({
      ...contract,
      [name]: value,
    });
  };

  const [clientHasPermission, setClientHasPermission] = useState(null);

  const handleClientHasPermissionService = (cpf) => {
    setClientHasPermission(null);
    showClientHasPermissionService(cpf.replace(/[^0-9]/g, '')).then(
      ({ data }) => {
        setClientHasPermission(data?.resultSet ?? null);
      }
    );
  };

  return (
    <>
      <Card className="shadow">
        <CardHeader>
          <h1>
            <i className="far fa-sticky-note" /> Crédito Pessoal - Crefisa
            <Button
              color="warning"
              className="float-right"
              size="sm"
              onClick={() =>
                history.push(`/admin/personal-credit/digitation/queue`)
              }
            >
              <i className="fas fa-chevron-left" /> Voltar
            </Button>
          </h1>
        </CardHeader>
        <CardBody>
          {clientHasPermission && (
            <Alert color="danger">
              Notificação Crefisa: {clientHasPermission}
            </Alert>
          )}
          <Row>
            <Col lg="4">
              <FormGroup>
                <Label>
                  CPF <span className="text-danger">*</span>
                </Label>
                <Input
                  name="cpf"
                  value={contract.cpf}
                  onChange={handleInputChange}
                  placeholder="000.000.000-00"
                  onBlur={(ev) => {
                    handleClientHasPermissionService(ev.target.value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <Label>
                  Matrícula <span className="text-danger">*</span>
                </Label>
                <InputGroup>
                  <Input
                    name="registry"
                    value={contract.registry}
                    placeholder="Matrícula"
                    onChange={handleInputChange}
                  />

                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className="bg-secondary border b-5">
                      -
                    </InputGroupText>
                  </InputGroupAddon>

                  <Input
                    name="registryDigit"
                    className="col-3"
                    value={contract.registryDigit}
                    placeholder="Digito"
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <Label>
                  Valor da Renda Líquida <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="valueIncome"
                  value={contract.valueIncome}
                  onChange={handleInputChange}
                  placeholder="R$ 0,00"
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <Label>
                  Tipo de contrato <span className="text-danger">*</span>
                </Label>
                <select className="form-control">
                  <option value={1} selected>
                    NOVO
                  </option>
                </select>
              </FormGroup>
            </Col>
            <Col md="12">
              <Card className="shadow">
                <CardBody>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <Label>
                          Valor da Parcela{' '}
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="valueInstallment"
                          value={contract.valueInstallment}
                          onChange={handleInputChange}
                          placeholder="R$ 0,00"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label>
                          Parcela <span className="text-danger">*</span>
                        </Label>
                        <InputGroup>
                          <select
                            className="form-control"
                            name="installment"
                            value={contract.installment}
                            onChange={handleInputChange}
                          >
                            <option value={1}>1x</option>
                            <option value={2}>2x</option>
                            <option value={3}>3x</option>
                            <option value={4}>4x</option>
                            <option value={5}>5x</option>
                            <option value={6}>6x</option>
                            <option value={7}>7x</option>
                            <option value={8}>8x</option>
                            <option value={9}>9x</option>
                            <option value={10}>10x</option>
                            <option value={11}>11x</option>
                            <option value={12}>12x</option>
                          </select>
                          <InputGroupAddon addonType="append">
                            <Button color="success" onClick={handleSimulate}>
                              Simular
                            </Button>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  {simulation.show && (
                    <>
                      <div className="bg-secondary p-3 rounded border">
                        <Row>
                          <Col md="4">
                            <p className="m-0">Prazo</p>
                            <h3 className="m-0">{simulation.installment}</h3>
                          </Col>
                          <Col md="4">
                            <p className="m-0">Valor Parcela</p>
                            <h3 className="m-0">
                              R$ {simulation.valueInstallment}
                            </h3>
                          </Col>
                          <Col md="4">
                            <p className="m-0">Valor Cliente</p>
                            <h3 className="m-0">R$ {simulation.valueClient}</h3>
                          </Col>
                        </Row>
                      </div>
                      <Alert color="info" className="mt-2">
                        <h4 className="m-0">
                          Valor aproximado, poderá sofrer alteração de acordo
                          com as taxas de juros da Crefisa
                        </h4>
                      </Alert>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="shadow mt-2">
        <CardHeader>
          <h3 className="m-0">
            <i className="fas fa-user-check" /> Informações do cliente
            <Button
              color="primary"
              size="sm"
              className="float-right"
              onClick={handleGetClientProposal}
            >
              <i className="fas fa-id-badge" /> Atualizar dados do cliente
            </Button>
          </h3>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="4">
              <FormGroup>
                <Label>
                  Name <span className="text-danger">*</span>
                </Label>
                <Input
                  name="name"
                  value={contract.name}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label>
                  Data de Nascimento <span className="text-danger">*</span>
                </Label>
                <Input
                  name="birthDate"
                  placeholder="--/--/----"
                  value={contract.birthDate}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label>
                  Contato WhatsApp <span className="text-danger">*</span>
                </Label>
                <Input
                  name="phone"
                  placeholder="(xx) x xxxx-xxxx"
                  value={contract.phone}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>
                  RG <span className="text-danger">*</span>
                </Label>
                <Input
                  name="documentRG"
                  value={contract.documentRG}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>
                  Data de Emissão <span className="text-danger">*</span>
                </Label>
                <Input
                  name="dateEmissionRG"
                  placeholder="--/--/----"
                  value={contract.dateEmissionRG}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>
                  Orgão Emissor <span className="text-danger">*</span>
                </Label>
                <select
                  name="emissionRG"
                  className="form-control"
                  value={contract.emissionRG}
                  onChange={handleInputChange}
                >
                  <option value="" selected disabled>
                    SELECIONE...
                  </option>
                  {EnumOrganEmissionRG.map((organEmission, key) => (
                    <option key={key} value={organEmission.code}>
                      {organEmission.code} - {organEmission.name}
                    </option>
                  ))}
                </select>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>
                  Estado Emissor <span className="text-danger">*</span>
                </Label>
                <select
                  name="stateRG"
                  className="form-control"
                  value={contract.stateRG}
                  onChange={handleInputChange}
                >
                  <option value="" disabled="">
                    Selecione...
                  </option>
                  <option value="AC">Acre</option>
                  <option value="AL">Alagoas</option>
                  <option value="AP">Amapá</option>
                  <option value="AM">Amazonas</option>
                  <option value="BA">Bahia</option>
                  <option value="CE">Ceará</option>
                  <option value="DF">Distrito Federal</option>
                  <option value="ES">Espírito Santo</option>
                  <option value="GO">Goiás</option>
                  <option value="MA">Maranhão</option>
                  <option value="MT">Mato Grosso</option>
                  <option value="MS">Mato Grosso do Sul</option>
                  <option value="MG">Minas Gerais</option>
                  <option value="PA">Pará</option>
                  <option value="PB">Paraíba</option>
                  <option value="PR">Paraná</option>
                  <option value="PE">Pernambuco</option>
                  <option value="PI">Piauí</option>
                  <option value="RJ">Rio de Janeiro</option>
                  <option value="RN">Rio Grande do Norte</option>
                  <option value="RS">Rio Grande do Sul</option>
                  <option value="RO">Rondônia</option>
                  <option value="RR">Roraima</option>
                  <option value="SC">Santa Catarina</option>
                  <option value="SP">São Paulo</option>
                  <option value="SE">Sergipe</option>
                  <option value="TO">Tocantins</option>
                </select>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>
                  E-mail <span className="text-danger">*</span>
                </Label>
                <Input
                  name="email"
                  value={contract.email}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>
                  Sexo <span className="text-danger">*</span>
                </Label>
                <select
                  name="sex"
                  className="form-control"
                  value={contract.sex}
                  onChange={handleInputChange}
                >
                  <option value="" disabled="">
                    Selecione...
                  </option>
                  <option value="M">Masculino</option>
                  <option value="F">Feminino</option>
                </select>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>
                  Nome da Mãe <span className="text-danger">*</span>
                </Label>
                <Input
                  name="nameMother"
                  value={contract.nameMother}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="shadow mt-2">
        <CardHeader>
          <h3>
            <i className="fas fa-map-marked-alt" /> Endereço
          </h3>
        </CardHeader>
        <CardBody>
          <FormRegisterAddressComponent
            register={contract}
            setRegister={setContract}
            isRequired={true}
          />
        </CardBody>
      </Card>

      <Card className="shadow mt-2">
        <CardHeader>
          <h3 className="m-0">
            <i className="fas fa-university" /> Dados bancários
          </h3>
        </CardHeader>
        <CardBody>
          <Row>
            <Col lg="5" md="12">
              <FormGroup>
                <Label>
                  Banco <span className="text-danger">*</span>
                </Label>
                <select className="form-control" readOnly disabled>
                  <option value="104" selected>
                    CAIXA ECONÔMICA FEDERAL
                  </option>
                </select>
              </FormGroup>
            </Col>
            <Col xl="2">
              <FormGroup>
                <Label>
                  Agência <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="agencyBank"
                  value="3880"
                  readOnly
                  disabled
                />
              </FormGroup>
            </Col>
            <Col xl="3">
              <FormGroup>
                <Label>
                  Conta <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="numberAccount"
                  value={contract.numberAccount}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col xl="2">
              <FormGroup>
                <Label>
                  Dígito Conta <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="digitAccount"
                  value={contract.digitAccountBank}
                  onChange={handleInputChange}
                  maxLength={1}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default DataDigitationCrefisaComponent;
