import styled, { css } from 'styled-components';
import { CaretRight as Caret } from 'phosphor-react';

import { device } from 'layouts/breakpoints';

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;

  padding: 16px;

  @media ${device.desktopLG} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Title = styled.h3`
  font-size: 17px;
  color: #32325d;
  font-weight: 600;

  margin-bottom: 0;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 8px;

  margin-top: 8px;

  @media ${device.desktopLG} {
    margin-top: 0;
  }
`;

export const Button = styled.button`
  margin: 0 !important;
`;

export const CreatedAt = styled.p`
  font-size: 10px;
  margin-bottom: 4px;

  @media ${device.desktopLG} {
    font-size: 13px;
  }
`;

export const Actuating = styled.p`
  font-size: 10px;

  @media ${device.desktopXL} {
    font-size: 13px;
  }

  margin-bottom: 0;

  div {
    margin-top: 4px;
    font-size: 9px;
    width: fit-content;

    @media ${device.desktopXL} {
      font-size: 11px;
    }
  }
`;

export const ClientName = styled.h3`
  font-size: 13px;
  margin-bottom: 4px;

  @media ${device.desktopLG} {
    font-size: 15px;
  }
`;

export const Subtitle = styled.p`
  font-size: 10px;
  margin-bottom: 4px;

  @media ${device.desktopLG} {
    font-size: 11px;
  }
  @media ${device.desktopXL} {
    font-size: 13px;
  }

  strong {
    font-weight: bold;
  }

  div {
    font-size: 9px;
    width: fit-content;

    @media ${device.desktopXL} {
      font-size: 11px;
    }
  }
`;

export const WhatsAppLink = styled.a`
  font-size: 10px;
  margin-bottom: 0;
  padding: 0;
  text-align: left;

  @media ${device.desktopLG} {
    font-size: 11px;
  }
  @media ${device.desktopXL} {
    font-size: 13px;
  }
`;

export const MobileTitle = styled.h3`
  font-size: 13px;
  color: #8898aa;
  text-transform: uppercase;
  width: 100%;

  margin-bottom: 8px;
`;

export const ContentWrapper = styled.div`
  & + & {
    margin-top: 16px;
  }
`;

export const CaretRight = styled(Caret).attrs(() => ({
  size: 20
}))`
  transition: all 0.2s ease;
  cursor: pointer;

  ${(props) =>
    props.active &&
    css`
      transform: rotate(90deg);
    `}
`;

export const CardTitle = styled.h1`
  @media screen and (max-width: 1072px) {
    font-size: 1.3rem;
  };
`;

export const FieldsetDiv = styled.div`

  @media screen and (max-width: 554px) {
    padding: 0px;
  };

  .btn-margin {
    @media screen and (max-width: 561px) {
        margin-left: 0;
    };

    @media screen and (max-width: 554px) {
        margin-top: 10px;
    };
  }

  .margin {
    @media screen and (max-width: 508px) {
        margin-left: 0;
         margin-top: 10px;
    };
  }
`;

export const ContainerButton = styled.div`
  .btn-margin {

    @media screen and (max-width: 696px) {
        margin-top: 10px;
        margin-left: 0;
      };
  } 
`;