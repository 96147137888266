import Header from 'components/Headers/Header';
import { useEffect, useState } from 'react';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
  Table,
} from 'reactstrap';
import Swal from 'sweetalert2';
import { Title } from 'views/Dashboard/styles';
import { moneyMask } from 'utils/mask';
import InputIndicationView from 'components/Form/Input/InputIndicationView';
import CardResumoComponent from 'components/Cards/CardResumoComponent';
import { reportMyComissionService } from 'services/Comission/SellerComissionTableService';
import { TextValue } from 'components/Cards/styles';

const ComissionPromoterReportView = () => {
  const [showBtnMoreRegisters, setShowBtnMoreRegisters] = useState(false);
  const [filter, setFilter] = useState({
    status: 2,
  });
  const [result, setResult] = useState([]);
  const [resumeCommission, setResumeCommision] = useState({
    promoter: 0.0,
    seller: 0.0,
    wait: 0.0,
    balance: 0.0,
  });

  const handleList = (isMore = false) => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let filterSearch = {
      ...filter,
    };

    if (!isMore) {
      setFilter({
        ...filter,
        previusLimit: 0,
      });

      filterSearch = {
        ...filter,
        previusLimit: 0,
      };
    }

    reportMyComissionService(filterSearch)
      .then((res) => {
        Swal.close();
        const { resume, report } = res.data.resultSet;
        setFilter({
          ...filter,
          previusLimit: report.limit,
        });
        setResumeCommision(resume);

        if (isMore) {
          setResult([...result, ...report?.result || []]);
          setShowBtnMoreRegisters(report?.result.length > 0);
        } else {
          setResult(report?.result);
          setShowBtnMoreRegisters(report?.result.length >= 15);
        }
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <>
      <Header titlePage="Meu comissionamento" />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader>
            <Title>
              <h2 className="m-0">
                <i className="fas fa-percentage" /> Meu comissionamento
              </h2>
            </Title>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>Período Cadastro</Label>
                  <InputGroup>
                    <Input
                      id="dateStart"
                      name="dateStart"
                      type="date"
                      className="border"
                      onChange={(ev) => {
                        setFilter({
                          ...filter,
                          dateStart: ev.target.value,
                        });
                      }}
                    />
                    <Input
                      id="dateEnd"
                      name="dateEnd"
                      type="date"
                      className="border pl-3"
                      onChange={(ev) => {
                        setFilter({
                          ...filter,
                          dateEnd: ev.target.value,
                        });
                      }}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Financeira</Label>
                  <select
                    id="bank"
                    name="bank"
                    className="form-control"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        febrabanId: ev.target.value,
                      });
                    }}
                  >
                    <option value="">Todos</option>
                    <option value="626">C6 Consignado</option>
                    <option value="149">FACTA Financeira</option>
                    <option value="074">SAFRA</option>
                    <option value="623">PAN</option>
                    <option value="389">BANCO MERCANTIL</option>
                    <option value="329">iCred</option>
                    <option value="465">Quero+Crédito</option>
                    <option value="318">BMG</option>
                    <option value="329-2">SABEMI</option>
                    <option value="324">LOTUS MAIS</option>
                    <option value="457">457 - UY3</option>
                  </select>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Nº Proposta</Label>
                  <Input
                    type="text"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        numberProposal: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Produto</Label>
                  <select className="form-control">
                    <option value="">FGTS</option>
                  </select>
                </FormGroup>
              </Col>
              <Col md="6">
                <InputIndicationView filter={filter} setFilter={setFilter} />
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="primary" onClick={() => handleList(false)}>
              <i className="fas fa-search" /> Buscar
            </Button>
            {/* <Button
              color="success"
              onClick={() => {
                scheduleExcel(8, filter, history);
              }}
            >
              <i className="fas fa-file-excel" /> Exportar
            </Button> */}
          </CardFooter>
        </Card>

        <Card className="shadow mt-4">
          <CardBody className="bg-secondary">
            <h3>Resultado comissionamento</h3>
            <Row>
              <Col md="3" className="d-flex align-content-stretch">
                <CardResumoComponent
                  name="Vendedores"
                  value={
                    <TextValue>
                      R$ {moneyMask(Number(resumeCommission.seller).toFixed(2))}
                    </TextValue>
                  }
                  colorValue="default"
                  icon="fas fa-hands-helping"
                  isLoading={false}
                />
              </Col>
              <Col md="3" className="d-flex align-content-stretch">
                <CardResumoComponent
                  name="Expectativa"
                  value={
                    <TextValue className='text-primary'>
                      R$ {moneyMask(Number(resumeCommission.promoter).toFixed(2))}
                    </TextValue>
                  }
                  colorValue="primary"
                  icon="fas fa-chart-line"
                  isLoading={false}
                />
              </Col>
              <Col md="3" className="d-flex align-content-stretch">
                <CardResumoComponent
                  name="Aguardando"
                  value={
                    <TextValue className='text-warning'>
                      R$ {moneyMask(Number(resumeCommission.wait).toFixed(2))}
                    </TextValue>
                  }
                  colorValue="warning"
                  icon="fas fa-pause"
                  isLoading={false}
                />
              </Col>
              <Col md="3" className="d-flex align-content-stretch">
                <CardResumoComponent
                  name="Recebido"
                  value={
                    <TextValue className='text-success'>
                      R$ {moneyMask(Number(resumeCommission.balance).toFixed(2))}
                    </TextValue>
                  }
                  colorValue="success"
                  icon="fas fa-wallet"
                  isLoading={false}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className="shadow mt-4">
          <CardHeader>
            <Title>Detalhamento</Title>
          </CardHeader>
          {result.length > 0 ? (
            <>
              <Table hover responsive>
                <thead>
                  <tr className="bg-secondary">
                    <th>DATA CADASTRO</th>
                    <th>BANCO</th>
                    <th>Nº CONTRATO</th>
                    <th>VALOR LIBERADO</th>
                    <th>STATUS</th>
                    <th>PAGO</th>
                    <th>AGUARDANDO</th>
                    <th>EXPECTATIVA</th>
                    <th>VENDEDOR</th>
                    <th>DATA PAGAMENTO</th>
                  </tr>
                </thead>
                <tbody>
                  {result.map((register, key) => (
                    <tr key={key}>
                      <td>{register.createdAt}</td>
                      <td>{register.bankName}</td>
                      <td>{register.numberProposal}</td>
                      <td>
                        R$ {moneyMask(Number(register.valueLiberty).toFixed(2))}
                      </td>
                      <td>
                        {register.datePaymentComission ? (
                          <Badge color="success">PAGO</Badge>
                        ) : (
                          <Badge color="warning">AGUARDANDO PAGAMENTO</Badge>
                        )}
                      </td>
                      <td className="font-14 text-success">
                        R${' '}
                        {moneyMask(
                          Number(register?.valueComissionProposal).toFixed(2)
                        )}
                      </td>
                      <td className="font-14">
                        R${' '}
                        {moneyMask(
                          Number(
                            register?.valueComissionPromoter -
                            register?.valueComissionProposal
                          ).toFixed(2)
                        )}
                      </td>
                      <td className="font-14">
                        R${' '}
                        {moneyMask(
                          Number(register?.valueComissionPromoter).toFixed(2)
                        )}
                      </td>
                      <td className="font-14">
                        R${' '}
                        {moneyMask(
                          Number(register?.valueComissionSeller).toFixed(2)
                        )}
                      </td>
                      <td>{register.datePaymentComission}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {showBtnMoreRegisters && (
                <Card className="shadow">
                  <CardBody color="secondary">
                    <Button
                      className="w-100"
                      onClick={() => {
                        setFilter({ ...filter });
                        handleList(true);
                      }}
                    >
                      Carregar mais...
                    </Button>
                  </CardBody>
                </Card>
              )}
            </>
          ) : (
            <Alert color="secondary">
              Não foram identificados registros para esta consulta
            </Alert>
          )}
        </Card>
      </Container>
    </>
  );
};

export default ComissionPromoterReportView;
