import { useState, useRef } from 'react';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
} from 'reactstrap';
import Header from 'components/Headers/Header';
import { maskCPF, phoneMask, maskDate } from 'utils/mask';
import { Title } from 'views/Dashboard/styles';
import SampleModalView from 'components/Modals/SampleModalView';
import {
  ICredTable,
  GrandinoBankTable,
  FactaTable,
  VCTexTable,
} from 'utils/EnumTablesBank';
import { BanksSimulatorFGTS } from 'utils/EnumBanksSimulatorFgts';
import ConditionSimulationFGTSICRED from '../components/Digitation/ConditionSimulationFGTSICRED';
import InputIndicationView from 'components/Form/Input/InputIndicationView';
import Swal from 'sweetalert2';
import { SaveSimulationFgtsService } from 'services/Digitation/SimulationService';
import { useEffect } from 'react';
import { io } from 'socket.io-client';
import FormRegisterAddressComponent from 'components/Form/Register/FormRegisterAddressComponent';
import { DigitationProposalFGTSService } from 'services/Digitation/ReDigitationService';
import ConditionSimulationFGTSGRANDINOBANK from '../components/Digitation/ConditionSimulationFGTSGRANDINOBANK';
import ConditionSimulationFGTSFacta from '../components/Digitation/ConditionSimulationFGTSFacta';
import { ShowClientProposalService } from 'services/Register/ClientService';
import dayjs from 'dayjs';
import TableResultSimulationComponent from './TableResultSimulationComponent';
import { BankSelectData } from 'views/AccessBank/service/bankSelectData';
import ConditionSimulationFGTSDefault from '../components/Digitation/ConditionSimulationFGTSDefault';
import ConditionSimulationFGTSVcTex from '../components/Digitation/ConditionSimulationFGTSVcTex';
import ConditionSimulationFGTSV8Digital from '../components/Digitation/ConditionSimulationFGTSV8Digital';
import ConditionSimulationFGTSBancoPan from '../components/Digitation/ConditionSimulationFGTSBancoPan';
import { useHistory } from 'react-router-dom';
import ConditionSimulationFGTSLotusMais from '../components/Digitation/ConditionSimulationFGTSLotusMais';
import ConditionSimulationFGTSPrataDigital from '../components/Digitation/ConditionSimulationFGTSPrataDigital';

import { Label } from 'views/PersonalCredit/Component/styles';
import { ContainerButton } from 'views/Dashboard/styles';

let socket = null;
const NewSimulatorFgtsView = () => {
  if (!socket) {
    socket = io('wss://marketplace.izidev.com.br/', {
      // socket = io('ws://localhost:3001/', {
      query: {},
      transports: ['websocket'],
      reconnectionDelay: 10000,
      auth: {
        token: localStorage.getItem('IZIDEV_AUTH_TOKEN'),
      },
    });
  }

  const queryParams = new URLSearchParams(window.location.search);

  const inputWhatsApp = useRef(null);
  const history = useHistory();
  const [showLoading, setShowLoading] = useState(false);
  const [hideSimulation, setHideSimulation] = useState(false);
  const [modalBank, setModalBank] = useState(false);
  const [bankSelected, setBankSelected] = useState('');
  const [tableSelected, setTableSelected] = useState([]);
  const [installmentSelected, setInstallmentSelected] = useState(10);
  const [valueSolicited, setValueSolicited] = useState(null);
  const [useSafe, setUseSafe] = useState(0);
  const [banks, setBanks] = useState([]);
  const [resultSimulation, setResultSimulation] = useState([]);
  const [searchClient, setSearchClient] = useState({});
  const [conditionSelected, setConditionSelected] = useState({
    isSelected: false,
    bank: null,
    febrabanId: null,
    bankId: null,
    table: null,
  });
  const [btnSimulationDisabled, setBtnSimulationDisabled] = useState(false);
  const [simulation, setSimulation] = useState({
    uuid: null,
    name: null,
    document: null,
    birthDate: null,
    whatsapp: null,
    indicationId: null,
  });
  const [proposal, setProposal] = useState({
    simulationId: null,
    tableId: null,
    febrabanId: null,
    documentRg: null,
    dateEmissionRg: null,
    organEmissionRg: null,
    stateEmissionRg: null,
    email: null,
    sex: null,
    motherName: null,
    cep: null,
    address: null,
    number: null,
    district: null,
    city: null,
    stage: null,
    complement: null,
    pix: null,
  });
  const [proposalFinish, setProposalFinish] = useState({
    isFinish: false,
    numberProposal: null,
    link: null,
    whatsappNumber: null,
  });
  const [typePayment, setTypePayment] = useState('ACCOUNT');

  const handleSimulation = () => {
    if (!simulation.document) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'CPF não informado',
      });
      return;
    }
    if (!simulation.whatsapp) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Nº WhatsApp não informado',
      });
      return;
    }

    if (banks.length <= 0) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Bancos / Condições para simulação, não selecionada(s)',
      });
      return;
    }
    Swal.fire({
      title: 'Salvando simulação',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    setResultSimulation([]);
    setConditionSelected({
      isSelected: false,
      bank: null,
      febrabanId: null,
      bankId: null,
      table: null,
    });

    SaveSimulationFgtsService({
      name: simulation.name,
      document: simulation.document,
      whatsapp: simulation.whatsapp,
      birthDate: simulation.birthDate,
      indicationId: simulation.indicationId,
    })
      .then(({ data }) => {
        setBtnSimulationDisabled(true);
        setShowLoading(true);
        setSimulation({
          ...simulation,
          uuid: data.resultSet.uuid,
          id: data.resultSet.simulationId,
        });
        handleSocket(data.resultSet.uuid);
        Swal.close();
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleCreateOperation = () => {
    Swal.fire({
      title: 'Salvando Proposta',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    DigitationProposalFGTSService(proposal.simulationId, proposal)
      .then(({ data }) => {
        setConditionSelected({
          ...conditionSelected,
          isSelected: false,
        });
        setProposalFinish({
          isFinish: true,
          numberProposal: data.numberProposal,
          whatsappNumber: simulation.whatsapp.replace(/[^0-9]/g, ''),
          link: data.linkFormalize,
        });
        Swal.close();
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleConnectSocket = () => {
    socket.on('connect', () => {});

    socket.on('simulation', (res) => {
      if (res?.status == false) {
        Swal.fire({
          icon: 'error',
          title: res.message,
          text: 'Tente realizar novamente a simulação',
        });
        return;
      }

      setResultSimulation((prevList) => [...prevList, res.content]);
    });

    socket.on('simulation-finish', (res) => {
      if (res.status === true) {
        setShowLoading(false);
        setBtnSimulationDisabled(false);
      }
    });

    return () => socket.disconnect();
  };

  const handleSocket = (uuid) => {
    socket.emit('simulation', {
      uuid: uuid,
      banks: banks,
    });
  };

  const handleRemoveBank = (id) => {
    const auxBanks = banks.filter((res) => res.id !== id);
    setBanks(auxBanks);
    handleSetCondition(simulation.saveCondition, auxBanks);
  };

  const handleGetClient = async (cpf) => {
    setResultSimulation([]);
    inputWhatsApp.current.focus();
    if (!cpf) return;

    Swal.fire({
      title: 'Buscando cliente',
      text: 'Por favor, aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    await ShowClientProposalService(cpf.replace(/[^0-9]/g, ''))
      .then(({ data }) => {
        Swal.close();
        if (!data?.resultSet || !data?.resultSet?.name) {
          return;
        }
        setSimulation({
          ...simulation,
          name: data.resultSet.name,
          birthDate: dayjs(data.resultSet.birthDate).format('DD/MM/YYYY'),
          whatsapp: data.resultSet.whatsappNumber,
        });
      })
      .catch(() => {
        Swal.close();
      });
  };

  const handleGetClientProposal = async (force = 0) => {
    setSearchClient({});
    Swal.fire({
      title: 'Buscando',
      text: 'Por favor, aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    await ShowClientProposalService(
      simulation.document.replace(/[^0-9]/g, ''),
      force
    ).then(({ data }) => {
      if (!data?.resultSet || !data?.resultSet?.name) {
        Swal.fire({
          icon: 'warning',
          title: 'Não identificado',
          text: 'Registro não identificado na base',
        });
        return;
      }

      Swal.close();

      setSearchClient(data.resultSet);

      setProposal({
        ...proposal,
        email: data.resultSet?.email ?? '',
        sex: data.resultSet?.sex ?? '',
        motherName: data.resultSet?.nameMother ?? '',
        cep: data.resultSet?.CEP ?? '',
        address: data.resultSet?.address ?? '',
        numberAddress: data.resultSet?.numberAddress ?? '',
        district: data.resultSet?.district ?? '',
        city: data.resultSet?.city ?? '',
        state: data.resultSet?.state ?? '',
        complement: data.resultSet?.complement ?? '',
        documentRg: data.resultSet?.documentRG ?? '',
        dateEmissionRg: data.resultSet?.dateEmissionRG ?? '',
        organEmissionRg: data.resultSet?.emissionRG ?? '',
        stateEmissionRg: data.resultSet?.ufRG ?? '',
      });
    });
  };

  const proceedWithAddingCondition = () => {
    const saveBanks = [
      ...banks.filter((e) => e.id !== Number(bankSelected)),
      {
        id: Number(bankSelected),
        tableId: tableSelected,
        installments: installmentSelected,
        useSafe: useSafe,
        valueSolicited: valueSolicited
          ? Number(valueSolicited.replace('.', '').replace(',', '.'))
          : null,
      },
    ];

    setBanks(saveBanks);
    setModalBank(false);
    setBankSelected('');
    setValueSolicited(null);
    setTableSelected([]);
    setUseSafe(0);
    setInstallmentSelected(10);

    if (simulation.saveCondition > 0) {
      handleSetCondition(simulation.saveCondition, saveBanks);
    }
  };

  const handleSetCondition = (status, saveBanks) => {
    localStorage.setItem(
      'IZIDEV_CONDITION_SIMULATION',
      JSON.stringify({
        status: status,
        banks: saveBanks,
      })
    );
  };

  const findTableBank = (bankId, table) => {
    if (bankId == 6) {
      return ICredTable.find((res) => res.code == table)?.name;
    }

    if (bankId == 19) {
      return GrandinoBankTable.find((res) => res.code == table)?.name;
    }

    if (bankId == 3) {
      return FactaTable.find((res) => res.code == table)?.name;
    }

    if (bankId == 20) {
      return VCTexTable.find((res) => res.code == table)?.name;
    }

    return '';
  };

  useEffect(() => {
    handleConnectSocket();

    const conditionSimulation = JSON.parse(
      localStorage.getItem('IZIDEV_CONDITION_SIMULATION')
    );

    if (conditionSimulation?.status > 0) {
      setSimulation({
        ...simulation,
        saveCondition: 1,
      });
      setBanks(conditionSimulation.banks);
    }

    if (queryParams.get('cpf')) {
      setSimulation({
        ...simulation,
        document: maskCPF(queryParams.get('cpf')),
        birthDate: dayjs(queryParams.get('birthDate')).format('DD/MM/YYYY'),
        name: queryParams.get('name'),
        whatsapp: phoneMask(queryParams.get('whatsapp')),
      });
    }
  }, []);

  useEffect(() => {
    setTypePayment('ACCOUNT');
  }, [resultSimulation]);

  return (
    <>
      <Header titlePage="FGTS: Proposta FGTS" />
      <Container className="mt--9" fluid>
        {!hideSimulation && (
          <>
            <Card className="shadow">
              <CardHeader>
                <h1>
                  <i className="far fa-sticky-note" /> FGTS - Realizar Simulação
                </h1>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <Label>
                        CPF: <span className="text-danger">*</span>
                      </Label>
                      <Input
                        placeholder="000.000.000-00"
                        value={simulation.document}
                        onChange={(ev) => {
                          setSimulation({
                            ...simulation,
                            document: maskCPF(ev.target.value),
                          });
                        }}
                        onBlur={() => {
                          handleGetClient(simulation.document);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>
                        Celular WhatsApp: <span className="text-danger">*</span>
                      </Label>
                      <input
                        className="form-control"
                        placeholder="(xx) x xxxx-xxxx"
                        ref={inputWhatsApp}
                        value={simulation.whatsapp}
                        onChange={(ev) => {
                          setSimulation({
                            ...simulation,
                            whatsapp: phoneMask(ev.target.value),
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Nome Completo:</Label>
                      <Input
                        value={simulation.name}
                        onChange={(ev) => {
                          setSimulation({
                            ...simulation,
                            name: ev.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Data de Nascimento:</Label>
                      <Input
                        placeholder="--/--/----"
                        value={simulation.birthDate}
                        onChange={(ev) => {
                          setSimulation({
                            ...simulation,
                            birthDate: maskDate(ev.target.value),
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <InputIndicationView
                        filter={simulation}
                        setFilter={(ev) => {
                          setSimulation({
                            ...simulation,
                            indicationId: ev?.indicationId,
                          });
                        }}
                        defaultValue={
                          simulation?.indicationId > 0
                            ? {
                                value: simulation?.indicationId ?? null,
                                label: simulation?.nameIndication ?? null,
                              }
                            : {}
                        }
                        isClearInput={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>
                        Banco(s): <span className="text-danger">*</span>
                      </Label>
                      <InputGroup>
                        <select
                          className="form-control"
                          value={bankSelected}
                          onChange={(ev) => {
                            setBankSelected(ev.target.value);
                          }}
                        >
                          <option value="">Selecione...</option>
                          <option value="6">ICRED</option>
                          <option value="19">GRANDINO BANK</option>
                          <option value="3">FACTA</option>
                          <option value="21">PRESENÇA BANK</option>
                          <option value="20">VCTEX</option>
                          <option value="22">V8 DIGITAL</option>
                          <option value="4">BANCO PAN</option>
                          <option value="17">LOTUS MAIS</option>
                          <option value="14">PRATA DIGITAL</option>
                        </select>
                        <InputGroupAddon addonType="append">
                          <Button
                            color="primary"
                            onClick={() => {
                              setModalBank(true);
                            }}
                            disabled={!bankSelected}
                          >
                            <i className="fas fa-plus" /> Adicionar Banco
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <div className="d-flex align-items-center">
                      <Label className="switch">
                        <Input
                          id="status"
                          type="checkbox"
                          checked={simulation.saveCondition > 0}
                          size="sm"
                          onChange={(ev) => {
                            const statusCondigtion = ev.target.checked ? 1 : 0;
                            setSimulation({
                              ...simulation,
                              saveCondition: statusCondigtion,
                            });
                            handleSetCondition(statusCondigtion, banks);
                          }}
                        />
                        <span className="slider round" />
                      </Label>
                      <span className="ml-2">Salvar condição de pesquisa</span>
                    </div>
                  </Col>
                </Row>
                {banks.length > 0 && (
                  <>
                    <div className="mt-4">
                      <Title>Condições de consulta</Title>
                      <Alert color="secondary" className="p-3">
                        {banks.map((bankUsed, key) => (
                          <Card className="shadow" key={key}>
                            <CardBody>
                              <Row className="d-flex align-items-center">
                                <Col md="3">
                                  <h5 className="m-0">Banco / Fintech</h5>
                                  <img
                                    src={
                                      BankSelectData(
                                        BanksSimulatorFGTS.find(
                                          (res) => res.id == bankUsed.id
                                        )?.febrabanId
                                      ).img
                                    }
                                    alt={
                                      BanksSimulatorFGTS.find(
                                        (res) => res.id == bankUsed.id
                                      )?.name
                                    }
                                    height="40px"
                                  />
                                </Col>
                                <Col md="5">
                                  <h5 className="m-0">Tabela(s)</h5>
                                  {bankUsed?.tableId.length > 0 &&
                                    bankUsed?.tableId.map((table, keyTable) => (
                                      <>
                                        <Badge color="primary" key={keyTable}>
                                          {table} -{' '}
                                          {findTableBank(bankUsed.id, table)}
                                        </Badge>
                                        {` `}
                                      </>
                                    ))}
                                </Col>
                                {Number(bankUsed.valueSolicited) <= 0 && (
                                  <Col md="2">
                                    <h5 className="m-0">Parcelas</h5>
                                    <select
                                      className="form-control"
                                      value={Number(bankUsed.installments)}
                                      onChange={(ev) => {
                                        const aux = [...banks];
                                        aux[key].installments = ev.target.value;
                                        setBanks(aux);
                                      }}
                                    >
                                      <option value={3}>3 PARCELAS</option>
                                      <option value={4}>4 PARCELAS</option>
                                      <option value={5}>5 PARCELAS</option>
                                      <option value={6}>6 PARCELAS</option>
                                      <option value={7}>7 PARCELAS</option>
                                      <option value={8}>8 PARCELAS</option>
                                      <option value={9}>9 PARCELAS</option>
                                      <option value={10}>10 PARCELAS</option>
                                      <option value={11}>11 PARCELAS</option>
                                      <option value={12}>12 PARCELAS</option>
                                    </select>
                                  </Col>
                                )}

                                <Col md="2" className="mt-sm-0 mt-3">
                                  {bankUsed.valueSolicited > 0 ? (
                                    <>
                                      - Valor solicitado:{' '}
                                      <strong>
                                        R$ {bankUsed.valueSolicited}
                                      </strong>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  <Button
                                    color="danger"
                                    size="sm"
                                    className="float-right"
                                    onClick={() => {
                                      handleRemoveBank(bankUsed.id);
                                    }}
                                  >
                                    <i className="fas fa-trash-alt" />
                                  </Button>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        ))}
                      </Alert>
                    </div>
                  </>
                )}
              </CardBody>
              <CardFooter>
                <ContainerButton>
                  <Button
                    className="btn-margin"
                    color="success"
                    onClick={handleSimulation}
                    disabled={btnSimulationDisabled}
                  >
                    <i className="fas fa-clipboard-check" /> Realizar Simulação
                  </Button>
                  <Button
                    className="btn-margin"
                    color="warning"
                    onClick={() => history.push('/admin/index')}
                  >
                    <i className="fas fa-chevron-left" /> Voltar
                  </Button>
                </ContainerButton>
              </CardFooter>
            </Card>
            <Alert className="mt-2" color="secondary">
              <p className="m-0">
                - Campos com <strong className="text-danger">*</strong> são
                obrigatórios
              </p>
            </Alert>

            {showLoading && (
              <Alert color="primary">
                <h2 className="m-0 text-white">
                  <i className="fa-spin fas fa-spinner" /> Realizando Consulta,
                  por favor aguarde...
                </h2>
              </Alert>
            )}

            {resultSimulation.length > 0 && (
              <Card className="mt-4 shadow">
                <CardHeader>
                  <h2>
                    <i className="far fa-sticky-note" /> Resultado da simulação
                  </h2>
                </CardHeader>
                <CardBody className="bg-secondary p-2">
                  {resultSimulation.length > 0 &&
                    resultSimulation.map((register, key) => (
                      <Card className="shadow mt-1 mb-1" key={key}>
                        <CardHeader className="p-2">
                          <img
                            src={BankSelectData(register.febrabanId).img}
                            alt={register.name}
                            height="80px"
                          />
                        </CardHeader>
                        <CardBody>
                          {register?.message && (
                            <>
                              <p className="text-danger">
                                <i className="fas fa-exclamation-triangle" />{' '}
                                {register?.message}
                              </p>
                            </>
                          )}
                          {register?.tables?.length > 0 && (
                            <Row className="px-1">
                              {register.tables.map((table, tableKey) => (
                                <Col
                                  md="4"
                                  sm="6"
                                  className="d-flex align-items-stretch px-1"
                                  key={tableKey}
                                >
                                  <Card className="shadow w-100 mt-1 mb-1">
                                    <CardBody>
                                      {!table?.message ? (
                                        <TableResultSimulationComponent
                                          table={table}
                                          handleSelectSimulation={() => {
                                            const result = {
                                              isSelected: true,
                                              bank: register.bank,
                                              febrabanId: register.febrabanId,
                                              bankId: register.bankId,
                                              table: table,
                                            };

                                            setConditionSelected(result);
                                            setHideSimulation(true);
                                            setProposal({
                                              ...proposal,
                                              name: simulation.name,
                                              document: simulation.document,
                                              simulationId: simulation.id,
                                              birthDate: simulation.birthDate,
                                              whatsappNumber:
                                                simulation.whatsapp,
                                              febrabanId: register.febrabanId,
                                              valueSolicited:
                                                table?.valueLiberty.toFixed(2),
                                              valueLiberty:
                                                table?.valueLiberty.toFixed(2),
                                              valueFinally:
                                                table?.valueFinally.toFixed(2),
                                              tableSelected: result,
                                            });

                                            window.scrollTo({
                                              top: 0,
                                              left: 0,
                                              behavior: 'smooth',
                                            });
                                          }}
                                        />
                                      ) : (
                                        <>
                                          <p className="m-0">
                                            Tabela:{' '}
                                            <strong>{table?.name}</strong>
                                          </p>
                                          <p className="text-danger">
                                            <i className="fas fa-exclamation-triangle" />{' '}
                                            {table.message}
                                          </p>
                                        </>
                                      )}
                                    </CardBody>
                                  </Card>
                                </Col>
                              ))}
                            </Row>
                          )}
                        </CardBody>
                      </Card>
                    ))}
                </CardBody>
              </Card>
            )}
          </>
        )}

        {conditionSelected.isSelected === true && (
          <Card className="mt-4 shadow">
            <CardHeader>
              <Button
                className="float-right"
                color="warning"
                onClick={() => {
                  setConditionSelected({
                    ...conditionSelected,
                    isSelected: false,
                  });
                  setHideSimulation(false);
                }}
              >
                <i className="fas fa-chevron-left" /> Voltar
              </Button>
              <h2>
                <i className="far fa-sticky-note" /> Detalhes da Proposta
              </h2>
            </CardHeader>
            <CardBody>
              <Card className="shadow mb-3">
                <CardHeader>Condição selecionada</CardHeader>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <h3 className="mt-2">
                        {conditionSelected.febrabanId} -{' '}
                        {conditionSelected.bank}
                      </h3>
                      <p className="font-20">
                        {conditionSelected?.table?.name}{' '}
                        {conditionSelected?.table?.useSafe > 0 && (
                          <Badge color="success">COM SEGURO</Badge>
                        )}
                      </p>
                    </Col>
                    <Col md="6">
                      <Row>
                        <Col md="6">
                          <Card className="bg-success text-white">
                            <CardBody>
                              <p className="m-0">Valor Liberado</p>
                              <p className="m-0 font-weight-bold">
                                R${' '}
                                {conditionSelected.table.valueLiberty.toFixed(
                                  2
                                )}
                              </p>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md="6">
                          <Card className="bg-success text-white">
                            <CardBody>
                              <p className="m-0">Valor Total</p>
                              <p className="m-0 font-weight-bold">
                                R${' '}
                                {conditionSelected.table.valueFinally.toFixed(
                                  2
                                )}
                              </p>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="shadow">
                <CardHeader>
                  <i className="fas fa-user-check" /> Informações do cliente
                  <Button
                    color="primary"
                    size="sm"
                    className="float-right"
                    onClick={() => handleGetClientProposal(1)}
                  >
                    <i className="fas fa-id-badge" /> Atualizar dados do cliente
                  </Button>
                </CardHeader>
                <CardBody>
                  {searchClient?.origin && searchClient.origin === 'COLD' && (
                    <Alert color="info">
                      <p className="m-0">
                        <i className="fas fa-info-circle" /> Informações do
                        cliente podem estar desatualizadas.
                      </p>
                    </Alert>
                  )}
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label>
                          Name <span className="text-danger">*</span>
                        </Label>
                        <Input
                          value={proposal.name}
                          onChange={(ev) => {
                            setProposal({
                              ...proposal,
                              name: ev.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label>
                          CPF <span className="text-danger">*</span>
                        </Label>
                        <Input readOnly value={simulation.document} />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label>
                          Data de Nascimento{' '}
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          placeholder="--/--/----"
                          value={proposal.birthDate}
                          onChange={(ev) => {
                            setProposal({
                              ...proposal,
                              birthDate: maskDate(ev.target.value),
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label>
                          RG <span className="text-danger">*</span>
                        </Label>
                        <Input
                          value={proposal.documentRg}
                          onChange={(ev) => {
                            setProposal({
                              ...proposal,
                              documentRg: ev.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label>
                          Data de Emissão <span className="text-danger">*</span>
                        </Label>
                        <Input
                          placeholder="--/--/----"
                          value={proposal.dateEmissionRg}
                          onChange={(ev) => {
                            setProposal({
                              ...proposal,
                              dateEmissionRg: maskDate(ev.target.value),
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label>
                          Orgão Emissor <span className="text-danger">*</span>
                        </Label>
                        <Input
                          value={proposal.organEmissionRg}
                          onChange={(ev) => {
                            setProposal({
                              ...proposal,
                              organEmissionRg: ev.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label>
                          Estado Emissor <span className="text-danger">*</span>
                        </Label>
                        <select
                          name="ufRG"
                          className="form-control"
                          value={proposal.stateEmissionRg}
                          onChange={(ev) => {
                            setProposal({
                              ...proposal,
                              stateEmissionRg: ev.target.value,
                            });
                          }}
                        >
                          <option value="" disabled="">
                            Selecione...
                          </option>
                          <option value="AC">Acre</option>
                          <option value="AL">Alagoas</option>
                          <option value="AP">Amapá</option>
                          <option value="AM">Amazonas</option>
                          <option value="BA">Bahia</option>
                          <option value="CE">Ceará</option>
                          <option value="DF">Distrito Federal</option>
                          <option value="ES">Espírito Santo</option>
                          <option value="GO">Goiás</option>
                          <option value="MA">Maranhão</option>
                          <option value="MT">Mato Grosso</option>
                          <option value="MS">Mato Grosso do Sul</option>
                          <option value="MG">Minas Gerais</option>
                          <option value="PA">Pará</option>
                          <option value="PB">Paraíba</option>
                          <option value="PR">Paraná</option>
                          <option value="PE">Pernambuco</option>
                          <option value="PI">Piauí</option>
                          <option value="RJ">Rio de Janeiro</option>
                          <option value="RN">Rio Grande do Norte</option>
                          <option value="RS">Rio Grande do Sul</option>
                          <option value="RO">Rondônia</option>
                          <option value="RR">Roraima</option>
                          <option value="SC">Santa Catarina</option>
                          <option value="SP">São Paulo</option>
                          <option value="SE">Sergipe</option>
                          <option value="TO">Tocantins</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label>
                          E-mail <span className="text-danger">*</span>
                        </Label>
                        <Input
                          value={proposal.email}
                          onChange={(ev) => {
                            setProposal({
                              ...proposal,
                              email: ev.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label>
                          Sexo <span className="text-danger">*</span>
                        </Label>
                        <select
                          name="sex"
                          className="form-control"
                          value={proposal.sex}
                          onChange={(ev) => {
                            setProposal({ ...proposal, sex: ev.target.value });
                          }}
                        >
                          <option value="" disabled="">
                            Selecione...
                          </option>
                          <option value="M">Masculino</option>
                          <option value="F">Feminino</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>
                          Nome da Mãe <span className="text-danger">*</span>
                        </Label>
                        <Input
                          value={proposal.motherName}
                          onChange={(ev) => {
                            setProposal({
                              ...proposal,
                              motherName: ev.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="shadow mt-4">
                <CardHeader>
                  <h3>
                    <i className="fas fa-map-marked-alt" /> Endereço
                  </h3>
                </CardHeader>
                <CardBody>
                  <FormRegisterAddressComponent
                    register={proposal}
                    setRegister={setProposal}
                    isRequired={true}
                  />
                </CardBody>
              </Card>

              <Card className="shadow mt-4">
                <CardHeader>
                  <i className="fas fa-university" /> Dados bancários
                </CardHeader>
                <CardBody>
                  {(proposal.febrabanId === '329-6' ||
                    proposal.febrabanId === '274') && (
                    <Row className="mb-3">
                      <Col md="6">
                        <Button
                          color={
                            typePayment === 'ACCOUNT' ? 'success' : 'secondary'
                          }
                          onClick={() => {
                            setTypePayment('ACCOUNT');
                          }}
                        >
                          DADOS BANCÁRIOS
                        </Button>
                        <Button
                          color={
                            typePayment === 'PIX' ? 'success' : 'secondary'
                          }
                          onClick={() => {
                            setTypePayment('PIX');
                          }}
                        >
                          CHAVE PIX
                        </Button>
                      </Col>
                    </Row>
                  )}

                  {(proposal.febrabanId === '329' ||
                    proposal.febrabanId === '329-4' ||
                    typePayment === 'PIX') && (
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label>
                            PIX <span className="text-danger">*</span>
                          </Label>
                          <Input
                            value={proposal.pix}
                            onChange={(ev) => {
                              setProposal({
                                ...proposal,
                                pix: ev.target.value,
                              });
                            }}
                            placeholder="Informe o PIX: e-mail, celular, cpf, ou chave aleatória"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  {proposal.febrabanId != '329' &&
                    proposal.febrabanId != '329-4' &&
                    typePayment === 'ACCOUNT' && (
                      <>
                        <Row>
                          <Col md="4">
                            <FormGroup>
                              <Label>
                                Banco <span className="text-danger">*</span>
                              </Label>
                              <select
                                className="form-control"
                                value={proposal.bank}
                                onChange={(ev) => {
                                  setProposal({
                                    ...proposal,
                                    bank: ev.target.value,
                                  });
                                }}
                              >
                                <option value="">SELECIONE</option>
                                <option value="070">
                                  BRB - BANCO DE BRASÍLIA
                                </option>
                                <option value="104">
                                  CAIXA ECONOMICA FEDERAL
                                </option>
                                <option value="001">
                                  BANCO DO BRASIL S.A.
                                </option>
                                <option value="237">BANCO BRADESCO</option>
                                <option value="341">BANCO ITAÚ S.A.</option>
                                <option value="336">BANCO C6 S.A.</option>
                                <option value="033">
                                  BANCO SANTANDER S.A.
                                </option>
                                <option value="151">
                                  BANCO NOSSA CAIXA S.A.
                                </option>
                                <option value="745">BANCO CITIBANK S.A.</option>
                                <option value="041">
                                  BANCO DO ESTADO DO RIO GRANDE DO SUL S.A.
                                </option>
                                <option value="756">BANCO SICOOB</option>
                                <option value="748">BANCO SICREDI</option>
                                <option value="085">
                                  COOPERATIVA CENTRAL DE CRÉDITO - AILOS
                                </option>
                                <option value="399">
                                  HSBC BANK BRASIL S.A
                                </option>
                                <option value="756">
                                  BANCOOB - BANCO COOPERATIVO DO BRASIL S.A.
                                </option>
                                <option value="041">BANRISUL</option>
                                <option value="212">BANCO ORIGINAL</option>
                                <option value="260">
                                  NU BANK PAGAMENTOS S.A.
                                </option>
                                <option value="318">BANCO BMG S.A.</option>
                                <option value="336">
                                  BANCO C6 S.A. (C6 BANK)
                                </option>
                                <option value="077">BANCO INTER S.A.</option>
                                <option value="133">CRESOL</option>
                                {proposal.febrabanId == '074' ? (
                                  <>
                                    <option value="077">
                                      INTERMEDIUM S.A.
                                    </option>
                                    <option value="290">
                                      PAGSEGURO INTERNET S.A (PAGBANK)
                                    </option>
                                    <option value="413">
                                      BANCO VOTORANTIM S.A
                                    </option>
                                    <option value="197">
                                      STONE PAGAMENTOS S.A
                                    </option>
                                    <option value="169">
                                      BANCO OLÉ BONSUCESSO CONSIGNADO S.A
                                    </option>
                                    <option value="323">
                                      MERCADO PAGO (CARTEIRA DIGITAL)
                                    </option>
                                    <option value="655">
                                      NEON PAGAMENTOS S.A (FINTECH DO BANCO
                                      VOTORANTIM)
                                    </option>
                                    <option value="637">
                                      BANCO SOFISA S.A
                                    </option>
                                    <option value="336">
                                      BANCO C6 S.A – C6 BANK
                                    </option>
                                  </>
                                ) : (
                                  <></>
                                )}
                                <option value="074">BANCO SAFRA S.A</option>
                                <option value="623">BANCO PAN</option>
                                <option value="380">PICPAY SERVICOS S.A</option>
                                <option value="037">BANCO DO PARÁ</option>
                                <option value="121">AGIBANK</option>
                                <option value="335">BANCO DIGIO</option>
                                <option value="389">BANCO MERCANTIL</option>
                              </select>
                            </FormGroup>
                          </Col>
                          <Col xl="4">
                            <FormGroup>
                              <Label>
                                Agência <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                name="agencyBank"
                                value={proposal.agencyBank}
                                onChange={(ev) => {
                                  setProposal({
                                    ...proposal,
                                    agencyBank: ev.target.value,
                                  });
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col xl="4">
                            <FormGroup>
                              <Label>
                                Conta <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                name="numberAccountBank"
                                value={proposal.numberAccountBank}
                                onChange={(ev) => {
                                  setProposal({
                                    ...proposal,
                                    numberAccountBank: ev.target.value,
                                  });
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col xl="4">
                            <FormGroup>
                              <Label>
                                Dígito Conta{' '}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                name="digitAccountBank"
                                value={proposal.digitAccountBank}
                                onChange={(ev) => {
                                  setProposal({
                                    ...proposal,
                                    digitAccountBank: ev.target.value,
                                  });
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col xl="4">
                            <FormGroup>
                              <Label>
                                Tipo da conta{' '}
                                <span className="text-danger">*</span>
                              </Label>
                              <select
                                name="typeAccount"
                                className="form-control"
                                value={proposal.typeAccount}
                                onChange={(ev) => {
                                  setProposal({
                                    ...proposal,
                                    typeAccount: ev.target.value,
                                  });
                                }}
                              >
                                <option value="" disabled="">
                                  Selecione...
                                </option>
                                <option value="01">Conta Corrente</option>
                                <option value="02">Conta Poupança</option>
                              </select>
                            </FormGroup>
                          </Col>
                        </Row>
                      </>
                    )}
                </CardBody>
              </Card>
            </CardBody>
            <CardFooter>
              <Button color="success" onClick={handleCreateOperation}>
                <i className="fas fa-clipboard-check" /> Digitar Proposta
              </Button>
            </CardFooter>
          </Card>
        )}

        {proposalFinish.isFinish === true && (
          <div>
            <Card className="mt-4 shadow" color="success">
              <CardBody className="text-center text-white">
                <span>
                  <i className="fas fa-check fa-3x" />
                </span>
                <h1>Sucesso</h1>
                <Card color="white" className="mt-3 ">
                  <CardBody className="text-dark">
                    <p className="mb-0">Proposta:</p>
                    <h2 className="m-0 mb-2" style={{ fontSize: '30px' }}>
                      {proposalFinish.numberProposal}
                    </h2>
                    {proposalFinish.link && (
                      <>
                        <p className="mb-0 mt-4">Link de formalização</p>
                        <div>
                          <a
                            href={`${proposalFinish.link}`}
                            className="btn btn-primary mb-3"
                          >
                            <i className="fas fa-external-link-alt" /> Acessar
                            link
                          </a>
                        </div>
                        <div>
                          <textarea className="form-control text-center">
                            {proposalFinish.link}
                          </textarea>
                        </div>
                      </>
                    )}
                  </CardBody>
                </Card>
              </CardBody>
            </Card>
            <div>
              <Row>
                <Col md="6">
                  <Button
                    color="primary"
                    size="lg"
                    className="w-100 mt-3"
                    onClick={() => {
                      history.push('/admin/fgts/proposal');
                    }}
                  >
                    ESTEIRA DE PROPOSTAS
                  </Button>
                </Col>
                <Col md="6">
                  <Button
                    color="success"
                    size="lg"
                    className="w-100 mt-3"
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    NOVA PROPOSTA
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Container>
      <SampleModalView
        labelModal="Selecionar condição de simulação"
        icon="fas fa-file-invoice-dollar"
        isOpen={modalBank}
        setIsOpen={setModalBank}
        btnSubmitLabel={'Adicionar'}
        btnSubmitAction={() => {
          const existingCondition = banks.find(
            (e) => e.id === Number(bankSelected)
          );

          if (existingCondition) {
            Swal.fire({
              title: 'Condição já selecionada',
              text: 'Já existe uma condição selecionada para este banco. Deseja substituir a condição anterior?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Sim',
              cancelButtonText: 'Não',
            }).then((result) => {
              if (result.isConfirmed) {
                handleRemoveBank(existingCondition.id);
                proceedWithAddingCondition();
              } else {
                setModalBank(false);
              }
            });
            return;
          }

          if (Number(bankSelected) !== 21 && tableSelected.length <= 0) {
            Swal.fire({
              icon: 'warning',
              title: 'Tabela não selecionada',
              text: 'É obrigatório selecionar ao menos uma tabela',
            });
            return;
          }

          proceedWithAddingCondition();
        }}
        showBtnSubmit={true}
        size="xl"
      >
        <ConditionSimulationFGTSICRED
          bankId={Number(bankSelected)}
          setTableSelected={setTableSelected}
          installmentSelected={installmentSelected}
          setInstallmentSelected={setInstallmentSelected}
        />
        <ConditionSimulationFGTSGRANDINOBANK
          bankId={Number(bankSelected)}
          setTableSelected={setTableSelected}
          installmentSelected={installmentSelected}
          setInstallmentSelected={setInstallmentSelected}
        />
        <ConditionSimulationFGTSFacta
          bankId={Number(bankSelected)}
          setTableSelected={setTableSelected}
          installmentSelected={installmentSelected}
          setInstallmentSelected={setInstallmentSelected}
        />
        <ConditionSimulationFGTSVcTex
          bankId={Number(bankSelected)}
          setTableSelected={setTableSelected}
          valueSolicited={valueSolicited}
          setValueSolicited={setValueSolicited}
        />
        <ConditionSimulationFGTSV8Digital
          bankId={Number(bankSelected)}
          setTableSelected={setTableSelected}
          installmentSelected={installmentSelected}
          setInstallmentSelected={setInstallmentSelected}
          useSafe={useSafe}
          setUseSafe={setUseSafe}
        />
        <ConditionSimulationFGTSLotusMais
          bankId={Number(bankSelected)}
          setTableSelected={setTableSelected}
          installmentSelected={installmentSelected}
          setInstallmentSelected={setInstallmentSelected}
          useSafe={useSafe}
          setUseSafe={setUseSafe}
        />
        <ConditionSimulationFGTSBancoPan
          bankId={Number(bankSelected)}
          setTableSelected={setTableSelected}
          installmentSelected={installmentSelected}
          setInstallmentSelected={setInstallmentSelected}
          useSafe={useSafe}
          setUseSafe={setUseSafe}
        />
        <ConditionSimulationFGTSPrataDigital
          bankId={Number(bankSelected)}
          setTableSelected={setTableSelected}
          installmentSelected={installmentSelected}
          setInstallmentSelected={setInstallmentSelected}
          valueSolicited={valueSolicited}
          setValueSolicited={setValueSolicited}
          useSafe={useSafe}
          setUseSafe={setUseSafe}
        />
        <ConditionSimulationFGTSDefault
          bankId={Number(bankSelected)}
          setTableSelected={setTableSelected}
          installmentSelected={installmentSelected}
          setInstallmentSelected={setInstallmentSelected}
        />
      </SampleModalView>
    </>
  );
};

export default NewSimulatorFgtsView;
