import Header from 'components/Headers/Header';
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row
} from 'reactstrap';
import { brasilApiCep } from 'services/cepApi';
import { viaCEP } from 'services/cepApi';
import { ProposalPersonalCreditService } from 'services/Digitation/PersonalCreditService';
import { SimulationPersonalCreditService } from 'services/Digitation/PersonalCreditService';
import Swal from 'sweetalert2';
import { cepMask } from 'utils/mask';
import { maskDate } from 'utils/mask';
import { moneyMask } from 'utils/mask';
import { phoneMask } from 'utils/mask';
import { maskCPF } from 'utils/mask';
import { Label } from 'views/PersonalCredit/Component/styles';

const DigitationPersonalCreditView = () => {
  const history = useHistory();
  const [digitation, setDigitation] = useState({
    name: null,
    phone: null,
    birthDate: null,
    cpf: null,
    valueSolicited: null,
    registry: 111111111,
    ufRG: 'XX',
    stateCivil: 'SOLTEIRO'
  });
  const [resultSimulation, setResultSimulation] = useState({
    status: false
  });

  const handleSimule = () => {
    Swal.fire({
      title: 'Realizando simulação',
      text: 'Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    SimulationPersonalCreditService(digitation)
      .then((res) => {
        const { simulation } = res.data;
        setResultSimulation({
          status: true,
          ...simulation.result
        });

        setDigitation({
          ...digitation,
          simulationId: simulation.id,
          valueParc: simulation.result.installment_value,
          febrabanId: simulation.febrabanId,
          simulation: simulation.result
        });

        Swal.close();
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleDigitation = () => {
    Swal.fire({
      title: 'Realizando digitação',
      text: 'Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    ProposalPersonalCreditService(digitation.simulationId, digitation)
      .then((res) => {
        const { proposal } = res.data;
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          html: `
            <p>Proposta digitada com sucesso!</p>
            <div class="bg-secondary p-3">
            <h2>Nº da Proposta</h2>
            <h1 class="text-warning">${proposal.numberProposal}</h1>
            <a class="btn btn-success" target="blank" href="${proposal.urlFormalize}">Link de Formalização</a>
            </div>
            `,
          confirmButtonText: 'Sair'
        }).then((confirm) => {
          if (confirm.isConfirmed) {
            history.push('/admin/personal-credit/simulation');
          }
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleIdentifyAddressUserByCEP = async (ev) => {
    setDigitation({
      ...digitation,
      cep: cepMask(ev.target.value)
    });

    Swal.fire({
      title: 'Consultando CEP',
      text: 'Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    await brasilApiCep(ev.target.value)
      .then((res) => {
        Swal.close();
        const address = res.data;
        setDigitation({
          ...digitation,
          address: address.street,
          district: address.neighborhood,
          city: address.city,
          state: address.state
        });
      })
      .catch(async () => {
        viaCEP(ev.target.value)
          .then((res) => {
            Swal.close();
            const address = res.data;
            setDigitation({
              ...digitation,
              address: address.logradouro,
              district: address.bairro,
              city: address.localidade,
              state: address.uf
            });
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `CEP Não encontrado`
              // footer: '<a href="">Why do I have this issue?</a>'
            });
            setDigitation({
              ...digitation,
              address: null,
              district: null,
              city: null,
              state: null
            });
          });
      });
  };

  useEffect(() => {}, []);

  return (
    <>
      <Header titlePlage="Crédito Pessoal - Digitação" />
      <Container className="mt--9" fluid>
        <Card>
          <CardHeader>Digitação</CardHeader>
          <CardBody>
            <h3>Dados Pessoais</h3>
            <Row>
              <Col md="3">
                <FormGroup>
                  <Label>CPF</Label>
                  <Input
                    value={digitation.cpf}
                    onChange={(ev) => {
                      setDigitation({
                        ...digitation,
                        cpf: maskCPF(ev.target.value)
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Nome</Label>
                  <Input
                    onChange={(ev) => {
                      setDigitation({
                        ...digitation,
                        name: ev.target.value
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>WhatsApp</Label>
                  <Input
                    value={digitation.phone}
                    onChange={(ev) => {
                      setDigitation({
                        ...digitation,
                        phone: phoneMask(ev.target.value)
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Data de Nascimento</Label>
                  <Input
                    max={10}
                    maxLength={10}
                    value={digitation.birthDate}
                    onChange={(ev) => {
                      setDigitation({
                        ...digitation,
                        birthDate: maskDate(ev.target.value)
                      });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>

            <h3>Simulação</h3>
            <Row>
              <Col md="3">
                <FormGroup>
                  <Label>Valor solicitado</Label>
                  <Input
                    value={digitation.valueSolicited}
                    onChange={(ev) => {
                      setDigitation({
                        ...digitation,
                        valueSolicited: moneyMask(ev.target.value)
                      });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="success" onClick={handleSimule}>
              Realizar Simulação
            </Button>
          </CardFooter>
        </Card>
        {resultSimulation.status === true && (
          <>
            <Card className="mt-3">
              <CardHeader>Resultado Simulação</CardHeader>
              <CardBody>
                <div className="bg-secondary rounded p-4">
                  <Row>
                    <Col>
                      <h3>Valor Solicitado</h3>
                      <h1>
                        <strong className="text-warning">
                          R$ {moneyMask(resultSimulation.value_free.toFixed(2))}
                        </strong>
                      </h1>
                    </Col>
                    <Col>
                      <h3>Parcelas</h3>
                      <h1>
                        <small>12x de</small>{' '}
                        <strong className="text-warning">
                          R${' '}
                          {moneyMask(
                            resultSimulation.installment_value.toFixed(2)
                          )}
                        </strong>
                      </h1>
                    </Col>
                    <Col>
                      <h3>Limite necessário no cartão de créditos</h3>
                      <h1 className="text-success">
                        <strong>
                          R${' '}
                          {moneyMask(resultSimulation.credit_limit.toFixed(2))}
                        </strong>
                      </h1>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
            <Card className="mt-3">
              <CardHeader>Proposta</CardHeader>
              <CardBody>
                <h3>Informações do cliente</h3>
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <Label>Sexo</Label>
                      <select
                        name="sex"
                        className="form-control"
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            sex: ev.target.value
                          });
                        }}
                      >
                        <option value="">Selecione</option>
                        <option value="M">Masculino</option>
                        <option value="F">Feminino</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Nome da mãe</Label>
                      <Input
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            nameMother: ev.target.value
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Nº RG</Label>
                      <Input
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            documentRG: ev.target.value
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Data Emissão RG</Label>
                      <Input
                        value={digitation.dateEmissionRG}
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            dateEmissionRG: maskDate(ev.target.value)
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>E-mail</Label>
                      <Input
                        type="email"
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            email: ev.target.value
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <h3>Endereço</h3>
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <Label>CEP</Label>
                      <Input
                        value={digitation.cep}
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            cep: cepMask(ev.target.value)
                          });
                        }}
                        onBlur={handleIdentifyAddressUserByCEP}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="7">
                    <FormGroup>
                      <Label>Rua</Label>
                      <Input
                        value={digitation.address}
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            address: ev.target.value
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <Label>Número</Label>
                      <Input
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            numberAddress: ev.target.value
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>Bairro</Label>
                      <Input
                        value={digitation.district}
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            district: ev.target.value
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>Cidade</Label>
                      <Input value={digitation.city} readOnly />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>Estado</Label>
                      <Input value={digitation.state} readOnly />
                    </FormGroup>
                  </Col>
                </Row>

                <h3>Dados bancários</h3>
                <Row>
                  <Col xl="3">
                    <div className="form-group">
                      <label htmlFor="bank">Banco</label>
                      <select
                        id="bank"
                        name="bank"
                        className="form-control"
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            bank: ev.target.value
                          });
                        }}
                      >
                        <option value="">SELECIONE</option>
                        <option value="104">CAIXA ECONOMICA FEDERAL</option>
                        <option value="001">BANCO DO BRASIL S.A.</option>
                        <option value="237">BANCO BRADESCO</option>
                        <option value="341">BANCO ITAÚ S.A.</option>
                        <option value="336">BANCO C6 S.A.</option>
                        <option value="033">BANCO SANTANDER S.A.</option>
                        <option value="151">BANCO NOSSA CAIXA S.A.</option>
                        <option value="745">BANCO CITIBANK S.A.</option>
                        <option value="041">
                          BANCO DO ESTADO DO RIO GRANDE DO SUL S.A.
                        </option>
                        <option value="756">BANCO SICOOB</option>
                        <option value="748">BANCO SICREDI</option>
                        <option value="085">
                          COOPERATIVA CENTRAL DE CRÉDITO - AILOS
                        </option>
                        <option value="399">HSBC BANK BRASIL S.A</option>
                        <option value="756">
                          BANCOOB - BANCO COOPERATIVO DO BRASIL S.A.
                        </option>
                        <option value="041">BANRISUL</option>
                        <option value="212">BANCO ORIGINAL</option>
                        <option value="260">NU BANK PAGAMENTOS S.A.</option>
                        <option value="318">BANCO BMG S.A.</option>
                        <option value="336">BANCO C6 S.A. (C6 BANK)</option>
                        <option value="077">BANCO INTER S.A.</option>
                      </select>
                    </div>
                  </Col>
                  <Col xl="3">
                    <div className="form-group">
                      <label htmlFor="agency">Agência</label>
                      <input
                        id="agency"
                        name="agency"
                        className="form-control"
                        placeholder="Informe o nome da Indicação"
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            agencyBank: ev.target.value
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col xl="3">
                    <div className="form-group">
                      <label htmlFor="numberAccount">Número da conta</label>
                      <input
                        id="numberAccount"
                        name="numberAccount"
                        className="form-control"
                        placeholder="Informe o nome da Indicação"
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            numberAccountBank: ev.target.value
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col xl="3">
                    <div className="form-group">
                      <label htmlFor="numberAccount">Digito da conta</label>
                      <input
                        id="numberAccount"
                        name="numberAccount"
                        className="form-control"
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            digitAccountBank: ev.target.value
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col xl="3">
                    <div className="form-group">
                      <label htmlFor="typeAccount">Tipo de conta</label>
                      <select
                        id="typeAccount"
                        name="typeAccount"
                        className="form-control"
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            typeAccount: ev.target.value
                          });
                        }}
                      >
                        <option value="">SELECIONE</option>
                        <option value="1">CONTA CORRENTE</option>
                        <option value="2">POUPANÇA</option>
                      </select>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button color="success" onClick={handleDigitation}>
                  Digitar proposta
                </Button>
              </CardFooter>
            </Card>
          </>
        )}
      </Container>
    </>
  );
};

export default DigitationPersonalCreditView;
