import { useState } from 'react';
import Header from 'components/Headers/Header';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { listBanksActiveService } from 'services/AccessBank/AccessBankService';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { updateOrderBankService } from 'services/AccessBank/AccessBankService';
import Swal from 'sweetalert2';

const AccessBankOrderView = () => {
  const history = useHistory();
  const [items, setItems] = useState([]);
  const [draggedItem, setDraggedItem] = useState(null);

  const handleDragStart = (e, item) => {
    e.dataTransfer.setData('text/plain', parseInt(item.order));
    setDraggedItem(item);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, dropTargetItem) => {
    e.preventDefault();
    const draggedItemId = Number(e.dataTransfer.getData('text/plain'), 10);

    if (draggedItemId !== Number(dropTargetItem.order)) {
      const updatedItems = items.map((item) => {
        if (Number(item.order) === draggedItemId) {
          return { ...item, order: Number(dropTargetItem.order) };
        }
        if (Number(item.order) === parseInt(dropTargetItem.order)) {
          return { ...item, order: Number(draggedItem.order) };
        }
        return item;
      });

      setItems(
        [...updatedItems].sort((a, b) => Number(a.order) - Number(b.order))
      );
      setDraggedItem(null);
    }
  };

  const sortedItems = [...items].sort(
    (a, b) => Number(a.order) - Number(b.order)
  );

  const handleList = () => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    listBanksActiveService()
      .then(({ data }) => {
        Swal.close();
        setItems(data?.resultSet ?? []);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleUpdate = () => {
    Swal.fire({
      title: 'Atualizando',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    updateOrderBankService(items)
      .then(() => {
        Swal.close();
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <>
      <Header showCards={false} />
      <Container className="mt--9" fluid>
        <Card>
          <CardHeader>
            <h1>
              <i className="fas fa-sort" /> Ordenar Bancos
            </h1>
          </CardHeader>
          <CardBody>
            <Alert color="secondary">
              <Row>
                <Col md="1" className="flex d-flex align-items-center">
                  <i className="fas fa-info fa-2x" />
                </Col>
                <Col>
                  <p className="m-0">
                    Para organizar as suas finanças, você pode simplesmente
                    arrastar e soltar as opções na ordem que preferir. É como
                    mover peças de um quebra-cabeça para colocá-las na posição
                    que faça mais sentido para você. Isso facilita visualizar e
                    acessar suas informações financeiras da maneira que mais lhe
                    convier.
                  </p>
                </Col>
              </Row>
            </Alert>
            {sortedItems.length <= 0 && (
              <Alert color="secondary">
                Não foram identificadas financeiras ativas no momento
              </Alert>
            )}
            {sortedItems.length > 0 && (
              <Button color="success" onClick={handleUpdate}>
                <i className="fas fa-save" /> SALVAR ALTERAÇÃO
              </Button>
            )}
            <div onDragOver={handleDragOver}>
              {sortedItems.length > 0 &&
                items.map((item, key) => (
                  <div
                    key={key}
                    draggable
                    onDragStart={(e) => handleDragStart(e, item)}
                    onDragOver={() => handleDragOver}
                    onDrop={(e) => handleDrop(e, item)}
                    style={{
                      backgroundColor:
                        draggedItem === item ? '#f0f0f0' : 'white',
                      cursor: 'pointer',
                    }}
                    className="pt-2 pb-2"
                  >
                    <Card className="shadow h-100">
                      <CardBody>
                        <Row>
                          <Col
                            md="1"
                            className="flex d-flex align-items-center"
                          >
                            <i className="fas fa-arrows-alt-v fa-2x text-primary" />
                          </Col>
                          <Col>
                            <Row className='d-flex flex-md-row flex-column'>
                              <Col>
                                <p className="m-0">Ordem</p>
                                <p className="font-22 font-weight-bold m-0">
                                  {item.order}
                                </p>
                              </Col>
                              <Col>
                                <p className=" m-0">Financeira</p>
                                <p className="font-22 font-weight-bold m-0">
                                  {item.name}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            className="text-right flex d-flex align-items-center"
                            md="4"
                          >
                            <Button
                              color="secondary"
                              className="w-100"
                              onClick={() => {
                                history.push(
                                  `/admin/access-bank-configure/${item.febraban}`
                                );
                              }}
                            >
                              <i className="fas fa-cog" /> Configurar
                            </Button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </div>
                ))}
            </div>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default AccessBankOrderView;
