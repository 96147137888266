import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Chart from 'chart.js';
import Swal from 'sweetalert2';
import { Button, Input, Label } from 'reactstrap';
import { format } from 'date-fns';

import {
  ListSimulation,
  ListSimulationCsv,
} from 'services/Digitation/SimulationService';
import { updateStep } from 'services/Promoter/PromoterService';

import Header from 'components/Headers/Header.js';
import FilterSimulationDashboard from './component/filterSimulation';
import SimulationInfo from './component/simulationInfo';
import ModalStep1 from './component/modalStep1';
import ModalStep2 from './component/modalStep2';
import ModalStep4 from './component/modalStep4';
import ModalStep3 from '../AccessBank/component/register';
import DigitationFGTS from './component/DigitationFGTS';
import { chartOptions, parseOptions } from 'variables/charts.js';
import {
  reSendLinkFormalizationMercantil,
  cancelProposalICredService,
  reSendProposalICredService,
  showLinkFormalize,
} from 'services/Digitation/DigitationService';
import { phoneMask } from 'utils/mask';
import Note from './component/note';
import FilterDefault from 'components/Form/FilterDefault';
import CardSimulation from 'components/Cards/CardSimulation';
import { ProposalAct } from 'services/Digitation/SimulationService';
import FormScheduleComponent from 'views/Schedule/Component/FormScheduleComponent';
import { IndexClientProposalService } from 'services/Register/ClientService';
import ModalTagComponent from 'components/ModalTag/ModalTagComponent';
import SmsSendMessageComponent from 'views/Register/Sms/Component/SmsSendMessgeComponent';
import ResendPaymentComponent from 'views/FGTS/components/ResendPaymentComponent';
import { scheduleExcel } from 'services/Report/Schedule';
import ListSimulationDashboardComponent from './component/listSimulationDashboardComponent';
import CardSimulationDashboardComponent from './component/cardSimulationDashboardComponent';
import SimulationFGTSQueueComponent from 'views/FGTS/components/SimulationFGTSQueueComponent';

import './style/style.scss';

const Index = () => {
  const history = useHistory();
  const [tableDetails, setTableDetails] = useState(null);
  const [listData, setListData] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);
  const [modalInfoData, setModalInfoData] = useState(false);
  const [modalStep1, setModalStep1] = useState(false);
  const [modalStep2, setModalStep2] = useState(false);
  const [modalStep3, setModalStep3] = useState(false);
  const [modalStep4, setModalStep4] = useState(false);
  const [modalNote, setModalNote] = useState(false);
  const [dataClient, setDataClient] = useState([]);
  const [dataSimule, setDataSimule] = useState({});
  const [proposalsClient, setProposalsClient] = useState([]);
  const [showClientProposals, setShowClientProposals] = useState(null);

  const [toggleScheduleComponent, setToggleScheduleComponent] = useState(false);
  const [scheduleDocumentClient, setScheduleDocumentClient] = useState({
    name: '',
    document: '',
  });

  const [modalDigitationFGTS, setModalDigitationFGTS] = useState(false);
  const [dateStart, setDateStart] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [dateEnd, setDateEnd] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [document, setDocument] = useState('');
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);
  const [statusBank, setStatusBank] = useState(null);
  const [numberProposal, setNumberProposal] = useState(null);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(0);
  const [toggleShowList, setToggleShowList] = useState(0);
  const [openModalSimulationQueue, setOpenModalSimulationQueue] =
    useState(false);

  const [resendPayment, setResendPayment] = useState({
    isOpen: false,
    simulationid: null,
    proposalId: null,
    febrabanId: null,
  });

  const [filter, setFilter] = useState({});

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const handleTableDetails = (index) => {
    if (tableDetails === index) return setTableDetails(null);

    setTableDetails(index);
  };

  const openModal = async () => {
    await setModal(!modal);
  };

  const openModalNote = async (data) => {
    setDataClient(data);
    await setModalNote(!modalNote);
  };

  const openModalInfo = async (data) => {
    await setModalInfoData(data);
    await setModalInfo(!modalInfo);
  };

  const openModalDigitationFGTS = () => {
    setModalDigitationFGTS(!modalDigitationFGTS);
  };

  const openModalStep1 = () => {
    setModalStep1(!modalStep1);
  };

  const openModalStep2 = () => {
    setModalStep2(!modalStep2);
  };

  const openModalStep3 = () => {
    setModalStep3(!modalStep3);
  };

  const openModalStep4 = () => {
    setModalStep4(!modalStep4);
  };

  const toggleModalSimulationQueueOpen = () => {
    setOpenModalSimulationQueue(true);
  };

  const toggleModalSimulationQueueClose = () => {
    setOpenModalSimulationQueue(false);
  };

  const handleTakeAct = async (simulationId, data) => {
    Swal.fire({
      icon: 'question',
      title: 'Deseja iniciar este atendimento?',
      text: 'ATENÇÃO! Ao iniciar o atendimento nesta proposta, será marcada uma flag com seu nome na simulação, para que os demais operadores saibam quem está atuando.',
      showDenyButton: true,
      confirmButtonText: 'Sim, continuar',
      denyButtonText: 'Não, sair',
    }).then(async (confirm) => {
      if (!confirm.isConfirmed) {
        return;
      }

      const result = await ProposalAct(simulationId);

      if (!result?.data?.status && result?.data?.name) {
        Swal.fire({
          icon: 'warning',
          title: 'Ops!',
          text: `O atendente ${result?.data?.name} já está atuando nesta simulação!`,
          confirmButtonText: 'Atender mesmo assim',
          showDenyButton: true,
          denyButtonText: 'Ok, sair',
        }).then((confirm) => {
          if (!confirm.isConfirmed) {
            return;
          }
          setDataSimule(data);
          openModalDigitationFGTS();
        });
      } else {
        setDataSimule(data);
        openModalDigitationFGTS();
      }
    });
  };

  const handleList = async (pageSelected = 0) => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde.',
      // message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    setListData([]);
    if (typeof pageSelected == 'number') {
      setPage(pageSelected);
    } else {
      pageSelected = 0;
    }

    await ListSimulation({
      dateStart: filter.dateStart,
      dateEnd: filter.dateEnd,
      document: filter.cpf,
      status: filter.statusIzidev,
      page: pageSelected,
      error: filter.error,
      numberProposal: filter.numberProposal,
      statusBank: filter.statusBankText,
      statusBankId: filter.statusProposal,
      indicationId: filter.indicationId,
      bank: filter.bank,
      userId: filter.userId,
      hasBalance: filter.hasBalance,
      typeDate: filter.typeDate,
      whatsappNumber: filter.whatsappNumber,
      tag: filter.tag,
    })
      .then(async (res) => {
        setListData(res.data.resultSet);
        if (res.data.resultSet.length > 0) {
          setPages(res.data.pagination.pages);
        }
        Swal.close();
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const exportExcell = async () => {
    Swal.fire({
      title: 'Carregando',
      message: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await ListSimulationCsv({
      dateStart: filter.dateStart,
      dateEnd: filter.dateEnd,
      document: filter.cpf,
      status: filter.statusIzidev,
      error: filter.error,
      numberProposal: filter.numberProposal,
      statusBank: filter.statusBankText,
      statusBankId: filter.statusProposal,
      indicationId: filter.indicationId,
      bank: filter.bank,
      userId: filter.userId,
      hasBalance: filter.hasBalance,
      typeDate: filter.typeDate,
      whatsappNumber: filter.whatsappNumber,
      tag: filter.tag,
    })
      .then(async (res) => {
        Swal.fire({
          icon: 'info',
          title: 'Sucesso',
          text: res.data.message,
        }).then((result) => {
          if (!result.isConfirmed) return;
          history.push('/admin/report-schedule');
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleSteps = async (step, save = true) => {
    if (step == 1) openModalStep1();
    else if (step == 2) openModalStep2();
    else if (step == 3) openModalStep3();
    else if (step == 4) openModalStep4();

    if (save) {
      localStorage.setItem('FIRST_LOGIN_STEP', step);
      updateStep(step);
    }
  };

  const reSendLinkMercantil = async (simulationId) => {
    Swal.fire({
      title: 'ReEnviando, Por favor aguarde...',
      message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    reSendLinkFormalizationMercantil(simulationId)
      .then((res) => {
        const link = res.data.link;

        Swal.fire({
          icon: 'info',
          title: 'Sucesso',
          html: `<h3>Link ReEnviado com sucesso!</h3>
          <input class="form-control form-control" type="text" value="${link}" />`,
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const cancelProposalICred = async (simulationId) => {
    Swal.fire({
      title: 'Informar motivo cancelamento',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
        placeholder: 'Motivo do cancelamento',
      },
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      showLoaderOnConfirm: true,
      preConfirm: (value) => {
        return cancelProposalICredService(simulationId, value)
          .then((res) => {
            return res.data;
          })
          .catch((error) => {
            const message = !error.response
              ? 'Ocorreu um erro inesperado'
              : error.response.data.message;
            Swal.showValidationMessage(`Erro: ${message}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Proposta Cancelada com sucesso!',
        });
      }
    });
  };

  const getLinkFormalize = async (numberProposal, febrabanId) => {
    Swal.fire({
      title: 'Buscando, Por favor aguarde...',
      message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    showLinkFormalize(numberProposal, febrabanId)
      .then((res) => {
        const link = res.data.resultSet;
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          html: `<input class="form-control form-control" type="text" value="${link}" />`,
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const reSendProposalICred = async (
    simulationId,
    phone = '',
    numberProposal = null
  ) => {
    Swal.fire({
      title: 'Informe o número',
      text: 'Por favor informe o número de celular com o DDD que deseja realizar o envio',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
        placeholder: '(00) 00000-0000',
        id: 'phone',
        value: phone,
      },
      inputValue: phoneMask(phone),
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      showLoaderOnConfirm: true,
      preConfirm: (value) => {
        return reSendProposalICredService(simulationId, value, numberProposal)
          .then((res) => {
            return res.data;
          })
          .catch((error) => {
            const message = !error.response
              ? 'Ocorreu um erro inesperado'
              : error.response.data.message;
            Swal.showValidationMessage(`Erro: ${message}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Link reenviado por SMS!',
        });
      }
    });
  };

  const handleShowClientProposals = (index) => {
    if (showClientProposals === index) return setShowClientProposals(null);

    setShowClientProposals(index);
  };

  const handleListProposalClient = async (clientId, index) => {
    Swal.fire({
      title: 'Carregando...',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    await IndexClientProposalService(clientId)
      .then(({ data }) => {
        Swal.close();
        setProposalsClient(data.resultSet);
        handleShowClientProposals(index);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const [tagDataModal, setTagDataModal] = useState({
    isOpen: false,
    clientId: null,
  });

  const [sendSms, setSendSms] = useState({
    number: null,
    message: null,
    openModal: false,
  });

  useEffect(() => {
    setToggleShowList(
      JSON.parse(localStorage.getItem('IZIDEV_LIST_VIEW_CARD'))
    );
    console.log(JSON.parse(localStorage.getItem('IZIDEV_LIST_VIEW_CARD')));
    handleList();
    const step = localStorage.getItem('FIRST_LOGIN_STEP');
    if (step != 'null') handleSteps(step, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header titlePage="PRINCIPAL - SIMULAÇÕES FGTS" />
      <section className="mt--9 container-fluid">
        <CardSimulation serviceId="2" />
        <FilterDefault
          title="Filtro - Simulações FGTS"
          data={filter}
          setData={setFilter}
          handleSubmit={handleList}
          handleSubmitCsv={exportExcell}
          config={{
            useFilterOperator: true,
            showBtnExcel: true,
            showFilterStatusText: true,
            showFilterHasBalance: true,
            showFilterTag: true,
            showBtnAditional: true,
            btnAditional: (
              <>
                <Button
                  color="success"
                  onClick={() => {
                    scheduleExcel(16, filter, history);
                  }}
                  className="margin-button"
                >
                  <i className="far fa-file-excel" /> Exportar Simulações
                  Analitico
                </Button>

                <div className="float-right">
                  <div className="d-flex align-items-center switch-div">
                    <Label className="switch">
                      <Input
                        id="status"
                        type="checkbox"
                        checked={toggleShowList <= 0}
                        size="sm"
                        onChange={(ev) => {
                          const value = ev.target.checked ? 0 : 1;
                          localStorage.setItem('IZIDEV_LIST_VIEW_CARD', value);
                          setToggleShowList(value);
                        }}
                      />
                      <span className="slider round" />
                    </Label>
                    <span className="ml-2">Visualizar em card(s)</span>
                  </div>
                </div>
              </>
            ),
          }}
        />
        <ListSimulationDashboardComponent
          toggleShowList={toggleShowList}
          listData={listData}
          setDataSimule={setDataSimule}
          openModalDigitationFGTS={openModalDigitationFGTS}
          pages={pages}
          handleList={handleList}
          setPage={setPage}
          page={page}
          reSendLinkMercantil={reSendLinkMercantil}
          cancelProposalICred={cancelProposalICred}
          reSendProposalICred={reSendProposalICred}
          getLinkFormalize={getLinkFormalize}
          openModalNote={openModalNote}
          openModalSimulationQueue={toggleModalSimulationQueueOpen}
          handleTakeAct={handleTakeAct}
          setToggleScheduleComponent={setToggleScheduleComponent}
          toggleScheduleComponent={toggleScheduleComponent}
          setScheduleDocumentClient={setScheduleDocumentClient}
          openModalInfo={openModalInfo}
          handleListProposalClient={handleListProposalClient}
          setTagDataModal={setTagDataModal}
          setResendPayment={setResendPayment}
          resendPayment={resendPayment}
          setSendSms={setSendSms}
          sendSms={sendSms}
          tableDetails={tableDetails}
          handleTableDetails={handleTableDetails}
          showClientProposals={showClientProposals}
          proposalsClient={proposalsClient}
        />
        <CardSimulationDashboardComponent
          toggleShowList={toggleShowList}
          listData={listData}
          setDataSimule={setDataSimule}
          openModalDigitationFGTS={openModalDigitationFGTS}
          pages={pages}
          handleList={handleList}
          setPage={setPage}
          page={page}
          reSendLinkMercantil={reSendLinkMercantil}
          cancelProposalICred={cancelProposalICred}
          reSendProposalICred={reSendProposalICred}
          getLinkFormalize={getLinkFormalize}
          openModalNote={openModalNote}
          openModalSimulationQueue={toggleModalSimulationQueueOpen}
          handleTakeAct={handleTakeAct}
          setToggleScheduleComponent={setToggleScheduleComponent}
          toggleScheduleComponent={toggleScheduleComponent}
          setScheduleDocumentClient={setScheduleDocumentClient}
          openModalInfo={openModalInfo}
          handleListProposalClient={handleListProposalClient}
          setTagDataModal={setTagDataModal}
          setResendPayment={setResendPayment}
          resendPayment={resendPayment}
          setSendSms={setSendSms}
          sendSms={sendSms}
          tableDetails={tableDetails}
          handleTableDetails={handleTableDetails}
          showClientProposals={showClientProposals}
          proposalsClient={proposalsClient}
        />
      </section>

      <FilterSimulationDashboard
        isModal={modal}
        state={setModal}
        setDateStart={setDateStart}
        setDateEnd={setDateEnd}
        setDocument={setDocument}
        setStatus={setStatus}
        setPages={setPages}
        setError={setError}
        setNumberProposal={setNumberProposal}
        setStatusBank={setStatusBank}
        handleList={handleList}
      />
      <SimulationInfo
        isModal={modalInfo}
        state={setModalInfo}
        data={modalInfoData}
      />
      <ModalStep1
        isModal={modalStep1}
        state={setModalStep1}
        handleSteps={handleSteps}
      />
      <ModalStep2
        isModal={modalStep2}
        state={setModalStep2}
        handleSteps={handleSteps}
      />
      <ModalStep3
        isModal={modalStep3}
        state={setModalStep3}
        handleSteps={handleSteps}
      />
      <ModalStep4
        isModal={modalStep4}
        state={setModalStep4}
        handleSteps={handleSteps}
      />
      <DigitationFGTS
        isModal={modalDigitationFGTS}
        state={setModalDigitationFGTS}
        dataSimule={dataSimule}
      />
      <Note isModal={modalNote} state={setModalNote} data={dataClient} />

      <FormScheduleComponent
        toggleModal={toggleScheduleComponent}
        setToggleModal={setToggleScheduleComponent}
        name={scheduleDocumentClient.name}
        document={scheduleDocumentClient.document}
      />

      <ModalTagComponent
        tagDataModal={tagDataModal}
        setTagDataModal={setTagDataModal}
        serviceId={2}
      />

      <SmsSendMessageComponent
        numberDefault={sendSms.number}
        messageDefault={sendSms.message}
        isOpen={sendSms.openModal}
        setIsOpen={(e) => {
          setSendSms({ ...sendSms, openModal: e });
        }}
      />

      <ResendPaymentComponent
        isOpen={resendPayment.isOpen}
        setIsOpen={(e) => {
          setResendPayment({
            ...resendPayment,
            isOpen: e,
          });
        }}
        simulationId={resendPayment.simulationid}
        proposalId={resendPayment.proposalId}
        febrabanId={resendPayment.febrabanId}
      />

      <SimulationFGTSQueueComponent
        isOpen={openModalSimulationQueue}
        hideModal={toggleModalSimulationQueueClose}
      />
    </>
  );
};

export default Index;
