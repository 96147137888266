import { useState, useEffect } from 'react';
import Header from 'components/Headers/Header';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
  Container,
  Alert,
  InputGroup,
  UncontrolledTooltip,
} from 'reactstrap';

import Select from 'react-select';
import { maskDate, maskCPF, moneyMask, cepMask } from 'utils/mask';
import Swal from 'sweetalert2';
import { BankSelectData } from 'views/AccessBank/service/bankSelectData';
import {
  simuleConsignedService,
  searchMarginService,
} from 'services/Consigned/ConsignedService';
import CardSomulationConsignedCompoenent from './Component/CardSimulationConsignedComponent';
import SelectedSimulationView from './Component/SelectedSimulationView';
import {
  PAN_SERVICES,
  SAFRA_SERVICES,
  FACTA_SERVICES,
} from 'utils/EnumServicesINSS';
import { simulationConsignedValidator } from './Component/ConsignedValidator';
import ModalMarginComponent from './Component/ModalMarginComponent';
import { Label } from 'views/PersonalCredit/Component/styles';

const banksOptions = [
  { value: '626', label: 'C6 BANK' },
  { value: '623', label: 'BANCO PAN' },
  { value: '074', label: 'BANCO SAFRA' },
  { value: '149', label: 'FACTA' },
];

const installmentOptions = [
  { value: 84, label: '84 parcelas' },
  { value: 72, label: '72 parcelas' },
  { value: 60, label: '60 parcelas' },
  { value: 48, label: '48 parcelas' },
  { value: 36, label: '36 parcelas' },
];

const insuranceOptions = [
  { value: true, label: 'Sim' },
  { value: false, label: 'Não' },
];

const NewProposalView = () => {
  const [simulationList, setSimulationList] = useState([]);
  const [financialSelected, setFinancialSelected] = useState(null);
  const [simulationSelected, setSimulationSelected] = useState({});
  const [hideSimulation, setHideSimulation] = useState(false);
  const [serviceBank, setServiceBank] = useState([]);
  const [valueSelectedService, setValueSelectedService] = useState(null);
  const [errorMessages, setErrorMessages] = useState([]);

  const [resultMargin, setResultMargin] = useState({});
  const [marginFilter, setMarginFilter] = useState({
    cpf: '',
    openModal: false,
  });

  const [contract, setContract] = useState({
    cpf: '',
    birthDate: '',
    febrabanId: null,
    registry: '',
    value: '',
    installment: 84,
    useSafe: false,
  });

  const handleListServiceBank = (febrabanId) => {
    setValueSelectedService(null);
    setServiceBank([]);
    setContract({
      ...contract,
      serviceBank: null,
      febrabanId: febrabanId,
    });
    if (febrabanId === '074') {
      setServiceBank(
        SAFRA_SERVICES.map((register) => {
          return { id: register.idConvenio, name: register.nome };
        })
      );
    }

    if (febrabanId === '623') {
      setServiceBank(
        PAN_SERVICES.map((register) => {
          return { id: register.codigo, name: register.nome };
        })
      );
    }

    if (febrabanId === '149') {
      setServiceBank(
        FACTA_SERVICES.map((register) => {
          return { id: register.id, name: register.name };
        })
      );
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContract({
      ...contract,
      [name]: value,
    });
  };

  const handleSelectChange = (selectedOption, name) => {
    setContract({
      ...contract,
      [name]: selectedOption.value,
    });
  };

  const handleSimulation = async () => {
    const missingFields = simulationConsignedValidator(contract, serviceBank);

    if (missingFields.length > 0) {
      const messages = missingFields.map((field) => `- ${field}`).join('<br>');
      setErrorMessages(messages);
      return;
    }

    Swal.fire({
      title: 'Realizando simulação',
      text: 'Por favor, aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const result = await simuleConsignedService(contract);

    if (!result) {
      return;
    }

    const { resultSet } = result;
    setSimulationList(resultSet);
    setFinancialSelected(contract.febrabanId);
    Swal.close();
  };

  const handleSelectSimulation = (simulation) => {
    setSimulationSelected(simulation);
    setHideSimulation(true);
  };

  const handleSearchMargin = async () => {
    if (!marginFilter?.cpf) {
      Swal.fire({
        icon: 'error',
        title: 'CPF obrigatório',
        text: 'Por favor, informe o CPF antes de continuar.',
        confirmButtonText: 'OK',
      });
      return;
    }

    setContract({
      cpf: marginFilter?.cpf,
      birthDate: '',
      febrabanId: contract.febrabanId,
      registry: '',
      value: '',
      installment: 84,
      useSafe: false,
    });

    Swal.fire({
      title: 'Carregando',
      text: 'Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const cpf = marginFilter.cpf.replace(/[^0-9]/g, '');

    const result = await searchMarginService(cpf);

    if (!result) {
      return;
    }

    const { resultSet } = result;
    setResultMargin(resultSet);
    setMarginFilter({ ...marginFilter, openModal: true });
    Swal.close();
  };

  useEffect(() => {
    const missingFields = simulationConsignedValidator(contract, serviceBank);

    if (missingFields.length === 0) {
      setErrorMessages([]);
    }
  }, [contract]);

  return (
    <>
      <Header titlePage="Simulador Consignado" />
      <Container className="mt--9" fluid>
        {!hideSimulation && (
          <>
            <Alert color="primary">
              <p className="m-0">
                <i className="fas fa-info-circle" /> Nesta página você pode
                digitar os produtos do Consignado nos bancos{' '}
                <strong>PAN</strong>, <strong>SAFRA</strong>,{' '}
                <strong>FACTA</strong> e <strong>C6</strong>, a integração é
                feita por API.
              </p>
            </Alert>
            <Card className="shadow">
              <CardHeader>
                <h1>
                  <i className="far fa-sticky-note" /> Consignado - Realizar
                  Simulação
                </h1>
              </CardHeader>
              <CardBody>
                <Row className='px-2'>
                  <Col lg="3" md="4" className='px-1'>
                    <FormGroup>
                      <Label>
                        CPF <span className="text-danger">*</span>
                      </Label>

                      <InputGroup>
                        <Input
                          name="cpf"
                          value={contract.cpf}
                          placeholder="xxx.xxx.xxx-xx"
                          onChange={(ev) => {
                            handleInputChange(ev);
                            setContract({
                              ...contract,
                              cpf: maskCPF(ev.target.value),
                            });
                            setMarginFilter({
                              ...marginFilter,
                              cpf: maskCPF(ev.target.value),
                            });
                          }}
                        />
                        <button
                          id="btnSearchMargin"
                          className="bg-primary text-white border-0 px-3"
                          style={{
                            borderTopRightRadius: '5px',
                            borderBottomRightRadius: '5px',
                          }}
                          onClick={handleSearchMargin}
                        >
                          <i className="fa fa-solid fa-search" />
                        </button>
                        <UncontrolledTooltip
                          placement="top"
                          target="btnSearchMargin"
                        >
                          Consultar margem consignável
                        </UncontrolledTooltip>
                      </InputGroup>
                    </FormGroup>
                  </Col>

                  <Col lg="3" md="4" className='px-1'>
                    <FormGroup>
                      <Label>
                        Data de Nascimento{' '}
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="birthDate"
                        value={contract.birthDate}
                        placeholder="--/--/----"
                        onChange={(ev) => {
                          handleInputChange(ev);
                          setContract({
                            ...contract,
                            birthDate: maskDate(ev.target.value),
                          });
                        }}
                        maxLength="10"
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="3" md="4" className='px-1'>
                    <FormGroup>
                      <Label>
                        Banco <span className="text-danger">*</span>
                      </Label>
                      <Select
                        options={banksOptions}
                        onChange={(ev) => {
                          handleListServiceBank(ev.value);
                        }}
                        placeholder="Selecione..."
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="3" md="4" className='px-1'>
                    <FormGroup>
                      <Label>
                        Matrícula <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="registry"
                        value={contract.registry}
                        placeholder="Digite a matrícula"
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                  {contract.febrabanId === '074' && (
                    <Col lg="3" md="4" className='px-1'>
                      <FormGroup>
                        <Label>Aumento de Margem</Label>
                        <select
                          className="form-control"
                          onChange={(ev) => {
                            setContract({
                              ...contract,
                              marginIncrease: ev.target.value,
                            });
                          }}
                        >
                          <option value="0">Não</option>
                          <option value="1">Sim</option>
                        </select>
                      </FormGroup>
                    </Col>
                  )}

                  <Col lg="3" md="4" className='px-1'>
                    <FormGroup>
                      <Label>
                        Valor da Parcela <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        name="value"
                        value={contract.value}
                        onChange={(ev) => {
                          setContract({
                            ...contract,
                            value: moneyMask(ev.target.value),
                          });
                        }}
                        placeholder="R$"
                      />
                    </FormGroup>
                  </Col>

                  {serviceBank.length > 0 && (
                    <Col lg="3" md="4" className='px-1'>
                      <FormGroup>
                        <Label>
                          Convênio <span className="text-danger">*</span>
                        </Label>
                        <Select
                          options={serviceBank.map((serviceb) => {
                            return { label: serviceb.name, value: serviceb.id };
                          })}
                          value={valueSelectedService}
                          onChange={(ev) => {
                            handleSelectChange(ev, 'serviceBank');
                            setValueSelectedService(ev);
                          }}
                          placeholder="Selecione..."
                        />
                      </FormGroup>
                    </Col>
                  )}

                  <Col lg="3" md="4" className='px-1'>
                    <FormGroup>
                      <Label>
                        Prazo <span className="text-danger">*</span>
                      </Label>
                      <Select
                        options={installmentOptions}
                        defaultValue={{ value: 84, label: '84 parcelas' }}
                        onChange={(ev) => {
                          handleSelectChange(ev, 'installment');
                        }}
                        placeholder="Selecione..."
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="3" md="4" className='px-1'>
                    <FormGroup>
                      <Label>
                        Seguro <span className="text-danger">*</span>
                      </Label>
                      <Select
                        options={insuranceOptions}
                        defaultValue={{ value: false, label: 'Não' }}
                        onChange={(ev) => {
                          handleSelectChange(ev, 'useSafe');
                        }}
                        placeholder="Selecione..."
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button color="success" onClick={handleSimulation}>
                  <i className="fas fa-arrow-right mr-1" /> Realizar simulação
                </Button>
              </CardFooter>
            </Card>
            <Alert className="mt-2" color="secondary">
              <p className="m-0">
                - Campos com <strong className="text-danger">*</strong> são
                obrigatórios
              </p>
            </Alert>

            {errorMessages.length > 0 && (
              <Alert color="warning">
                <h2 className="m-0 text-white">
                  <i className="fas fa-times" /> Erro
                </h2>
                <div dangerouslySetInnerHTML={{ __html: errorMessages }} />
              </Alert>
            )}

            {simulationList.length > 0 && (
              <Card className="mt-4 shadow">
                <CardHeader>
                  <h2>
                    <i className="far fa-sticky-note" /> Resultado da simulação
                  </h2>
                </CardHeader>
                <CardBody className="bg-secondary">
                  <Card>
                    <CardHeader>
                      <img
                        src={BankSelectData(financialSelected).img}
                        alt={financialSelected}
                        height="80px"
                      />
                    </CardHeader>
                    <CardBody>
                      <Row className="mt-4">
                        {simulationList.map((register, index) => (
                          <Col
                            key={index}
                            sm="12"
                            md="6"
                            lg="4"
                            className="mb-3 d-flex align-items-stretch"
                          >
                            <CardSomulationConsignedCompoenent
                              simulation={register}
                              selectSimulation={handleSelectSimulation}
                            />
                          </Col>
                        ))}
                      </Row>
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
            )}
          </>
        )}

        {simulationSelected && hideSimulation === true && (
          <SelectedSimulationView
            simulationSelected={simulationSelected}
            financialSelected={financialSelected}
            hideSimulation={hideSimulation}
            contract={contract}
            setContract={setContract}
          />
        )}

        <ModalMarginComponent
          openMarginModal={marginFilter.openModal}
          result={resultMargin}
          setIsOpen={(modal) => {
            setMarginFilter({
              ...marginFilter,
              openModal: modal,
            });
          }}
          setMarginSelected={(result) => {
            setContract({
              ...contract,
              name: result?.name ?? null,
              birthDate: result?.birthDate ?? null,
              febrabanId: null,
              registry: result?.selected?.number ?? null,
              value: moneyMask(result?.selected?.loanAvailableRMC),
              installment: 84,
              useSafe: false,
              sex: result?.sex ?? '',
              motherName: result?.nameMother ?? '',
              cep: cepMask(result?.zipCode ?? ''),
              address: result?.street ?? '',
              numberAddress: '',
              district: result?.district ?? '',
              city: result?.city ?? '',
              state: result?.state ?? '',
              documentRg: result?.documentRG ?? '',
            });
          }}
        />
      </Container>
    </>
  );
};

export default NewProposalView;
