import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableData,
  Table,
} from 'components/Table';

import Header from 'components/Headers/Header';
import { MobileTitle, ContentWrapper } from 'views/Dashboard/styles';
import { CaretRight } from 'phosphor-react';

import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
} from 'reactstrap';

import Swal from 'sweetalert2';

import { maskDocument, phoneMask } from 'utils/mask';
import { listNotificationsImportedService } from 'services/Import/ImportProposalService';

const NotificationFgtsImportedView = () => {
  let { fileImportedId } = useParams();
  const [result, setResult] = useState([]);
  const [tableDetails, setTableDetails] = useState(null);
  const [showBtnMoreRegisters, setShowBtnMoreRegisters] = useState(false);
  const [filter, setFilter] = useState({
    pageActual: 0,
    limit: 15,
  });

  const handleList = async (isMore) => {
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let filterSearch = {
      ...filter,
      pageActual: isMore ? filter.pageActual + filter.limit : 0,
    };

    try {
      const response = await listNotificationsImportedService(
        fileImportedId,
        filterSearch
      );
      Swal.close();
      const newResultSet = response.data.resultSet.data;
      setFilter(filterSearch);

      if (isMore) {
        setResult((prevResult) => {
          const combinedResult = [...prevResult, ...newResultSet];
          const uniqueResult = combinedResult.filter(
            (item, index, self) =>
              index ===
              self.findIndex((t) => t.numberProposal === item.numberProposal)
          );

          return uniqueResult;
        });
      } else {
        setResult(newResultSet);
      }

      setShowBtnMoreRegisters(newResultSet.length === filter.limit);
    } catch (err) {
      const code = !err.response ? '00000' : err.response.data.code;
      const message = !err.response
        ? 'Ocorreu um erro inesperado'
        : err.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `[${code}] ${message}`,
      });
    }
  };

  const handleTableDetails = (index) => {
    if (tableDetails === index) return setTableDetails(null);

    setTableDetails(index);
  };

  const getStatusBadge = (status) => {
    switch (Number(status)) {
      case -1:
        return { color: 'danger', text: 'ERRO' };
      case -2:
        return { color: 'danger', text: 'NÃO FOI POSSÍVEL ENVIAR NOTIFICAÇÃO' };
      case 0:
        return { color: 'warning', text: 'AGUARDANDO' };
      case 1:
        return { color: 'info', text: 'PROCESSADO' };
      case 2:
        return { color: 'success', text: 'NOTIFICAÇÃO ENVIADA' };
      case 3:
        return { color: 'success', text: 'LINK ENVIADO' };
      default:
        return { color: 'secondary', text: 'STATUS DESCONHECIDO' };
    }
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <>
      <Header titlePage="Notificações FGTS - Registros importados" />
      <Container className="mt--9" fluid>
        {result.length > 0 ? (
          <Card className="mt-3">
            <CardHeader className="border-bottom-0 pb-0">
              Registros Importados
            </CardHeader>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>STATUS NOTIFICAÇÃO</TableHead>
                  <TableHead className="">CLIENTE</TableHead>
                  <TableHead className="d-none d-lg-table-cell">
                    VALOR LIBERADO
                  </TableHead>
                  <TableHead className="d-none d-lg-table-cell">
                    VALOR FINAL
                  </TableHead>
                  <TableHead className="d-xl-none" />
                  <TableHead className="d-lg-table-cell d-none" />
                </TableRow>
              </TableHeader>
              <TableBody>
                {result.map((register, key) => (
                  <>
                    <TableRow style={{ borderBottom: 0 }}>
                      {register?.error && (
                        <TableData colSpan={12}>
                          <Alert
                            color="secondary"
                            className="w-100 rounded p-2 mb-0 text-danger"
                          >
                            {register.error}
                          </Alert>
                        </TableData>
                      )}
                    </TableRow>

                    <TableRow style={{ borderTop: 0 }} key={key}>
                      <TableData>
                        {(() => {
                          const { color, text } = getStatusBadge(
                            register.proccess
                          );
                          return (
                            <Badge
                              color={color}
                              style={{
                                fontSize: '0.8rem',
                                padding: '0.5rem',
                              }}
                            >
                              {text}
                            </Badge>
                          );
                        })()}
                      </TableData>
                      <TableData>
                        <p className="m-0">
                          NOME:{' '}
                          <strong className="font-weight-bold">
                            {register.name ? register.name : 'Não identificado'}
                          </strong>
                        </p>
                        <p className="m-0">
                          CPF:{' '}
                          <strong className="font-weight-bold">
                            {maskDocument(register.cpf)}
                          </strong>
                        </p>
                        <p className="m-0">
                          DATA NASCIMENTO:{' '}
                          <strong className="font-weight-bold">
                            {register.birthDate
                              ? register.birthDate
                              : 'Não identificado'}
                          </strong>
                        </p>

                        {register.whatsappNumber ? (
                          <p className="m-0">
                            NÚMERO WHATSAPP:{' '}
                            <a
                              className="font-weight-bold"
                              href={`https://wa.me/55${register.whatsappNumber.replace(
                                /[^0-9]/g,
                                ''
                              )}`}
                              target="_blank"
                            >
                              <i className="fab fa-whatsapp" />
                              {` `}
                              {phoneMask(register.whatsappNumber)}
                            </a>
                          </p>
                        ) : (
                          <p>
                            NÚMERO WHATSAPP:{' '}
                            <strong className="font-weight-bold">
                              Não Identificado
                            </strong>
                          </p>
                        )}
                      </TableData>

                      <TableData className="d-none d-lg-table-cell">
                        <strong
                          className="font-weight-bold"
                          style={{
                            fontSize: '1rem',
                          }}
                        >
                          {register.valueLiberty
                            ? `R$ ${register.valueLiberty}`
                            : 'Não identificado'}
                        </strong>
                      </TableData>
                      <TableData className="d-none d-lg-table-cell">
                        <strong
                          className="font-weight-bold"
                          style={{
                            fontSize: '1rem',
                          }}
                        >
                          {register.valueFinally
                            ? `R$ ${register.valueFinally}`
                            : 'Não identificado'}
                        </strong>
                      </TableData>
                      <TableData className="d-none d-xl-table-cell">
                        <a
                          id={`detailsTooltip-${register.simulationId}`}
                          href={`/admin/simulation-details/${
                            register.simulationId ?? 0
                          }`}
                          target="_blank"
                        >
                          <Button>
                            <i className="fas fa-tag mr-1" />
                            Detalhes da simulação
                          </Button>
                        </a>
                      </TableData>
                      <TableData className="d-xl-none">
                        <CaretRight
                          active={tableDetails === key}
                          onClick={() => handleTableDetails(key)}
                        />
                      </TableData>
                    </TableRow>

                    {tableDetails === key && (
                      <TableRow className="d-xl-none">
                        <TableData colSpan={10}>
                          <ContentWrapper className="d-block d-lg-none">
                            <MobileTitle>VALOR LIBERADO</MobileTitle>
                            <strong className="font-weight-bold">
                              {register.valueLiberty
                                ? register.valueLiberty
                                : 'Não Identificado'}
                            </strong>
                          </ContentWrapper>

                          <ContentWrapper className="d-block d-lg-none">
                            <MobileTitle>VALOR FINAL</MobileTitle>
                            <strong className="font-weight-bold">
                              {register.valueFinally
                                ? register.valueFinally
                                : 'Não Identificado'}
                            </strong>
                          </ContentWrapper>

                          <ContentWrapper>
                            <a
                              id={`detailsTooltip-${register.simulationId}`}
                              href={`/admin/simulation-details/${
                                register.simulationId ?? 0
                              }`}
                              target="_blank"
                            >
                              <Button>
                                <i className="fas fa-tag mr-1" />
                                Detalhes da simulação
                              </Button>
                            </a>
                          </ContentWrapper>
                        </TableData>
                      </TableRow>
                    )}
                  </>
                ))}
              </TableBody>
              {showBtnMoreRegisters && (
                <tr>
                  <td colSpan={12} className="pt-3 px-3">
                    <Button
                      className="w-100"
                      onClick={() => {
                        setFilter({ ...filter });
                        handleList(true);
                      }}
                    >
                      Carregar mais...
                    </Button>
                  </td>
                </tr>
              )}
            </Table>
          </Card>
        ) : (
          <Card className="mt-3">
            <CardHeader className="">Registros Importados</CardHeader>
            <CardBody className="text-start">
              <p>Sem registros importados...</p>
            </CardBody>
          </Card>
        )}
      </Container>
    </>
  );
};

export default NotificationFgtsImportedView;
