import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Card, CardBody, Collapse } from 'reactstrap';
import { listAllDocuments } from 'services/Digitation/DigitationService';
import Swal from 'sweetalert2';

const ModalClientDocumentComponent = ({
   showModalDocuments,
   closeModal,
   register
}) => {
   const [listDocuments, setListDocuments] = useState([]);
   const [selectedDocument, setSelectedDocument] = useState(null);
   const [openCollapses, setOpenCollapses] = useState({});
   const [firstAccess, setFirstAccess] = useState(true);
   const [isImg, setIsImg] = useState(false);

   const groupedDocuments = listDocuments.reduce((acc, doc, index) => {
      const { typeTranslate } = doc;
      if (!acc[typeTranslate]) acc[typeTranslate] = [];
      acc[typeTranslate].push({ ...doc, index });
      return acc;
   }, {});

   const handleDocumentClick = (document, typeTranslate, index) => {
      setSelectedDocument({ ...document, typeTranslate, index });
   };

   const handleListDocuments = async (clientId) => {
      setListDocuments([]);
      Swal.fire({
         title: 'Carregando listagem',
         text: 'Por favor, aguarde.',
         allowOutsideClick: false,
         allowEscapeKey: false,
         didOpen: () => {
            Swal.showLoading();
         },
      });

      listAllDocuments(clientId)
         .then(({ data }) => {
            Swal.close();
            setListDocuments(data.resultSet);
         })
         .catch((err) => {
            const code = !err.response ? '00000' : err.response.data.code;
            const message = !err.response
               ? 'Ocorreu um erro inesperado'
               : err.response.data.message;
            Swal.fire({
               icon: 'error',
               title: 'Oops...',
               text: `[${code}] ${message}`,
            });
         });
   };

   const toggleCollapse = (type) => {
      setOpenCollapses((prevState) => ({
         ...prevState,
         [type]: !prevState[type],
      }));
   };

   useEffect(() => {
      setSelectedDocument(null);
      setListDocuments([]);

      if (!showModalDocuments || !register || register.clientId <= 0) return;
      handleListDocuments(register.clientId);

   }, [showModalDocuments, register]);

   useEffect(() => {
      if (Object.keys(groupedDocuments).length > 0) {
         if (firstAccess) {
            const firstTypeTranslate = Object.keys(groupedDocuments)[0];
            setOpenCollapses((prevState) => ({
               ...prevState,
               [firstTypeTranslate]: true,
            }));

            const firstDocument = groupedDocuments[firstTypeTranslate][0];
            setSelectedDocument({ ...firstDocument, typeTranslate: firstTypeTranslate, index: 0 });

            setFirstAccess(false);
         }
      }
   }, [groupedDocuments, firstAccess]);

   useEffect(() => {
      if (!showModalDocuments) {
         setOpenCollapses({});
         setSelectedDocument(null);
         setFirstAccess(true);
      }
   }, [showModalDocuments]);

   useEffect(() => {
      if (selectedDocument) {
         const { urlDocument } = selectedDocument;
         const isImage = /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(urlDocument);
         setIsImg(isImage);
      }
   }, [selectedDocument]);

   const renderDocument = () => {
      const { urlDocument } = selectedDocument;

      if (urlDocument.endsWith('.pdf')) {
         return (
            <iframe
               src={urlDocument}
               title="PDF Viewer"
               width="100%"
               height="300px"
               style={{ border: 'none' }}
            />
         );
      } else {
         return (
            <img
               src={urlDocument}
               alt={selectedDocument.typeTranslate}
               style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
         );
      }
   };

   return (
      <Modal isOpen={showModalDocuments} toggle={closeModal} backdrop={true} size="lg" className="modal-dialog modal-dialog-centered">
         <ModalHeader toggle={closeModal}><h2>Documentos do Cliente</h2></ModalHeader>
         <ModalBody className='pt-0'>
            {register && (
               <>
                  <Row className='px-3'>
                     <Card className="mb-4 w-100 shadow">
                        <CardBody>
                           <p><strong>Nome:</strong> {register.name}</p>
                           <p><strong>CPF:</strong> {register.documentCPF}</p>
                           <p><strong>Data de Nascimento:</strong> {register.birthDate}</p>
                           <p><strong>Contato:</strong> {register.whatsappNumber}</p>
                        </CardBody>
                     </Card>
                  </Row>
                  <Row>
                     <Col className='col-lg-5'>
                        {Object.keys(groupedDocuments).map((typeTranslate) => (
                           <Card className="mb-3 border-0 px-1" key={typeTranslate}>
                              <CardBody className='p-0'>
                                 <Button
                                    onClick={() => toggleCollapse(typeTranslate)}
                                    className="w-100 text-left d-flex align-items-center justify-content-between"
                                 >
                                    {typeTranslate}
                                    <i className={`ml-2 fas fa-chevron-${openCollapses[typeTranslate] ? 'up' : 'down'}`} />
                                 </Button>
                                 <Collapse isOpen={openCollapses[typeTranslate]}>
                                    <Card className="mt-2 d-flex flex-column align-items-center justify-content-center">
                                       <CardBody className='w-100 p-2'>
                                          {groupedDocuments[typeTranslate].map((doc, index) => (
                                             <Button
                                                key={`${typeTranslate}-${index}`}
                                                color="white"
                                                className="m-0 w-100 shadow-none text-left"
                                                onClick={() => handleDocumentClick(doc, typeTranslate, index)}
                                                active={selectedDocument?.typeTranslate === typeTranslate && selectedDocument?.index === index}
                                             >
                                                {`DOCUMENTO - ${index + 1}`}
                                             </Button>
                                          ))}
                                       </CardBody>
                                    </Card>
                                 </Collapse>
                              </CardBody>
                           </Card>
                        ))}
                     </Col>
                     <Col lg="7" className="d-flex align-items-start justify-content-center pt-2">
                        {selectedDocument ? (
                           <div className='w-100'>
                              <Card className="w-100 shadow">
                                 <CardBody className="d-flex align-items-center justify-content-center">
                                    {renderDocument()}
                                 </CardBody>
                              </Card>
                              {isImg && (
                                 <div className="w-100 d-flex justify-content-end mt-3">
                                    <a
                                       href={selectedDocument.urlDocument}
                                       target="_blank"
                                       className="btn btn-success btn-md"
                                       download
                                    >
                                       BAIXAR DOCUMENTO
                                    </a>
                                 </div>
                              )}
                           </div>
                        ) : (
                           <p>Cliente não possui documentos anexados</p>
                        )}
                     </Col>
                  </Row>
               </>
            )}
         </ModalBody>
         <ModalFooter>
            <Button color="secondary" onClick={closeModal}>
               FECHAR
            </Button>
         </ModalFooter>
      </Modal>
   );
};

export default ModalClientDocumentComponent;
