import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  InputGroup,
  InputGroupAddon,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import PaginationSelector from './PaginationSelector';
import { Buttons, Cliente, DataCadastro, Status, Valores } from '../tableData';
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableData,
} from 'components/Table';
import {
  CardHeader,
  Title,
  ButtonsWrapper,
  CaretRight,
  ContentWrapper,
  MobileTitle,
} from '../styles';
import { moneyMask } from 'utils/mask';
import { sendSimulationQueue } from 'services/SimulationQueue';
import Swal from 'sweetalert2';

const ListSimulationDashboardComponent = ({
  toggleShowList = 1,
  listData = [],
  setDataSimule = () => {},
  openModalDigitationFGTS = () => {},
  pages,
  handleList = () => {},
  setPage = () => {},
  page,
  reSendLinkMercantil = () => {},
  cancelProposalICred = () => {},
  reSendProposalICred = () => {},
  getLinkFormalize = () => {},
  openModalNote = () => {},
  handleTakeAct = () => {},
  setToggleScheduleComponent = () => {},
  toggleScheduleComponent = null,
  setScheduleDocumentClient = () => {},
  openModalInfo = () => {},
  handleListProposalClient = () => {},
  setTagDataModal = () => {},
  setResendPayment = () => {},
  resendPayment = null,
  setSendSms = () => {},
  sendSms = null,
  tableDetails = null,
  handleTableDetails = () => {},
  showClientProposals = null,
  proposalsClient = [],
  openModalSimulationQueue = () => {},
}) => {
  const history = useHistory();

  const handleSaveSimulationQueue = async (
    simulationId,
    bankId,
    tableId,
    cpf
  ) => {
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const data = {
      cpf: cpf,
      simulationId: simulationId,
      bankId: bankId,
      tableId: tableId || '',
    };

    await sendSimulationQueue(data)
      .then(async () => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Registro adicionado a Fila!',
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  if (toggleShowList <= 0) {
    return <></>;
  }

  return (
    <>
      <Row>
        <Col className="mt-4 mb-5 mb-xl-0">
          <Card className="shadow">
            <CardHeader>
              <Title>Simulações</Title>
              <ButtonsWrapper>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <Button
                      color="primary"
                      onClick={() => {
                        history.push('/admin/fgts/operation');
                      }}
                    >
                      <i className="far fa-file-alt" /> FGTS MULTI-TABELAS
                    </Button>
                  </InputGroupAddon>
                  <InputGroupAddon addonType="append">
                    <Button
                      className="btn btn-success btn-md btn-lg-md"
                      onClick={() => {
                        setDataSimule({});
                        openModalDigitationFGTS();
                      }}
                    >
                      <i className="far fa-file-alt" /> FGTS MULTI-BANCOS
                    </Button>
                  </InputGroupAddon>
                  <InputGroupAddon addonType="append">
                    <Button
                      className="btn btn-primary btn-md btn-lg-md"
                      onClick={() => {
                        openModalSimulationQueue();
                      }}
                    >
                      <i className="fa fa-solid fa-people-arrows"></i> FILA DE
                      SIMULAÇÕES <Badge color="success">NOVO</Badge>
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </ButtonsWrapper>
            </CardHeader>
            {listData.length > 0 ? (
              <>
                <PaginationSelector
                  pages={pages}
                  list={handleList}
                  setPage={setPage}
                  page={page}
                />
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Data Cadastro</TableHead>
                      <TableHead>Cliente</TableHead>
                      <TableHead className="d-none d-lg-table-cell">
                        Valores
                      </TableHead>
                      <TableHead className="d-none d-xl-table-cell">
                        Status
                      </TableHead>
                      <TableHead className="d-none d-xl-table-cell" />
                      <TableHead className="d-xl-none" />
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {listData.map((data, index) => (
                      <>
                        <TableRow style={{ borderBottom: 0 }}>
                          {data?.tags?.length > 0 && (
                            <TableData colSpan={5}>
                              <div className="bg-secondary rounded p-2">
                                {data.tags.map((tag, tagKey) => (
                                  <Badge
                                    key={tagKey}
                                    className={`${tag.color} m-1 font-12 shadow`}
                                  >
                                    {tag.title}
                                  </Badge>
                                ))}
                              </div>
                            </TableData>
                          )}
                        </TableRow>
                        <TableRow style={{ borderTop: 0 }} key={index}>
                          <TableData>
                            <DataCadastro data={data} />
                          </TableData>
                          <TableData>
                            <Cliente data={data} />
                          </TableData>
                          <TableData className="d-none d-lg-table-cell">
                            <Valores data={data} />
                          </TableData>
                          <TableData className="d-none d-xl-table-cell">
                            <Status data={data} />
                            {data.febrabanId == '389' &&
                            data.status === 'DIGITAÇÃO REALIZADA' ? (
                              <Buttons
                                title="ReEnviar SMS Mercantil"
                                className="btn btn-info btn-sm"
                                onClick={() => reSendLinkMercantil(data.id)}
                              />
                            ) : (
                              <></>
                            )}
                            {data.febrabanId == '329' &&
                            data.status === 'DIGITAÇÃO REALIZADA' ? (
                              <>
                                <div className="input-group">
                                  <div className="input-group-append">
                                    <Button
                                      title="Cancelar Proposta"
                                      className="btn btn-danger btn-sm mr-2"
                                      onClick={() =>
                                        cancelProposalICred(data.id)
                                      }
                                    >
                                      Cancelar Proposta
                                    </Button>
                                    <Button
                                      title="Enviar SMS"
                                      className="btn btn-info btn-sm ml-2"
                                      style={{ marginLeft: '5px !important' }}
                                      onClick={() =>
                                        reSendProposalICred(
                                          data.id,
                                          data.whatsappNumber,
                                          data.numberProposal
                                        )
                                      }
                                    >
                                      Enviar SMS
                                    </Button>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            {(data.febrabanId == '626' ||
                              data.febrabanId == '149' ||
                              data.febrabanId == '623' ||
                              data.febrabanId == '329-1' ||
                              data.febrabanId == '329-3' ||
                              data.febrabanId == '329-4' ||
                              data.febrabanId == '329-5' ||
                              data.febrabanId == '329-6' ||
                              data.febrabanId == '074' ||
                              data.febrabanId == '274' ||
                              data.febrabanId == '457' ||
                              data.febrabanId == '324' ||
                              data.febrabanId == '531') &&
                            data.status === 'DIGITAÇÃO REALIZADA' ? (
                              <>
                                <Button
                                  className="btn btn-sm btn-info"
                                  onClick={() => {
                                    getLinkFormalize(
                                      data.numberProposal,
                                      data.febrabanId
                                    );
                                  }}
                                >
                                  Link de Formalização
                                </Button>
                              </>
                            ) : (
                              <></>
                            )}
                            <Button
                              className="btn btn-secondary btn-sm"
                              onClick={() => {
                                openModalNote(data);
                              }}
                            >
                              Anotações
                            </Button>
                          </TableData>
                          <TableData className="d-none d-xl-table-cell">
                            <UncontrolledDropdown>
                              <DropdownToggle caret color="secondary">
                                Opções
                              </DropdownToggle>
                              <DropdownMenu dark>
                                <DropdownItem
                                  href={`/admin/simulation-details/${
                                    data.id ?? 0
                                  }`}
                                  target="_blank"
                                >
                                  <i className="fas fa-tag" /> Detalhes da
                                  Simulação
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    if (
                                      data.localeSimulation ===
                                      'INTERNO FGTS MULTITABELAS'
                                    ) {
                                      history.push(
                                        `/admin/fgts/operation?cpf=${data.cpf}&birthDate=${data.birthDate}&whatsapp=${data.whatsappNumber}&name=${data.name}`
                                      );
                                      return;
                                    }

                                    handleTakeAct(data.id, data);
                                  }}
                                >
                                  <i className="far fa-sticky-note" /> Simular
                                  Novamente
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    setToggleScheduleComponent(
                                      !toggleScheduleComponent
                                    );
                                    setScheduleDocumentClient({
                                      name: data.name,
                                      document: data.cpf,
                                    });
                                  }}
                                >
                                  <i className="far fa-clock" /> Realizar
                                  Agendamento
                                </DropdownItem>
                                {data.proposalId > 0 && (
                                  <DropdownItem
                                    onClick={() => openModalInfo(data)}
                                  >
                                    <i className="fas fa-edit" /> Redigitar
                                    Proposta
                                  </DropdownItem>
                                )}
                                <DropdownItem
                                  onClick={() =>
                                    handleListProposalClient(
                                      data.clientId,
                                      `clientProposals-${index}`
                                    )
                                  }
                                >
                                  <i className="fas fa-folder-open" /> Propostas
                                  do Cliente
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    setTagDataModal({
                                      isOpen: true,
                                      clientId: data.clientId,
                                    });
                                  }}
                                >
                                  <i className="fas fa-tags" /> Etiquetas
                                </DropdownItem>
                                {data.febrabanId === '329-5' && (
                                  <DropdownItem
                                    onClick={() => {
                                      setResendPayment({
                                        ...resendPayment,
                                        proposalId: data.proposalId,
                                        simulationid: data.id,
                                        febrabanId: '329-5',
                                        isOpen: true,
                                      });
                                    }}
                                  >
                                    <i className="fas fa-retweet" />{' '}
                                    Reapresentar Proposta
                                  </DropdownItem>
                                )}

                                <DropdownItem
                                  onClick={() => {
                                    setSendSms({
                                      ...sendSms,
                                      openModal: true,
                                      number: data.whatsappNumber,
                                      message: `Olá ${data.name}`,
                                    });
                                  }}
                                >
                                  <i className="fas fa-paper-plane" /> Enviar
                                  SMS
                                </DropdownItem>

                                {data.messageReturnService && (
                                  <DropdownItem
                                    onClick={() => {
                                      handleSaveSimulationQueue(
                                        data.id,
                                        data.febrabanId,
                                        data.tableCode,
                                        data.cpf
                                      );
                                    }}
                                  >
                                    <i className="fa fa-solid fa-people-arrows"></i>
                                    Fila de Simulações
                                  </DropdownItem>
                                )}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </TableData>
                          <TableData className="d-xl-none">
                            <CaretRight
                              active={tableDetails === index}
                              onClick={() => handleTableDetails(index)}
                            />
                          </TableData>
                        </TableRow>
                        {showClientProposals === `clientProposals-${index}` && (
                          <TableRow key={`clientProposals-${index}`}>
                            <TableData colSpan={5} className="bg-secondary">
                              {proposalsClient.length > 0 ? (
                                <Card className="shadow">
                                  <CardHeader>
                                    <h3 className="m-0">
                                      Últimas propostas do cliente
                                    </h3>
                                  </CardHeader>
                                  <table className="table table-striped table-bordered">
                                    <thead>
                                      <tr className="bg-primary text-white">
                                        <th>DATA</th>
                                        <th>PROPOSTA</th>
                                        <th>FINANCEIRA</th>
                                        <th>STATUS</th>
                                        <th>VALOR</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {proposalsClient.map(
                                        (proposalClient, keyProposalClient) => (
                                          <tr key={keyProposalClient}>
                                            <td>{proposalClient.createdAt}</td>
                                            <td>
                                              <Badge
                                                color="warning"
                                                className="font-12"
                                              >
                                                {proposalClient.numberProposal}
                                              </Badge>
                                            </td>
                                            <td>
                                              {proposalClient.febrabanId} -{' '}
                                              {proposalClient.bankName}
                                            </td>
                                            <td>{proposalClient.status}</td>
                                            <td>
                                              R${' '}
                                              {moneyMask(
                                                proposalClient.valueLiberty
                                              )}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </Card>
                              ) : (
                                <Badge color="danger" className="font-14">
                                  Não foram identificadas propostas para este
                                  cliente.
                                </Badge>
                              )}
                            </TableData>
                          </TableRow>
                        )}
                        {tableDetails === index && (
                          <TableRow className="d-xl-none">
                            <TableData colSpan={10}>
                              <ContentWrapper className="d-lg-none">
                                <MobileTitle>Valores</MobileTitle>
                                <Valores data={data} />
                              </ContentWrapper>
                              <ContentWrapper>
                                <MobileTitle>Status</MobileTitle>
                                <Status data={data} />
                                {data.febrabanId == '389' &&
                                data.status === 'DIGITAÇÃO REALIZADA' ? (
                                  <Buttons
                                    title="ReEnviar SMS Mercantil"
                                    className="btn btn-info btn-sm"
                                    onClick={() => reSendLinkMercantil(data.id)}
                                  />
                                ) : (
                                  <></>
                                )}
                                {data.febrabanId == '329' &&
                                data.status === 'DIGITAÇÃO REALIZADA' ? (
                                  <>
                                    <div className="input-group">
                                      <div className="input-group-append">
                                        <Button
                                          title="Cancelar Proposta"
                                          className="btn btn-danger btn-sm mr-2"
                                          onClick={() =>
                                            cancelProposalICred(data.id)
                                          }
                                        >
                                          Cancelar Proposta
                                        </Button>
                                        <Button
                                          title="Enviar SMS"
                                          className="btn btn-info btn-sm ml-2"
                                          style={{
                                            marginLeft: '5px !important',
                                          }}
                                          onClick={() =>
                                            reSendProposalICred(
                                              data.id,
                                              data.whatsappNumber,
                                              data.numberProposal
                                            )
                                          }
                                        >
                                          Enviar SMS
                                        </Button>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {(data.febrabanId == '626' ||
                                  data.febrabanId == '149' ||
                                  data.febrabanId == '623' ||
                                  data.febrabanId == '329-1' ||
                                  data.febrabanId == '329-5' ||
                                  data.febrabanId == '329-6' ||
                                  data.febrabanId == '074' ||
                                  data.febrabanId == '274' ||
                                  data.febrabanId == '457' ||
                                  data.febrabanId == '324' ||
                                  data.febrabanId == '531') &&
                                data.status === 'DIGITAÇÃO REALIZADA' ? (
                                  <>
                                    <Button
                                      className="btn btn-sm btn-info"
                                      onClick={() => {
                                        getLinkFormalize(
                                          data.numberProposal,
                                          data.febrabanId
                                        );
                                      }}
                                    >
                                      Link de Formalização
                                    </Button>
                                  </>
                                ) : (
                                  <></>
                                )}
                                <Button
                                  className="btn btn-secondary btn-sm"
                                  onClick={() => {
                                    openModalNote(data);
                                  }}
                                >
                                  Anotações
                                </Button>
                              </ContentWrapper>
                              <ContentWrapper>
                                <UncontrolledDropdown>
                                  <DropdownToggle caret color="secondary">
                                    Opções
                                  </DropdownToggle>
                                  <DropdownMenu dark>
                                    <DropdownItem
                                      href={`/admin/simulation-details/${
                                        data.id ?? 0
                                      }`}
                                      target="_blank"
                                    >
                                      <i className="fas fa-tag" /> Detalhes da
                                      Simulação
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => {
                                        if (
                                          data.localeSimulation ===
                                          'INTERNO FGTS MULTITABELAS'
                                        ) {
                                          history.push(
                                            `/admin/fgts/operation?cpf=${data.cpf}&birthDate=${data.birthDate}&whatsapp=${data.whatsappNumber}&name=${data.name}`
                                          );
                                          return;
                                        }

                                        setDataSimule(data);
                                        openModalDigitationFGTS();
                                      }}
                                    >
                                      <i className="fas fa-edit" /> Simular
                                      Novamente
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => openModalInfo(data)}
                                    >
                                      <i className="fas fa-edit" /> Redigitar
                                      Proposta
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => {
                                        setTagDataModal({
                                          isOpen: true,
                                          clientId: data.clientId,
                                        });
                                      }}
                                    >
                                      <i className="fas fa-tags" /> Etiquetas
                                    </DropdownItem>
                                    {data.febrabanId === '329-5' && (
                                      <DropdownItem
                                        onClick={() => {
                                          setResendPayment({
                                            ...resendPayment,
                                            proposalId: data.proposalId,
                                            simulationid: data.id,
                                            febrabanId: '329-5',
                                            isOpen: true,
                                          });
                                        }}
                                      >
                                        <i className="fas fa-retweet" />{' '}
                                        Reapresentar Proposta
                                      </DropdownItem>
                                    )}
                                    <DropdownItem
                                      onClick={() => {
                                        setSendSms({
                                          ...sendSms,
                                          openModal: true,
                                          number: data.whatsappNumber,
                                          message: `Olá ${data.name}`,
                                        });
                                      }}
                                    >
                                      <i className="fas fa-paper-plane" />{' '}
                                      Enviar SMS
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </ContentWrapper>
                            </TableData>
                          </TableRow>
                        )}
                      </>
                    ))}
                  </TableBody>
                </Table>
                <PaginationSelector
                  pages={pages}
                  list={handleList}
                  setPage={setPage}
                  page={page}
                />
              </>
            ) : (
              <>
                <Alert color="secondary" className="text-center">
                  Não possui simulações no momento
                </Alert>
              </>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ListSimulationDashboardComponent;
