import { useEffect, useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { listBankFgtsService } from 'services/Register/BankService';

const InputBankView = ({
  filter,
  setFilter,
  defaultValue = { value: null, label: null },
  isClearInput = false,
  isMultSelect = false,
  nameLabel = 'Banco(s)',
}) => {
  const [banks, setBanks] = useState([]);
  const [valueSelected, setValueSelected] = useState({});

  const handleList = async () => {
    await listBankFgtsService()
      .then((result) => {
        if (!result?.data?.resultSet) {
          return;
        }

        setValueSelected(
          result?.data.resultSet.filter(
            (e) => e.id == defaultValue?.value
          )[0] ?? null
        );

        setBanks(
          result?.data?.resultSet.map((res) => {
            return {
              value: res.id,
              label: `${res.febraban} | ${res.name}`,
              febrabanId: res.febraban,
            };
          })
        );
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <FormGroup>
      <Label>{nameLabel}</Label>
      {banks.length > 0 && (
        <Select
          className="basic-single"
          classNamePrefix="select"
          // defaultValue={defaultValue}
          defaultValue={
            valueSelected?.id
              ? {
                label: `${valueSelected?.febraban} | ${valueSelected?.name}`,
                value: valueSelected?.id,
              }
              : null
          }
          options={banks}
          isLoading={banks.length <= 0}
          placeholder={`Selecione...`}
          isClearable={isClearInput}
          isMulti={isMultSelect}
          onChange={(res) => {
            if (isMultSelect) {
              setFilter(res);
              return;
            }
            setFilter({
              ...filter,
              bankId: res?.value ?? null,
              febrabanId: res?.febrabanId ?? null,
            });
          }}
        />
      )}

      {banks.length <= 0 && <p>Carregando...</p>}
    </FormGroup>
  );
};

export default InputBankView;
