import { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  Table,
  Alert,
  CardBody,
  FormGroup,
  Label,
  Input,
  CardFooter,
  Button,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import Header from 'components/Headers/Header';
import Swal from 'sweetalert2';
import { maskCPF } from 'utils/mask';
import { digitationReportService } from 'services/Digitation/DigitationService';
import { Title, ContainerButton } from 'views/Dashboard/styles';
import PaginationSelector from 'views/Dashboard/component/PaginationSelector';
import { useHistory } from 'react-router-dom';
import { getAllStatusAttendant } from 'services/Digitation/DigitationService';
import { updateDigitationStatusAttendant } from 'services/Digitation/DigitationService';
import { scheduleExcel } from 'services/Report/Schedule';
import { phoneMask } from 'utils/mask';
import ModalClientDocumentComponent from 'views/Client/Component/ModalClientDocumentComponent';

const INSS = () => {
  const userDataConfig = JSON.parse(localStorage.getItem('IZIDEV_USER_DATA'));
  const [listData, setListData] = useState([]);
  const [selectedRegister, setSelectedRegister] = useState(null);
  const [showModalDocuments, setShowModalDocuments] = useState(false);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState({
    dateStart: null,
    dateEnd: null,
    typeDate: null,
    cpf: null,
    numberProposal: null
  });
  const [statusAttendant, setStatusAttendant] = useState([]);
  const history = useHistory();

  const handleList = async (pageSelected = 0) => {
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    digitationReportService({
      ...filter,
      page: pageSelected ?? 0
    }).then((res) => {
      Swal.close();
      setListData(res.data.resultSet.data);
      if (res.data.resultSet.data.length > 0) {
        setPages(res.data.resultSet.pagination.result.pages);
      }
    });
  };

  const handleStatusAttendant = () => {
    getAllStatusAttendant().then((res) => {
      setStatusAttendant(res.data.resultSet);
    });
  };

  const handleUpdateDigitationStatusAttendant = (digitationId, statusId) => {
    updateDigitationStatusAttendant({
      digitationId: digitationId,
      statusAttendantId: statusId
    }).catch((err) => {
      const code = !err.response ? '00000' : err.response.data.code;
      const message = !err.response
        ? 'Ocorreu um erro inesperado'
        : err.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `[${code}] ${message}`
      });
    });
  };

  const openModalDocuments = (data) => {
    setShowModalDocuments(true);
    setSelectedRegister(data);
  };

  const closeModal = () => {
    setSelectedRegister(null);
    setShowModalDocuments(false);
  };

  useEffect(() => {
    handleStatusAttendant();
    handleList(0);
  }, []);

  return (
    <>
      <Header titlePage="Esteira de Propostas" />
      <Container className="mt--9" fluid>
        <Row>
          <Col className="mt-5" xl="12">
            <Card className="shadow mb-4">
              <CardHeader>
                <h2 className="m-0">ESTEIRA DE DIGITAÇÃO</h2>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Periodo</Label>
                      <div className="input-group">
                        <Input
                          className="border"
                          id="dateStart"
                          name="dateStart"
                          type="date"
                          onBlur={(ev) => {
                            setFilter({
                              ...filter,
                              dateStart: ev.target.value
                            });
                          }}
                        />
                        <Input
                          className="border pl-2"
                          id="dateEnd"
                          name="dateEnd"
                          type="date"
                          onBlur={(ev) => {
                            setFilter({
                              ...filter,
                              dateEnd: ev.target.value
                            });
                          }}
                        />
                        <select
                          className="form-control border pl-2"
                          id="typeDate"
                          name="typeDate"
                          onBlur={(ev) => {
                            setFilter({
                              ...filter,
                              typeDate: ev.target.value
                            });
                          }}
                        >
                          <option value="createdAt">DATA DE CADASTRO</option>
                          <option value="updatedAt">DATA DE ATUALIZAÇÃO</option>
                        </select>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>CPF</Label>
                      <Input
                        type="text"
                        name="cpf"
                        id="cpf"
                        placeholder="000.000.000-00"
                        value={filter.cpf}
                        onBlur={(ev) => {
                          setFilter({
                            ...filter,
                            cpf: maskCPF(ev.target.value)
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Nº do contrato</Label>
                      <Input
                        type="text"
                        name="numberProposal"
                        id="numberProposal"
                        onBlur={(ev) => {
                          setFilter({
                            ...filter,
                            numberProposal: ev.target.value
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <ContainerButton>
                  <Button
                    className='btn-margin'
                    color="success"
                    onClick={() => {
                      handleList(0);
                    }}
                  >
                    Filtrar
                  </Button>
                  {userDataConfig?.canExportExcel > 0 && (
                    <Button
                      className='btn-margin'
                      color="success"
                      onClick={() => {
                        scheduleExcel(7, filter, history);
                      }}
                    >
                      Exportar Excel
                    </Button>
                  )}
                  <Button
                    className='btn-margin'
                    color="primary"
                    onClick={() => {
                      history.push('/admin/digitation-inss');
                    }}
                  >
                    Realizar digitação
                  </Button>
                </ContainerButton>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="mb-5 mb-xl-0">
            <Card className="shadow">
              <CardHeader>
                <Title>Listagem</Title>
              </CardHeader>
              {listData.length > 0 ? (
                <>
                  <PaginationSelector
                    pages={pages}
                    list={handleList}
                    setPage={setPage}
                    page={page}
                  />
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th>Cliente</th>
                        <th>Documento</th>
                        <th>Contato</th>
                        <th>Atendimento</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {listData.map((data, index) => (
                        <>
                          <tr key={index}>
                            <td>
                              <p>
                                <strong>Nome:</strong>
                                {` `}
                                {data.name}
                              </p>
                              <p>
                                <strong>Data Cadastro:</strong>
                                {` `}
                                {data.createdAt}
                              </p>
                              <p>
                                <strong>Data Atualização:</strong>
                                {` `}
                                {data.updatedAt}
                              </p>
                            </td>
                            <td>
                              <p>
                                <strong>CPF:</strong>
                                {` `}
                                {maskCPF(data.documentCPF)}
                              </p>
                              <p>
                                <strong>RG:</strong>
                                {` `}
                                {data.documentRG}
                              </p>
                              <p>
                                <strong>MATRICULA:</strong>
                                {` `}
                                {data.registry}
                              </p>
                            </td>
                            <td>
                              <p>
                                <strong>WHATSAPP:</strong>
                                {` `}
                                <strong>
                                  {phoneMask(data.whatsappNumber)}
                                </strong>
                                {` `}
                                <a
                                  href={`https://wa.me/55${data.whatsappNumber.replace(
                                    /[^0-9]/g,
                                    ''
                                  )}`}
                                  target="_blank"
                                  className="btn btn-success btn-sm"
                                >
                                  <i className="fab fa-whatsapp" />
                                </a>
                              </p>
                              <p>
                                <strong>E-MAIL:</strong>
                                {` `}
                                {data.email}
                              </p>
                            </td>
                            <td>
                              {statusAttendant.length > 0 ? (
                                <select
                                  className="form-control form-control-sm"
                                  onChange={(ev) => {
                                    if (!ev.target.value) return;
                                    handleUpdateDigitationStatusAttendant(
                                      data.id,
                                      ev.target.value
                                    );
                                  }}
                                >
                                  <option value="">SELECIONE...</option>
                                  {statusAttendant.map((result, key) => (
                                    <option
                                      key={key}
                                      value={result.id}
                                      selected={
                                        data.statusAttendantId === result.id
                                          ? 'selected'
                                          : ''
                                      }
                                    >
                                      {result.name}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                <></>
                              )}
                              {data.attendant ? (
                                <div className="mt-3">
                                  <Badge
                                    color="info"
                                    style={{ fontSize: '16px' }}
                                  >
                                    Atendente: {data.attendant}
                                  </Badge>
                                </div>
                              ) : (
                                <></>
                              )}
                            </td>
                            <td className="align-middle border-top-0">
                              <UncontrolledDropdown>
                                <DropdownToggle caret color="secondary">
                                  Opções
                                </DropdownToggle>
                                <DropdownMenu dark>
                                  <DropdownItem
                                    onClick={() => {
                                      history.push(
                                        `/admin/digitation-inss-show/${data.uuid}`
                                      );
                                    }}
                                    target="_blank"
                                    className="d-flex align-items-center"
                                  >
                                    <i className="fas fa-file-alt" /> Detalhes da
                                    digitação
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      openModalDocuments(data);
                                    }}
                                    target="_blank"
                                    className="d-flex align-items-center"
                                  >
                                    <i className="far fa-images" /> Visualizar
                                    documento
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                          {data.observation ? (
                            <tr>
                              <td className="bg-secondary" colSpan={5}>
                                <h4>ULTIMA OBSERVAÇÃO</h4>
                                {data.observation}
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        </>
                      ))}
                    </tbody>
                  </Table>
                  <PaginationSelector
                    pages={pages}
                    list={handleList}
                    setPage={setPage}
                    page={page}
                  />
                </>
              ) : (
                <>
                  <Alert color="secondary" className="text-center">
                    Não possuem registros no momento
                  </Alert>
                </>
              )}

              <ModalClientDocumentComponent
                showModalDocuments={showModalDocuments}
                closeModal={closeModal}
                register={selectedRegister}
              />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default INSS;
