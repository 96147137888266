import { useState } from 'react';
import Header from 'components/Headers/Header';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Row,
  Container,
  Alert,
  CardFooter,
} from 'reactstrap';

import { saveProposalCrefisaService } from 'services/Digitation/PersonalCreditCrefisaService';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import InputIncrementComponent from 'components/Form/Input/InputIncrementComponent';
import DataDigitationCrefisaComponent from './Component/DataDigitationCrefisaComponent';

const NewProposalCrefisaView = () => {
  const history = useHistory();
  const inputRequired = [
    'name',
    'birthDate',
    'cpf',
    'phone',
    'registry',
    'nameMother',
    'dateEmissionRG',
    'documentRG',
    'emissionRG',
    'stateRG',
    'sex',
    'cep',
    'address',
    'district',
    'numberAddress',
    'city',
    'state',
    'numberAccount',
    'RG_FRONT',
    'RG_BACK',
    'CPF_FRONT',
    'CPF_BACK',
    'DOCUMENT_BANK',
    'CAD_UNICO',
    'CITIZEN_PORTAL',
    'PRINTSCREEN',
  ];
  const translateInput = [
    { key: 'clientId', name: 'Cliente' },
    { key: 'serviceId', name: 'Serviço' },
    { key: 'name', name: 'Nome' },
    { key: 'birthDate', name: 'Data de nascimento' },
    { key: 'cpf', name: 'CPF' },
    { key: 'phone', name: 'Contato WhatsApp' },
    { key: 'registry', name: 'Matricula' },
    { key: 'nameMother', name: 'Nome da mãe' },
    { key: 'dateEmissionRG', name: 'Data de emissão do RG' },
    { key: 'documentRG', name: 'RG' },
    { key: 'emissionRG', name: 'Orgão emissor RG' },
    { key: 'stateRG', name: 'Estado Emissor RG' },
    { key: 'sex', name: 'Sexo' },
    { key: 'stateCivil', name: 'Estado Civil' },
    { key: 'cep', name: 'CEP' },
    { key: 'address', name: 'Endereço' },
    { key: 'district', name: 'Bairro' },
    { key: 'numberAddress', name: 'Numero' },
    { key: 'city', name: 'Cidade' },
    { key: 'state', name: 'Estado' },
    { key: 'numberAccount', name: 'Número da conta' },
    { key: 'RG_FRONT', name: 'Anexo: RG frente não informado' },
    { key: 'RG_BACK', name: 'Anexo: RG verso não informado' },
    { key: 'CPF_FRONT', name: 'Anexo: CPF frente não informado' },
    { key: 'CPF_BACK', name: 'Anexo CPF verso não informado' },
    { key: 'DOCUMENT_BANK', name: 'Anexo: Extrato bancário não informado' },
  ];
  const [errorMessages, setErrorMessages] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [contract, setContract] = useState({
    cpf: '',
    birthDate: '',
    febrabanId: null,
    registry: '',
    value: '',
    installment: 12,
    valueInstallment: '159,00',
    useSafe: false,
  });

  const handleSetFile = (ev) => {
    const file = ev.target.files[0];
    const name = ev.target.name;

    if (!file) return;

    const sizeFile = file.size / 1024 / 1024;

    if (sizeFile > 1) {
      Swal.fire({
        icon: 'warning',
        title: 'Error',
        text: 'O arquivo não pode ser importado. Por favor, reduza a quantidade de registros e importe novamente!',
      });
      return;
    }

    setContract({
      ...contract,
      [name]: file,
    });
  };

  const handleSave = () => {
    Swal.fire({
      title: 'Salvando proposta',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    setErrorMessages([]);
    const errors = [];

    for (let key in inputRequired) {
      const keyInput = inputRequired[key];
      if (contract[keyInput] !== undefined && contract[keyInput] !== '')
        continue;

      const translateName = translateInput.filter((e) => e.key === keyInput)[0];

      errors.push({
        input: keyInput,
        message: `Campo: ${translateName.name}, é obrigatório`,
      });
    }

    if (errors.length > 0) {
      setErrorMessages(errors);
      Swal.close();
      return;
    }

    setIsSaving(true);
    const formData = new FormData();

    for (let key in contract) {
      formData.append(key, contract[key]);
    }

    saveProposalCrefisaService(6, formData)
      .then(({ data }) => {
        Swal.close();
        setIsSaving(false);
        if (data?.errors && data?.errors.length > 0) {
          setErrorMessages(data?.errors);
          return;
        }

        setContract({
          ...contract,
          uuid: data.uuid,
        });
        setShowSuccess(true);
      })
      .catch((err) => {
        Swal.close();

        setErrorMessages([
          {
            input: null,
            message: !err.response
              ? 'Ocorreu um erro inesperado'
              : err.response.data.message,
          },
        ]);

        setIsSaving(false);
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  if (showSuccess) {
    return (
      <>
        <Header titlePage="Crédito Pessoal - Crefisa" />
        <Container className="mt--9" fluid>
          <div>
            <Card className="mt-4 shadow" color="success">
              <CardBody className="text-center text-white">
                <span>
                  <i className="fas fa-check fa-3x" />
                </span>
                <h1>Sucesso</h1>
                <p>
                  Agora, basta aguardar o processamento da esteira para o envio
                  ao banco Crefisa.
                </p>
                <Card color="white" className="mt-3 ">
                  <CardBody className="text-dark">
                    <p className="mb-0">Código digitação:</p>
                    <h2 className="m-0" style={{ fontSize: '30px' }}>
                      {contract.uuid}
                    </h2>
                  </CardBody>
                </Card>
              </CardBody>
            </Card>
            <div>
              <Row>
                <Col md="6">
                  <Button
                    color="primary"
                    size="lg"
                    className="w-100 mt-3"
                    onClick={() => {
                      history.push('/admin/personal-credit/digitation/queue');
                    }}
                  >
                    ESTEIRA DE PROPOSTAS
                  </Button>
                </Col>
                <Col md="6">
                  <Button
                    color="success"
                    size="lg"
                    className="w-100 mt-3"
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    NOVA PROPOSTA
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </>
    );
  }

  return (
    <>
      <Header titlePage="Crédito Pessoal - Crefisa" />
      <Container className="mt--9" fluid>
        <Alert color="primary">
          <p className="m-0">
            <i className="fas fa-info-circle" /> Nesta página você pode digitar
            os produtos do Crédito pessoal Crefisa
          </p>
        </Alert>

        <DataDigitationCrefisaComponent
          contract={contract}
          setContract={setContract}
        />

        <Card className="shadow mt-2">
          <CardHeader>
            <h3 className="m-0">
              <i className="fas fa-upload" /> Envio de documentos
            </h3>
          </CardHeader>
          <CardBody>
            <Alert color="secondary">
              <h3 className="m-0">Informações importantes sobre os arquivos</h3>
              <p className="m-0">Extensões permitidas: PDF, JPG</p>
              <p className="m-0">Tamanho limite: 2Mb por arquivo</p>
            </Alert>

            <Row className="px-2">
              <Col md="4" className="p-1">
                <Card className="shadow mb-2">
                  <CardBody>
                    <Label>
                      RG FRENTE <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="file"
                      name="RG_FRONT"
                      onChange={handleSetFile}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" className="p-1">
                <Card className="shadow mb-2">
                  <CardBody>
                    <Label>
                      RG VERSO <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="file"
                      name="RG_BACK"
                      onChange={handleSetFile}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" className="p-1">
                <Card className="shadow mb-2">
                  <CardBody>
                    <Label>
                      CPF FRENTE <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="file"
                      name="CPF_FRONT"
                      onChange={handleSetFile}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" className="p-1">
                <Card className="shadow mb-2">
                  <CardBody>
                    <Label>
                      CPF VERSO <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="file"
                      name="CPF_BACK"
                      onChange={handleSetFile}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" className="p-1">
                <Card className="shadow mb-2">
                  <CardBody>
                    <Label>
                      Extrato bancário <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="file"
                      name="DOCUMENT_BANK"
                      onChange={handleSetFile}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" className="p-1">
                <Card className="shadow mb-2">
                  <CardBody>
                    <Label>
                      Cad. único <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="file"
                      name="CAD_UNICO"
                      onChange={handleSetFile}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" className="p-1">
                <Card className="shadow mb-2">
                  <CardBody>
                    <Label>
                      Portal Cidadão <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="file"
                      name="CITIZEN_PORTAL"
                      onChange={handleSetFile}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" className="p-1">
                <Card className="shadow mb-2">
                  <CardBody>
                    <Label>
                      Tela de compartilhamento{' '}
                      <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="file"
                      name="PRINTSCREEN"
                      onChange={handleSetFile}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col md="12" className="p-1">
                <Card className="shadow mb-2">
                  <CardBody>
                    <InputIncrementComponent
                      label="Outros"
                      name="OTHERS"
                      type="file"
                      handleChange={handleSetFile}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="success" onClick={handleSave} disabled={isSaving}>
              <i className="fas fa-save" /> SALVAR PROPOSTA{' '}
              {isSaving && <i className="fas fa-circle-notch fa-spin" />}
            </Button>
          </CardFooter>
        </Card>

        <Alert className="mt-2" color="secondary">
          <p className="m-0">
            - Campos com <strong className="text-danger">*</strong> são
            obrigatórios
          </p>
        </Alert>

        {errorMessages.length > 0 && (
          <Alert color="warning">
            <h2 className="m-0 text-white">
              <i className="fas fa-times" /> Erro
            </h2>
            {errorMessages.map((errorMessage, key) => (
              <div
                key={key}
                dangerouslySetInnerHTML={{
                  __html: `- ${errorMessage.message}`,
                }}
              />
            ))}
          </Alert>
        )}
      </Container>
    </>
  );
};

export default NewProposalCrefisaView;
