import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { deleteSimulationQueue } from 'services/SimulationQueue';
import { reProcessSimulation } from 'services/SimulationQueue';
import Swal from 'sweetalert2';
const OptionSimulationFgtsQueueComponent = ({
  register = {
    uuid: null,
    simulationId: null,
    febrabanId: null,
    cpf: null,
    valueSolicited: null,
    whatsappNumber: null,
  },
  setSimulationResult = () => {},
  setModalDigitation = () => {},
  handleList = () => {},
}) => {
  const handleDigitation = (register) => {
    if (!register.simulationId) return;
    setModalDigitation(true);
    setSimulationResult({
      id: register.simulationId,
      febrabanId: register.febrabanId,
      cpf: register.cpf,
      valueSolicited: register.valueLiberty,
      valueLiberty: register.valueLiberty,
      whatsappNumber: register.whatsappNumber,
    });
  };

  const handleReprocessSimulation = async (uuid) => {
    Swal.fire({
      title: 'Realizando alteração',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await reProcessSimulation(uuid)
      .then(async () => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Seu registro será reprocessado!',
        });
        handleList();
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleDelete = (uuid) => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      text: 'Tem certeza que deseja deletar o registro? Será irreversível!',
      showCancelButton: true,
      cancelButtonText: 'Sair',
      confirmButtonText: 'Ok, continuar',
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;

      Swal.fire({
        title: 'Removendo',
        text: 'Por favor, aguarde.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      deleteSimulationQueue(uuid)
        .then(async () => {
          Swal.fire({
            icon: 'success',
            title: 'Sucesso',
            text: 'Seu registro foi removido da Fila.',
          });
          handleList();
        })

        .catch((err) => {
          const code = !err.response ? '00000' : err.response.data.code;
          const message = !err.response
            ? 'Ocorreu um erro inesperado'
            : err.response.data.message;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `[${code}] ${message}`,
          });
        });
    });
  };

  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle caret>Opções</DropdownToggle>
        <DropdownMenu>
          {register.simulationId > 0 && register.status === 'SUCCESS'}
          <DropdownItem onClick={() => handleDigitation(register)}>
            <i className="fas fa-sync-alt" /> DIGITAR PROPOSTA
          </DropdownItem>
          <DropdownItem
            onClick={() => handleReprocessSimulation(register.uuid)}
          >
            <i className="fas fa-sync-alt" /> REPROCESSAR
          </DropdownItem>
          <DropdownItem onClick={() => handleDelete(register.uuid)}>
            <i className="fas fa-trash" /> REMOVER
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

export default OptionSimulationFgtsQueueComponent;
