import { useState, useEffect } from 'react';
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap';
import SampleModalView from 'components/Modals/SampleModalView';
import InputComponent from 'components/Form/Input/InputComponent';
import Swal from 'sweetalert2';
import {
  insertLevelAccessService,
  getLevelAccessService,
} from 'services/User/LevelAccess';
import { listLevelAccessModulesService } from 'services/User/LevelAccess';

const LevelAccessRegisterView = ({
  isOpen = false,
  setIsOpen = () => {},
  levelAccessId = null,
  setLevelAccessId = () => {},
}) => {
  const [modules, setModules] = useState([]);
  const [form, setForm] = useState({
    id: null,
    name: null,
    showCardsFgts: 0,
    showValueCardDigitation: 0,
    showAdvancedOptionsFgts: 0,
    canExportExcel: 0,
    isSupervisor: 0,
    isChangeStatusProposal: 0,
  });

  const handleListModules = async (id = null) => {
    Swal.fire({
      title: 'Carregando...',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    await listLevelAccessModulesService({
      levelAccessId: id,
    })
      .then((res) => {
        Swal.close();
        setModules(res.data.resultSet);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleSubmit = () => {
    Swal.fire({
      title: 'Salvando...',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    insertLevelAccessService({
      ...form,
      modules: modules,
    })
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Registro inserido com sucesso',
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleGetLevelAccess = (levelId) => {
    Swal.fire({
      title: 'Carregando...',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    getLevelAccessService(levelId)
      .then(({ data }) => {
        setForm(data?.resultSet);
        Swal.close();
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleListModules();
  }, []);

  useEffect(() => {
    if (!levelAccessId) {
      setForm({
        id: null,
        name: null,
        showCardsFgts: 0,
        showValueCardDigitation: 0,
        showAdvancedOptionsFgts: 0,
        canExportExcel: 0,
        isSupervisor: 0,
        isChangeStatusProposal: 0,
      });
    }

    if (levelAccessId > 0) {
      handleGetLevelAccess(levelAccessId);
      handleListModules(levelAccessId);
    }
  }, [levelAccessId]);

  return (
    <>
      <SampleModalView
        labelModal="Nível de acesso"
        icon="far fa-window-restore"
        isOpen={isOpen}
        setIsOpen={(e) => {
          setIsOpen(e);
          if (!e) {
            setLevelAccessId(null);
          }
        }}
        btnSubmitLabel={'Salvar'}
        btnSubmitAction={() => {
          handleSubmit();
          setLevelAccessId(null);
          setIsOpen(false);
        }}
        showBtnSubmit={true}
        size="xl"
      >
        <Card className="shadow">
          <CardHeader>
            <h3>Registro</h3>
          </CardHeader>
          <CardBody>
            <InputComponent
              label="name"
              value={form.name}
              onChange={(ev) => {
                setForm({ ...form, name: ev.target.value });
              }}
            />
            <Card className="shadow mt-2">
              <CardHeader>
                <h3>Permissões</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="6">
                    <InputComponent
                      label="Cards resumo FGTS:"
                      value={form.showCardsFgts}
                      type="select"
                      select={[
                        { name: 'NÃO', value: 0 },
                        { name: 'SIM', value: 1 },
                      ]}
                      onChange={(ev) => {
                        setForm({ ...form, showCardsFgts: ev.target.value });
                      }}
                    />
                  </Col>
                  <Col md="6">
                    <InputComponent
                      label="Valor em card digitação:"
                      value={form.showValueCardDigitation}
                      type="select"
                      select={[
                        { name: 'NÃO', value: 0 },
                        { name: 'SIM', value: 1 },
                      ]}
                      onChange={(ev) => {
                        setForm({
                          ...form,
                          showValueCardDigitation: ev.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col md="6">
                    <InputComponent
                      label="Configurações avançadas FGTS:"
                      value={form.showAdvancedOptionsFgts}
                      type="select"
                      select={[
                        { name: 'NÃO', value: 0 },
                        { name: 'SIM', value: 1 },
                      ]}
                      onChange={(ev) => {
                        setForm({
                          ...form,
                          showAdvancedOptionsFgts: ev.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col md="6">
                    <InputComponent
                      label="Exporta realatórios em excel:"
                      value={form.canExportExcel}
                      type="select"
                      select={[
                        { name: 'NÃO', value: 0 },
                        { name: 'SIM', value: 1 },
                      ]}
                      onChange={(ev) => {
                        setForm({ ...form, canExportExcel: ev.target.value });
                      }}
                    />
                  </Col>
                  <Col md="6">
                    <InputComponent
                      label="Tipo vendedor"
                      value={form.isSeller}
                      type="select"
                      select={[
                        { name: 'NÃO', value: 0 },
                        { name: 'SIM', value: 1 },
                      ]}
                      onChange={(ev) => {
                        setForm({
                          ...form,
                          isSeller: ev.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col md="6">
                    <InputComponent
                      label="Tipo Supervisor"
                      value={form.isSupervisor}
                      type="select"
                      select={[
                        { name: 'NÃO', value: 0 },
                        { name: 'SIM', value: 1 },
                      ]}
                      onChange={(ev) => {
                        setForm({ ...form, isSupervisor: ev.target.value });
                      }}
                    />
                  </Col>
                  <Col md="6">
                    <InputComponent
                      label="Altera status proposta"
                      value={form.isChangeStatusProposal}
                      type="select"
                      select={[
                        { name: 'NÃO', value: 0 },
                        { name: 'SIM', value: 1 },
                      ]}
                      onChange={(ev) => {
                        setForm({
                          ...form,
                          isChangeStatusProposal: ev.target.value,
                        });
                      }}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="shadow mt-4">
              <CardHeader>
                <h3>Opções Menu</h3>
              </CardHeader>
              {modules.length > 0 ? (
                <>
                  <Alert color="info" className="mt-3 ml-3 mr-3">
                    Para realizar a liberação da tela, basta selecionar os itens
                    abaixo:
                  </Alert>
                  <Table className="table table-bordered table-striped shadow">
                    <thead>
                      <tr>
                        <th>MENU</th>
                        <th>TELA PRINCIPAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      {modules.map((register, key) => (
                        <tr key={key}>
                          <td>
                            <Label className="pl-4">
                              <Input
                                type="checkbox"
                                checked={register.checked > 0 ? true : false}
                                onChange={(ev) => {
                                  let newModules = [...modules];
                                  modules[key].checked = ev.target.checked;
                                  setModules(newModules);
                                }}
                              />
                              {register.name}
                            </Label>
                          </td>
                          <td>
                            <Label>
                              <Input
                                name="mainScreen"
                                type="radio"
                                className="m-0"
                                checked={register.mainScreen > 0}
                                onChange={(ev) => {
                                  let newModules = [...modules];
                                  newModules = newModules.map((register) => {
                                    register.mainScreen = 0;
                                    return register;
                                  });
                                  modules[key].mainScreen = ev.target.checked
                                    ? 1
                                    : 0;
                                  setModules(newModules);
                                }}
                              />
                            </Label>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              ) : (
                <></>
              )}
            </Card>
          </CardBody>
        </Card>
      </SampleModalView>
    </>
  );
};

export default LevelAccessRegisterView;
