import InputBankView from "components/Form/Input/InputBankView"
import SelectProductView from "components/Form/Input/SelectProductView"
import SelectServiceView from "components/Form/Input/SelectServiceView"
import SelectTypeServiceView from "components/Form/Input/SelectTypeServiceView"
import SampleModalView from "components/Modals/SampleModalView"
import { useEffect } from "react"
import { useState } from "react"
import { Alert, Card, CardBody, Col, Input, Label, Row } from "reactstrap"
import { saveTableBankPromoterService } from "services/Comission/TableComissionService"

const TableComissionBankRegisterComponent = ({
   isOpen = false,
   setIsOpen = () => { },
   handleList = () => { }
}) => {
   const defaultError = {
      status: false,
      message: null
   };
   const defaultRegister = {
      id: null,
      serviceId: '',
      productBankId: '',
      febrabanId: '',
      tableName: '',
      tableId: '',
   };
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(defaultError)
   const [register, setRegister] = useState(defaultRegister);
   const [success, setSuccess] = useState(false);

   const handleSubmit = () => {
      setSuccess(false);
      setLoading(true);
      setError(defaultError);
      saveTableBankPromoterService(register)
         .then(() => {
            handleList();
            setSuccess(true);
            setLoading(false);
            setRegister({
               ...defaultRegister
            });
         })
         .catch((err) => {
            setLoading(false);
            const code = !err.response ? '00000' : err.response.data.code;
            const message = !err.response
               ? 'Ocorreu um erro inesperado'
               : err.response.data.message;
            setError({
               status: true,
               message: `[${code}] ${message}`
            })
         });
   }

   useEffect(() => {
      if (!isOpen) return;
      setRegister(defaultRegister);
      setError(defaultError);
      setSuccess(false);
      setLoading(false);
   }, [isOpen]);

   if (!isOpen) {
      return <></>;
   }

   return (
      <SampleModalView
         labelModal="Tabela Banco - Registro"
         icon="fas fa-plus"
         isOpen={isOpen}
         setIsOpen={setIsOpen}
         btnSubmitLabel={'Salvar'}
         btnSubmitAction={handleSubmit}
         disabledBtnSubmit={loading}
         showBtnSubmit={true}
         size="xl">
         <Card className='shadow'>
            <CardBody>
               <Row>
                  <Col md="3">
                     <InputBankView
                        placeholder="Selecione..."
                        defaultValue={{
                           value: register.febrabanId
                        }}
                        filter={register}
                        setFilter={(e) => {
                           setRegister({
                              ...register,
                              febrabanId: e.febrabanId,
                              bankId: e.bankId,
                           })
                        }}
                        isClearable
                     />
                  </Col>
                  <Col md="3">
                     <SelectServiceView
                        placeholder="Selecione..."
                        nameLabel="Serviço"
                        defaultValue={{
                           value: register.serviceId
                        }}
                        filter={register}
                        setFilter={setRegister}
                        isClearable
                     />
                  </Col>

                  <Col lg="3" md="6">
                     <SelectTypeServiceView
                        defaultValue={
                           register.typeServiceId > 0
                              ? {
                                 value: register.typeServiceId,
                                 label: '',
                              }
                              : null
                        }
                        filter={register}
                        setFilter={setRegister}
                        isClearable
                     />
                  </Col>
                  <Col md="3">
                     <SelectProductView
                        defaultValue={
                           register.productBankId > 0
                              ? {
                                 value: register.productBankId,
                                 label: '',
                              }
                              : null
                        }
                        filter={register}
                        setFilter={(e) => {
                           setRegister({
                              ...register,
                              productBankId: e.productServiceId
                           })
                        }}
                        typeId={register.typeServiceId}
                        serviceId={register.serviceId}
                        febrabanId={register.febrabanId}
                        bankId={register.bankId}
                     />
                  </Col>
                  <Col md="3">
                     <Label>Cod. Tabela</Label>
                     <Input
                        value={register.tableId}
                        onChange={(e) => {
                           setRegister({
                              ...register,
                              tableId: e.target.value
                           })
                        }} />
                  </Col>
                  <Col md="9">
                     <Label>Nome Tabela</Label>
                     <Input
                        value={register.tableName}
                        onChange={(e) => {
                           setRegister({
                              ...register,
                              tableName: e.target.value
                           })
                        }} />
                  </Col>
               </Row>
            </CardBody>
         </Card>

         {loading && (
            <Alert className='mt-3 shadow' color='secondary'><i className="fas fa-circle-notch fa-spin" /> Salvando, por favor aguarde...</Alert>
         )}

         {success && (
            <Alert className='mt-3 shadow' color='success'><i className="fas fa-check" /> Registro salvo com sucesso</Alert>
         )}

         {error.status && (
            <Alert className='mt-3 shadow' color='warning'><i className="fas fa-exclamation-triangle" /> {error.message}</Alert>
         )}

      </SampleModalView>
   );
}

export default TableComissionBankRegisterComponent